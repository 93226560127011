import React, { useState, useEffect } from "react";
import ArowVector from '../../public/images/ArowVector.svg'
import LottieLoader from '../../LottieLoader/index';
import functions from '../../src/assets/functions/common_functions';
import url from '../../src/assets/url';
import { trackEvent } from '../../lib/facebookPixel';


const CreateAcount1 = ({ setStep, updateUser, onUpdate, childState, totalStep }) => {
  
  

  const [givenName, setGivenName] = useState(localStorage.getItem('given_name') || childState.givenName || '')
  const [familyName, setFamilyName] = useState(localStorage.getItem('family_name') || childState.familyName || '')
  const [gender, setGender] = useState(childState.gender || '');
  const [loader, setloader] = useState(false)
  const [show_error_message1, setshow_error_message1] = useState(childState.show_error_message1 || '');


  const handleSignup = () => {
    setloader(true)
    let user_data = {
      "family_name": familyName,
      "given_name": givenName,
      "gender": gender
    }

      // Check for auto_id in localStorage and update step
      if (localStorage.getItem('auto_id')) {
        setloader(false)
        // console.log("1");
        localStorage.setItem('gender', gender);
        localStorage.setItem('family_name', familyName)
        localStorage.setItem('given_name', givenName)
         updateUser(user_data)
    } else {
        // console.log("2");
        let attempts = 0;

        const checkAutoId = () => {
            attempts++;
            if (localStorage.getItem('auto_id')) {
                setloader(false)
                 updateUser(user_data)
            } else if (attempts < 5) {
                setTimeout(checkAutoId, 1000); // Retry after 500ms
            } else {
                Router.push("/"); // Redirect after 5 failed attempts
            }
        };

        checkAutoId(); // Start the checking process
    }
    
   
  }

  const handleContinue = () => {
    let user_action = "User got error on signup/index file handleContinue function";
    let given_name_validate = "false"
    if (!givenName.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      given_name_validate = "true"
    }
    let family_name_validate = "false"
    if (!familyName.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      family_name_validate = "true"
    }
    if (givenName != "") {

      if (given_name_validate == "false") {

        let error_body = "First name is not valid"
        functions.logUserError(user_action, error_body);

        setshow_error_message1(error_body)
      }
      else if (givenName.length < 2) {

        let error_body = url.dynamic_content.error_message.given_name_length.en
        functions.logUserError(user_action, error_body);
        setshow_error_message1(url.dynamic_content.error_message.given_name_length.en)

      }
      else {

        if (familyName != "") {

          if (family_name_validate == "false") {

            let error_body = "Last name is not valid"
            functions.logUserError(user_action, error_body);
            setshow_error_message1(error_body)
          }
          else if (familyName.length < 2) {

            let error_body = url.dynamic_content.error_message.given_name_length.en
            functions.logUserError(user_action, error_body);
            setshow_error_message1(url.dynamic_content.error_message.given_name_length.en)
          }
          else {
            if (!gender) {
              let error_body = "Please select gender";
              functions.logUserError(user_action, error_body);
              setshow_error_message1(error_body);
            } else {
           
              
              // let image = 'continue_button_signup_page.png'
              // functions.logUserActivity("User click on continue button from signup", image)

              handleSignup()
              try{
                trackEvent('Signup_only_with_name/gender_NextJS', { event_action: 'User entered name, gender and clicked continue' });
              }catch(error){
                console.log("Pixel Error while trackEvent  ", error)
              }
            }
            
          }
        } else {
          let error_body = url.dynamic_content.error_message.enter_last_name.en
          functions.logUserError(user_action, error_body);
          setshow_error_message1(url.dynamic_content.error_message.enter_last_name.en)

        }
      }
    } else {

      let error_body = url.dynamic_content.error_message.all_fields_required.en
      functions.logUserError(user_action, error_body);
      setshow_error_message1(url.dynamic_content.error_message.all_fields_required.en)

    }
  }

  useEffect(() => {
    if (show_error_message1) {
      const timer = setTimeout(() => {
        setshow_error_message1('');
      }, 2000);

      // Cleanup function to clear the timer if err_msg changes again
      return () => clearTimeout(timer);
    }
  }, [show_error_message1]);


  useEffect(() => {
    
    // Create a state object to hold the values
    const state = {
      givenName,
      familyName,
      gender,
      show_error_message1
    };

    // Call the parent component's onUpdate prop
    onUpdate(state);
  }, [givenName, familyName, gender, show_error_message1]);

  const handleFirstname = (event) => {

    if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      setGivenName(event.target.value.trim())
    }
  }

  const handleLastname = (event) => {

    if (!event.target.value.match(/^[a-zA-Z-'. àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
    }
    else {
      setFamilyName(event.target.value.trim())
    }
  }


  return (
    <div className='addCardInfo'>
      <div className='addCardDetailUpr2'>
        <div className='addCardDetail2'>
          <div className='verifyHeadOne'>
            <div><img className="cursor_pointer" onClick={() => setStep(1)} src="/images/ArowVector.svg" alt="familyone" title="familyone" /></div>
            <label>Create your account</label>
            <div><a>2/{totalStep}</a></div>
          </div>
          <div className='createAc'>
            <div><input name='given_name' onChange={(e) => handleFirstname(e)} type='text' placeholder='First name' value={givenName == null || givenName == "null" ? '' :  givenName} /></div>
            <div><input name='family_name' onChange={(e) => handleLastname(e)} type='text' placeholder='Last Name' value={familyName == null || familyName == "null" ? '' : familyName} /></div>
          </div>
          <div className='createAcBtnsUpr'>
            <label>About you</label>
            <div className='createAcBtns'>
              <a className="cursor_pointer">
                <div className={gender == 'female' ? 'femaleseleted background' : 'femaleseleted'} onClick={(e) => setGender('female')}
                >
                  <h4>Female</h4>
                </div>
              </a>
              <a className="cursor_pointer">

                <div className={gender == 'male' ? 'femaleseleted1 background' : 'femaleseleted1'} onClick={(e) => setGender('male')}
                >
                  <h4>Male</h4>
                </div></a>
              {/* <input  className={gender == 'male' ? 'selected_gender': ''}  type='button' name="male" onClick={(e)=>setGender(e.target.name)} value="Male" /></a> */}
            </div>
          </div>
          {show_error_message1 && <div className="error_msg erorMsgAddon">{show_error_message1}</div>}
          {loader ?
           <div className="loaderCenter"><center><LottieLoader /></center></div>
            :
            <div className='checkOtp cursor_pointer'><a className="cursor_pointer" onClick={() => handleContinue()}>Continue</a></div>
          }
          <div className='addCardDetailTD addCardDetailTD2'>
            <p>We collect your first name, last name and email address as the most basic account
              identifiers and default means for you to access your account, participate in offers
              and for us to contact you.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateAcount1