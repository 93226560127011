import React, { useState, useEffect, useRef } from "react";
import functions from '../src/assets/functions/common_functions';
import cookie from 'react-cookies';
import axios from 'axios';
// import url from '../../assets/url';
import Router from 'next/router';

import GetStarted from "./NewLoginProcess/getStarted"
import EnterEmail from "./NewLoginProcess/EnterEmail"
import VerifyCode from "./NewLoginProcess/VerifyCode"
import session from '../src/assets/functions/session';
import CreateAcount1 from "./NewLoginProcess/CreateAcount1";
import CreateAcount2 from "./NewLoginProcess/CreateAcount2";
import CreateAcount3 from "./NewLoginProcess/CreateAcount3";
import EnterMobile from "./EnterMobileNumber/EnterMobile";
import SentOpt from "./EnterMobileNumber/SentOpt";
import SendMethods from "./EnterMobileNumber/SendMethods";
import { trackEvent } from '../lib/facebookPixel';

import AddChild from './addchild'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { initializeApp, getApps, getApp, deleteApp } from 'firebase/app';


const LoginProcess = (props, { handleGetStarted }) => {
    const getInitialChildState = () => {
        if (typeof window !== 'undefined') {
          // Safely access localStorage only in the browser
          return JSON.parse(localStorage.getItem('childState')) || {};
        }
        return {};
      };
    
      const initialChildState = getInitialChildState();
    let [loader, setloader] = useState(false);

    let [steps, setStep] = useState(initialChildState.steps || 0);
    const [fb_value, setFBValue] = useState(initialChildState.fb_value || true);
    const [showEmailInput, setShowEmailInput] = useState(initialChildState.showEmailInput || false);
    const [email_address, setEmailAddress] = useState(initialChildState.email_address || '');
    const [errorMessage, setErrorMessage] = useState(false);
    const [codeSent, setCodeSent] = useState(initialChildState.codeSent || false);
    const [error_mess, setError_mess] = useState('');
    const [facebook_id, setfacebookID] = useState(initialChildState.facebook_id || null);
    const [google_id, setGoogleID] = useState(initialChildState.google_id || null);
    const [getStartedLoader, setgetStartedLoader] = useState(false);
    const [totalStep, setTotalStep] = useState(initialChildState.totalStep || '4');
    const [phone_number, setPhoneNumber] = useState(initialChildState.phone_number || '');
    const [otpVerified, setotpVerified] = useState(true);
    const [userData, setUserData] = useState(null)

  
    const [childState, setchildState] = useState(initialChildState);

    let session_id = cookie.load("session_id") ? cookie.load("session_id") : null

    useEffect(() => {
        const updatedChildState = {
          ...childState,
          steps,
          fb_value,
          showEmailInput,
          email_address,
          codeSent,
          facebook_id,
          google_id,
          totalStep,
          phone_number,
        };
    
        setchildState(updatedChildState);
        localStorage.setItem('childState', JSON.stringify(updatedChildState));
      }, [
        steps,
        fb_value,
        showEmailInput,
        email_address,
        codeSent,
        facebook_id,
        google_id,
        totalStep,
        phone_number,
        JSON.stringify(childState)
      ]);

      useEffect(() => {
        if (typeof window !== 'undefined') {
          // Get childState and show_program_id directly from localStorage
          const childState = JSON.parse(localStorage.getItem('childState')) || {};
          const localProgramId = props.programId;
          const stateProgramId = childState.program_id;
    
          // Compare program IDs
          if (localProgramId !== stateProgramId) {
            setStep(0);
          }
        }
      }, []);

    useEffect(()=>{
 
        let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : null
        if(program && program.entry_criteria?.includes('phone_number')){
            setTotalStep('6')
        }
        else{
            setTotalStep('4')
        }
    }, [])
    const checkUserExistence = (email, provider) => {
        let self = this;
        let finaljson = {
            email: email
        }
        functions.CheckUserExistenceInOldNewSytsem(this, finaljson, function (json_response) {
            let response = JSON.parse(json_response)

            if (response?.data?.user_exist_in_old_system == 1) {
                migrateUser(email, provider)
            } else if (response?.data?.success == 0) {
                functions.pushToRoute(self, 'signup', 'home')
            } else if (response?.data?.success == 1 && response?.data?.message == "User Already Exist") {
                functions.getUserData(self, email, provider);
            }
        })
    }

    const migrateUser = (email, provider) => {
        let self = this
        let migration_uri = 'https://request.family.one/request/cron_makeSync.php';
        let dataset = {
            "email": email,
            sync: 0
        }
        axios
            .request({
                url: migration_uri,
                data: JSON.stringify(dataset),
                method: "post"
            })
            .then(function (response) {
                functions.getUserData(self, email, provider);
            });
    }

    const handleVerificationCode = (enteredCode, email_address, action, callback) => {
        if (action === "verify_later") {
            handleContinue();
            return callback(false);
        }

        let finaljson = {
            'email': email_address || localStorage.getItem('email'),
            'action': 'VerifyEmailCode',
            'verificationCode': enteredCode
        }
        functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
            let res_data = response.data
            const self = this;

            if (res_data.success) {
                // handleGetStarted()
                // localStorage.setItem("user_signup_process", "false")
                let user_data = res_data.user_data;
                console.log("CheckUserExistenceAndSendOTP user_data", user_data);
                handleUserLoginProcess(user_data);
                callback(false);
                return
                let subid = user_data.auto_id
                let username1 = user_data.email
                localStorage.removeItem("email_verified")
                localStorage.setItem("auto_id", subid)
                cookie.save("auto_id", subid, { path: "/" })
                cookie.save("username", username1, { path: "/" });
                if (session_id == null) {
                    session.createSession(subid, 'login', function (response) {
                        // session.checkSession(subid, "login", 'false');
                        let element = document.getElementById("login_complete")

                        element.click();
                        if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                            functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                        }
                        else {
                            functions.getUserData(self, username1, 'login')
                        }
                    })
                }
                else {
                    session.checkSession(subid, "login", 'false');
                    let element = document.getElementById("login_complete")

                    if (element && element != null) element.click();

                    if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                        functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                    }
                    else {
                        functions.getUserData(self, username1, 'login')
                    }
                }
                functions.getUserData(self, username1, 'login');
                handleContinue()
                callback(false)
            }else{
                callback("Invalid Verification code")
                // setError_mess('Invalid Verification code')
                // setTimeout(()=>{
                //     setError_mess(false)
                // }, 7500)

            }

        })
    }

    const handleResendOTP = (event, callback) => {
        setTimeout(()=>{
            handleVerificationCode('', '', 'verify_later', (res)=>{})
        }, 800)
        // let finaljson = {
        //     'email': email_address || localStorage.getItem('email'),
        //     'session_id': cookie.load("session_id") ? cookie.load("session_id") : "",
        //     'session_entered_id': localStorage.getItem('session_entered_id') ?  localStorage.getItem('session_entered_id') : cookie.load("session_entered_id") || "",
        //     'action': 'SendEmailVerificationCode'
        // }

        // functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
        //     const res_data = response.data

        //     if (res_data.existing === 1) {
        //         localStorage.setItem('email', finaljson.email)
        //         setCodeSent(true);
        //         setloader(false)
        //         setStep(2)
        //     }
        //     callback(true)
        // })
    }

    const handleContinue = async (action = "User clicked on Continue button on buildfamily page") => {
        let element = document.getElementById("signup_add_child")
        if (localStorage.getItem("session_type") && localStorage.getItem("session_type") == "signup") {
            // 
            if(element) element.click();
            let userdata = [];
            // add campaign id and signup status in user info
            let campaign_id = cookie.load("camp_id") ? cookie.load("camp_id") : ""
            let dynamo_userdata = { 'campaign_id': campaign_id, 'signup_status': "completed" };
            functions.updateUserData(this, userdata, dynamo_userdata);
        }
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : "null"

        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : "null";
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.getUserProfile(this, finaljson, function (response_json) {
                let response = JSON.parse(response_json);
              
                if (response.catch_error) {
                    let user_action = "User got error on profile/index file componentWillMount function";
                    let body = response.message
                    functions.logUserError(user_action, body);
                    // self.setState({ loader: false, show_error_message: response.message })
                }
                else if (!response.catch_error) {

                    if (response.data.error === 1) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    }

                    else if (response.status === 200) {
                       
                            let data = response.data;
                            let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                            let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                            let relation_data = data.relation_data ? data.relation_data : {}
                            let program_data = data.program_data ? data.program_data : []
                            let winner_data = data.winner_data ? data.winner_data : [];
                            let user_status = dynamo_data.email_verified == 'true' || dynamo_data.email_verified == true ? 'Confirmed' : 'Not verified'
                            functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data);

                        
                    }
                }
            })
        }
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        // 
        let revert_page = localStorage.getItem("revert_page") ? localStorage.getItem("revert_page") : "";
        // 
        let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
        localStorage.setItem("sign_otp", "true")
        setgetStartedLoader(false)
        let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : null;
        console.log('program', program)
        let partner_name = functions.getPartnerNameById(program.partner_id)
        let user_signup_process = localStorage.getItem('user_signup_process')
        if(!user_signup_process || user_signup_process != 'true'){
            localStorage.setItem('user_signup_process', 'false')
        }
        if(program && !program.program_type && program.perk_title){
            localStorage.setItem("detail_page", 'true')
            localStorage.setItem("previous_state", 'main_index');
            Router.push('/home/perks_detail');
        } else if (previous_state == "program_detail" || revert_page == "program_detail") {
            localStorage.setItem("detail_page", 'true')
            localStorage.setItem("previous_state", 'main_index')
            // functions.pushToRoute(this, "main", 'buildfamily')
            const storedProgram = localStorage.getItem('program');

            if (storedProgram) {
                // Parse the program object from JSON
                const program = JSON.parse(storedProgram);

                // Check if 'perk_title' key exists
                if ('perk_title' in program) {
                    Router.push('/home/perk_detail');
                } else {
                    Router.push('/home/program_detail');
                }
            } else {
                console.error('Program not found in localStorage');
            }

        } else {
            if (perks_or_main == "perks") {
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this, "perks", 'buildfamily')
            }
            else {
            localStorage.setItem("previous_state", 'main_index')

                localStorage.setItem("detail_page", 'false')
                // functions.pushToRoute(this, "main", 'buildfamily')
                const storedProgram = localStorage.getItem('program');

                if (storedProgram) {
                // Parse the program object from JSON
                const program = JSON.parse(storedProgram);

                // Check if 'perk_title' key exists
                if ('perk_title' in program) {
                    Router.push('/home/perk_detail');
                } else {
                    Router.push('/home/program_detail');
                }
                } else {
                console.error('Program not found in localStorage');
                }

            }
        }
    }

    const handleChildStateUpdate = (newState) => {


        // Ensure newState is an object, defaulting to an empty object if it's not
        const newItems = typeof newState === 'object' && newState !== null ? newState : {};

        // Update childState by merging newItems into the previous state
        setchildState(prevChildState => ({
            ...prevChildState, // Keep existing state
            ...newItems // Merge with new items
        }));
    };


    const handleClick = (value) => {
        // let action = "User clicked on " + value + " on home page"
        // let image = 'user_clicked_on_' + value + '.png'
        // functions.logUserActivity(action, image);
        if (value === "facebook") {
            let element = document.getElementById("facebook_button")
            element.click();
        }
    }

    const responseGoogle = (response) => {

    }

    const handleEmailAddress = (event) => {
        localStorage.removeItem('family_name');
        localStorage.removeItem('given_name');
        setShowEmailInput(true)
        setStep(steps + 1)
        // props.handleShowEmailInput(true)
    }

    const InvokeSendOTP_Session = () => {
        if (!email_address) {
            setErrorMessage("Please type Email Address!");
            setTimeout(() => {
                setErrorMessage(false);
            }, 3000)
            setloader(false)
        } else {
        let self = this;
        let finaljson = {
            'email': email_address,
            'session_id': cookie.load("session_id") ? cookie.load("session_id") : "",
            'session_entered_id': localStorage.getItem('session_entered_id') ?  localStorage.getItem('session_entered_id') : cookie.load("session_entered_id") || "",

            'action': 'SendEmailVerificationCode'
        }
        let address = localStorage.getItem('address');
        let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {};
        let phone_required = program?.entry_criteria?.includes('phone_number');
    
        if (typeof address === "string") {
            try {
                address = JSON.parse(address);
            } catch (error) {
                console.error("Error parsing address:", error);
                address = {}; // Default to empty object on parse failure
            }
        }
    
        setchildState(prevState => ({
            ...prevState,
            familyName: localStorage.getItem("family_name") || '',
            givenName: localStorage.getItem("given_name") || '',
            gender: localStorage.getItem("gender") || '',
            ...address,
            "PO Box": localStorage.getItem("PO Box") || "",
            child_data: JSON.parse(localStorage.getItem("child_data")) || []
        }));
    
        let fb_signup = localStorage.getItem("login_via_fb") === 'true';
        let ggl_signup = localStorage.getItem("login_via_google") === 'true';
        let tracking_id = localStorage.getItem("tracking_id") || '';
        let { program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria } = program;
        entry_criteria = JSON.parse(entry_criteria)
        let sub_id = localStorage.getItem("auto_id");
    
        if (!localStorage.getItem('family_name') || !localStorage.getItem('given_name') || !localStorage.getItem('gender')) {
            setloader(false);
            setStep(3);
        } else if (!address) {
            setloader(false);
            setStep(4);
        } else if (!localStorage.getItem('child_data') || !JSON.parse(localStorage.getItem('child_data')).length) {
            localStorage.setItem('current_state', 'addchild');
            setloader(false);
            setStep(5);
        } else if (phone_required && !localStorage.getItem('phone_number')) {
            setloader(false);
            setStep(6);
        } else if (fb_signup || ggl_signup) {
            let fb_entered_pid = localStorage.getItem('fb_entered_pid') || '';
            handleAutoInvokeProgram(fb_entered_pid, program);
            handleContinue("User clicked on Facebook/Google button on buildfamily page");
        } else {
            if ((!is_lock_program || is_lock_program === 0) && program_type !== 'survey' && (partner_id !== '2' ) && !entry_criteria?.includes('baby_registry')) {
                let point_type = 'entry';
                let is_direct = 1;
                let consent = 'yes'
                functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id || '', consent, '', '', function(res){
                    console.log("Contest entry Invoked")
                });
                // triggerCAPIANDPixel(program)
                
            } else if (!is_lock_program || is_lock_program === 0) {
                localStorage.setItem('auto_invoke_program', program_id);
            }
            setTimeout(()=>{
                handleVerificationCode('', '', 'verify_later', (res)=>{})
            }, 800)
            // functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
            //     const res_data = response.data
            //     if (res_data.existing === 1) {
            //         setCodeSent(true);
            //         setloader(false)
            //         setStep(2)
            //     }
            // })
        }
    }
    };

    const handleUserLoginProcess= (user_data)=>{
        try{
            let auto_id = user_data?.auto_id;
            console.log("auto_id", auto_id)
            if(user_data?.phone_number && (user_data?.phone_verified == true || user_data?.phone_verified == "true")){
                localStorage.setItem('phone_number', user_data.phone_number);
                setPhoneNumber(user_data.phone_number);
            }
            if(user_data?.gender) localStorage.setItem('gender', user_data?.gender);
            if(user_data?.family_name) localStorage.setItem('family_name', user_data?.family_name)
            if(user_data?.given_name) localStorage.setItem('given_name', user_data?.given_name)
            if(user_data?.address && user_data?.address != '') localStorage.setItem('address', user_data?.address);
            if(user_data?.child_data && user_data?.child_data != []) localStorage.setItem('child_data', JSON.stringify(user_data?.child_data));

            localStorage.setItem('auto_id', auto_id);
            localStorage.setItem('email', email_address);
            cookie.save("auto_id", auto_id, { path: "/" });
            cookie.save("username", email_address, { path: "/" });
            let address = user_data.address;
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {};
            let phone_required = program?.entry_criteria?.includes('phone_number');
            setTimeout(() => {
                handleAutoInvokeProgram('', program)
            }, 300);
            if (typeof address === "string") {
                try {
                    address = JSON.parse(address);
                } catch (error) {
                    console.error("Error parsing address:", error);
                    address = {};  // Default to an empty object on parse failure
                }
            }
            // console.log("sdfghrds", childState)
            setchildState((prevState) => { 
                    return ({ 
                ...prevState,  // Preserve the previous state
                familyName: user_data?.family_name || '',
                givenName: user_data?.given_name || '',
                gender: user_data?.gender || '',
                ...address,  // Spread address attributes directly
                "PO Box": localStorage.getItem("PO Box") ? localStorage.getItem("PO Box") : address?.PO_Box || "",
                child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : user_data?.child_data || [],
            })});
            
            let fb_signup = localStorage.getItem("login_via_fb")
            let ggl_signup = localStorage.getItem("login_via_google");
            // declared variable to perform contest entry
            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria} = program;
            entry_criteria = JSON.parse(entry_criteria);
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");

            // Check if required data for each step is present
            if (!user_data?.family_name || !user_data?.given_name || !user_data?.gender) {
                // Navigate to Step 2
                setloader(false)

                setStep(3);
            } else if (!user_data?.address) {
                // Navigate to Step 3
                setloader(false)

                setStep(4);
            } else if (!user_data?.child_data || !Array.isArray(user_data.child_data) || user_data.child_data.length === 0) {
                // Navigate to Step 4
                localStorage.setItem('current_state', 'addchild')
                setloader(false)

                setStep(5);
            }  else if(phone_required && !user_data?.phone_number){
                // Navigate to Mobile entry screen
                    setloader(false)
                    setStep(6);
            } else if ((fb_signup && (fb_signup == 'true' || fb_signup == true)) || (ggl_signup && (ggl_signup == 'true' || ggl_signup == true))){
                let fb_entered_pid = localStorage.getItem('fb_entered_pid') || ''
                handleAutoInvokeProgram(fb_entered_pid, program)
                setTimeout(() => {
                    handleContinue("User click on Facebook/Google button on buildfamily page")
                }, 1100);
            } else {
                if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' && !entry_criteria?.includes('baby_registry') ){
                    let point_type = 'entry';
                    let is_direct = 1;
                    let consent = 'yes'
                    functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function(res){
                        console.log("Contest entry Invoked")
                    })
                    // triggerCAPIANDPixel(program)
                }else if (!is_lock_program || is_lock_program == 0){
                    localStorage.setItem('auto_invoke_program', program_id)
                }
                setTimeout(()=>{
                    handleContinue("")
                }, 800)                                    
            }
        }catch(error){
            console.error("Catches Error While HandleUserLogin Process", error)
        }   
    }
    

    const InvokeSendOTP = () => {
        setloader(true);
        setErrorMessage(false);
        if (!email_address) {
            setErrorMessage("Please type Email Address!");
            setTimeout(() => {
                setErrorMessage(false);
            }, 3000)
            setloader(false)
        } else {
            let self = this;
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            // Test the input email against the regex pattern
            let validateEmail = emailPattern.test(email_address);
            let email_verified = localStorage.getItem('email_verified')
            if (validateEmail) {
                let finaljson = {
                    'email': email_address,
                    'session_id': cookie.load("session_id") ? cookie.load("session_id") : "",
                    'session_entered_id': localStorage.getItem('session_entered_id') ?  localStorage.getItem('session_entered_id') : cookie.load("session_entered_id") || "",
                    'action': 'SendEmailVerificationCode'
                }
                
                functions.CheckPasswordExistence('', email_address, function (response) {
                    response = JSON.parse(response);
                    console.log("CheckPasswordExistence response", response)
                    if (response?.status == 200 && response?.data?.success === 1) {
                        let user_data = response.data?.data?.Items[0];
                        let auto_id = user_data?.auto_id
                        localStorage.setItem('auto_id', auto_id);
                        localStorage.setItem('email', email_address);
                        cookie.save("auto_id", auto_id, { path: "/" });
                        cookie.save("username", email_address, { path: "/" });
                        functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
                            const res_data = response.data
                            if (res_data.existing === 1) {
                                setCodeSent(true);
                                setloader(false)
                                setStep(2)
                            }
                        });
                        console.log("State User data", user_data)                        
                    } else {
                        functions.ValidateEmailForBounce(this, finaljson, function (response_json1) {
                            let response1 = JSON.parse(response_json1);
                            let valid = response1?.data?.data?.response?.result ?? null;
                            if (valid === 'valid' || valid === "catchall" || valid === "unknown") {
                                setchildState(({ program_id }) => ({program_id}));
                                // Generate password
                                let password = functions.makeid(8);
                                let family_name = localStorage.getItem('family_name') || 'null' 
                                let given_name = localStorage.getItem('given_name') || 'null'      
    
                                let signup_json = {
                                    username: email_address,
                                    password: password,
                                    session_id: session_id || "",
                                    provider: localStorage.getItem('provider') || "familyone",
                                    attributes: [
                                        { Name: "email", Value: email_address },
                                        { Name: "name", Value: 'null' },
                                        { Name: "given_name", Value: given_name },
                                        { Name: "family_name", Value: family_name },
                                        { Name: "picture", Value: 'no_image' },
                                        { Name: "preferred_username", Value: email_address }
                                    ]
                                };
                                // Fire and forget - call the adminCreateUser function without waiting for its response
                                functions.adminCreateUser(this, signup_json, function (response) {
                                    // This will execute when the response is returned, but we don't wait for it
                                    response = JSON.parse(response);
                                    console.log("adminCreateUser response", response)
                                    if (response?.data?.success === 1 || response?.data?.userprofiledata?.UserAttributes?.auto_id) {
                                        setloader(false); // Set loader state to false immediately
                                        let auto_id = response.data.userprofiledata?.UserAttributes?.auto_id;
                                        let email = response.data.userprofiledata.UserAttributes.email || email_address;
                                        let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {};
                                        localStorage.setItem('auto_id', auto_id);
                                        handleAutoInvokeProgram('', program)
                                        if(given_name && given_name !== 'null') localStorage.setItem('given_name', given_name);
                                        if(family_name && family_name !== 'null') localStorage.setItem('family_name', family_name);
                                        if(email_verified == 'false') localStorage.setItem('email_verified', 'false');
                                        localStorage.setItem('email', email);
                                        cookie.save("auto_id", auto_id, { path: "/" });
                                        cookie.save("username", email, { path: "/" });
                                        
                                        if (localStorage.getItem('auto_id') === auto_id) {
                                            console.log("1")
                                            setStep(3); // Move to the next screen
                                        } else {
                                            console.log("2")

                                            let attempts = 0;

                                            const checkAutoId = () => {
                                                attempts++;
                                                if (localStorage.getItem('auto_id') === auto_id) {
                                                    setStep(3); // Move to the next screen if found
                                                } else if (attempts < 5) {
                                                    setTimeout(checkAutoId, 500); // Retry after 500ms
                                                } else {
                                                    Router.push("/"); // Redirect after 5 failed attempts
                                                }
                                            };

                                            checkAutoId(); // Start the checking process
                                        }
                                    } else {
                                        setloader(false); // Set loader state to false immediately
                                        setErrorMessage("User Already Exist!");
                                    }
                                });

                        } else {
                            const updatedChildState = {
                                ...childState,
                                email_address: "", // Set email_address to an empty string
                                };
                            
                                localStorage.setItem('childState', JSON.stringify(updatedChildState));
                            setErrorMessage('Provided Email is not Valid! Try other email');
                            setTimeout(() => {
                                setErrorMessage(false);
                            }, 3000)
                            setloader(false)
                        }
                    })


                    }
                })
                    
            } else {
                const updatedChildState = {
                    ...childState,
                    email_address: "", // Set email_address to an empty string
                  };
               
                  localStorage.setItem('childState', JSON.stringify(updatedChildState));
                setErrorMessage("Provided Email is not Valid! Try other email");
                setTimeout(() => {
                    setErrorMessage(false);
                }, 3000)
                setloader(false)
            }
        }

    }

    const handleSetStep = (step, action) => {
        if (action === 'forward') {
            setStep(step + 1)
        } else if (action === 'backword') {
            setStep(step - 1)
        }
    }

    const updateUser = (dynamo_data) => {
        try{
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
            if(program?.partner_id == '14') dynamo_data['cst_consent'] = 'true'
            if(facebook_id) dynamo_data['facebook_id'] = facebook_id;
            if(google_id) dynamo_data['google_id'] = google_id
            if (
                ((localStorage.getItem('login_via_google') === 'true') || (localStorage.getItem('login_via_fb') === 'true')) &&
                localStorage.getItem('email_verified') !== 'false'
            ) {
                functions.invokeUserEmailVerify();
            }
            
            functions.updateUserData(this, [], dynamo_data);
            setStep(4)
        }catch(error){
            console.log("updateUser catches error", error)
        }
        
    }

    const handleAddressStep4 = (event) => {

        setStep(5)
    }
    const handleAddChildStep5 = (event) => {
        if (event) {
            setStep(5)
        }
    }

    const triggerCAPIANDPixel=(program)=>{
        try{
            const isEligibleUser= functions.checkUserEligibility();
            let partner_name = functions.getPartnerNameById(program.partner_id)
            if(isEligibleUser){
                trackEvent(partner_name + '_' + program.program_id + "_NextJS", { event_action: 'User sucessfully signup and redirect' });   
            }
        }catch(error){
            console.log("trackEvent Catches error", error)
        }
    }

    const handleAutoInvokeProgram=(fb_entered_pid, program)=>{
        try{
            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            if(fb_entered_pid){
                let program_list = localStorage.getItem('program_list') ? JSON.parse(localStorage.getItem('program_list')) : []
                program = program_list?.length && program_list.filter(data=> data.program_id == fb_entered_pid);
                setTimeout(()=>{
                    localStorage.removeItem('fb_entered_pid')
                }, 3000);
                console.log("fb_entered_pid program", program)
                let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria} = program[0] ;
                entry_criteria = JSON.parse(entry_criteria)
                if(((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' && !entry_criteria?.includes('baby_registry')) || partner_id == '14' ){
                    let point_type = 'entry';
                    let is_direct = 1;
                    let consent = 'yes'
                    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
                    functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function(res){
                        console.log("contestEntry invoked", program_id)
                    })
                    // triggerCAPIANDPixel(program)
                }else if (!is_lock_program || is_lock_program == 0){
                    console.log("fb_entered_pid auto_invoke_program id", program_id)
                    localStorage.setItem('auto_invoke_program', program_id)
                }
            }else{
                console.log("program", program)
                let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria} = program;
                entry_criteria = JSON.parse(entry_criteria)
                if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' && !entry_criteria?.includes('baby_registry') ){
                    let point_type = 'entry';
                    let is_direct = 1;
                    let consent = 'yes'
                    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
                    functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function(res){
                        console.log("contestEntry invoked", program_id)
                    })
                    // triggerCAPIANDPixel(program)
                }else if (!is_lock_program || is_lock_program == 0){
                    localStorage.setItem('auto_invoke_program', program_id);
                    console.log("auto_invoke_program id", program_id)
                }
            }
        }catch(error){
            console.log("handle Auto Invoke Program catches error", error)
        }
    }

    const processFBGoogleFlow = (provider, email, displayName, family_name, given_name) => {
        try{
            let self= this;
            functions.CheckPasswordExistence('', email, function (response) {
                response = JSON.parse(response);
                console.log("responseresponse", response)
                localStorage.setItem('auto_id', response?.data?.data?.Items?.[0]?.auto_id);
                localStorage.setItem('email', response?.data?.data?.Items?.[0]?.email);
                cookie.save("auto_id", response?.data?.data?.Items?.[0]?.auto_id, { path: "/" });
                let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {};
                let phone_required = program?.entry_criteria?.includes('phone_number');
                console.log("phone_required", phone_required)
                if (provider.includes('google')) {
                    localStorage.setItem('login_via_google', "true")
                }
                if (provider.includes('facebook')) {
                    localStorage.setItem('login_via_fb', "true")
                }
                if (response?.data?.success === 1) {
                    // localStorage.setItem("user_signup_process", "false")
                    let user_data = response?.data?.data?.Items?.[0];
                    if(user_data?.phone_number &&  (user_data?.phone_verified == true || user_data?.phone_verified == "true")){
                        localStorage.setItem('phone_number', user_data.phone_number);
                        setPhoneNumber(user_data.phone_number)
                    }
                    if(user_data?.gender) localStorage.setItem('gender', user_data?.gender);
                    if(user_data?.family_name) localStorage.setItem('family_name', user_data?.family_name)
                    if(user_data?.given_name) localStorage.setItem('given_name', user_data?.given_name)
                    if(user_data?.address && user_data?.address != '') localStorage.setItem('address', user_data?.address);

                    let subid = user_data.auto_id;
                    localStorage.setItem("auto_id", subid);
                    localStorage.setItem("email", email)
                    cookie.save("auto_id", subid, { path: "/" })
                    cookie.save("username", email, { path: "/" });
                    let address = response.data?.data?.Items[0]?.address;
                    if (typeof address === "string") {
                        try {
                            address = JSON.parse(address);
                        } catch (error) {
                            console.error("Error parsing address:", error);
                            address = {};  // Default to an empty object on parse failure
                        }
                    }
                    setchildState((prevState) => { 
                        return ({ 
                      ...prevState,  // Preserve the previous state
                      familyName: response.data?.data?.Items[0]?.family_name || '',
                      givenName: response.data?.data?.Items[0]?.given_name || '',
                      gender: response.data?.data?.Items[0]?.gender || '',
                      ...address,  // Spread address attributes directly
                      "PO Box": localStorage.getItem("PO Box") ? localStorage.getItem("PO Box") : address?.PO_Box || "",
                      child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : response.data?.data?.Items[0]?.child_data || [],
                  })});
                    if (!response.data?.data?.Items[0]?.family_name || !response.data?.data?.Items[0]?.given_name || !response.data?.data?.Items[0]?.gender) {
                        // Navigate to Step 2
                        setloader(false)
                        setgetStartedLoader(false)
                        setStep(3);
                    } else if (!response.data?.data?.Items[0]?.address) {
                        // Navigate to Step 3
                        setloader(false)
                        setgetStartedLoader(false)
    
                        setStep(4);
                    } else if (!response.data?.data?.Items[0]?.child_data || !Array.isArray(response.data?.data?.Items[0].child_data) || response.data?.data?.Items[0].child_data.length === 0) {
                        // Navigate to Step 4
                        localStorage.setItem('current_state', 'addchild')
                        setloader(false)
                        setgetStartedLoader(false)
    
                        setStep(5);
                    } else if(phone_required && !response.data?.data?.Items[0]?.phone_number){
                        // Navigate to Mobile entry screen
                         setloader(false)
                         setStep(6);
                    } else {
                        let username1 = email;
                        const fb_entered_pid = localStorage.getItem('fb_entered_pid');
                        
                        handleAutoInvokeProgram(fb_entered_pid, program)

                        if (session_id == null) {
                            session.createSession(subid, 'login', function (response) {
                                // session.checkSession(subid, "login", 'false');
                                let element = document.getElementById("login_complete")
        
                                if (element && element != null) element.click();
                                if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                    functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                                }
                                else {
                                    functions.getUserData(self, username1, 'login')
                                }
                            })
                        } else {
                            session.checkSession(subid, "login", 'false');
                            let element = document.getElementById("login_complete")
        
                            if (element && element != null) element.click();
        
                            if (cookie.load("accept_auto_id") && cookie.load("relative_id") && cookie.load("accept_auto_id") == cookie.load("auto_id")) {
                                functions.addRelative(self, subid, cookie.load("relative_id"), username1);
                            }
                            else {
                                functions.getUserData(self, username1, 'login')
                            }
                        }
                        functions.getUserData(self, username1, 'login'); 
                        setTimeout(()=>{
                            handleContinue("User click on " + provider + " button on buildfamily page")
                        }, 1100)                       
                    }
                } else {
                    let password = functions.makeid(8);
                    let signup_json = {
                        username: email,
                        password: password,
                        session_id: session_id || "",
                        provider: provider,
                        attributes: [
                            { Name: "email", Value: email },
                            { Name: "name", Value: displayName },
                            { Name: "given_name", Value: given_name },
                            { Name: "family_name", Value: family_name },
                            { Name: "picture", Value: 'no_image' },
                            { Name: "preferred_username", Value: email }
                        ]
                    };
                    functions.adminCreateUser(this, signup_json, function (response) {
                        response = JSON.parse(response);
                        if (response?.data?.success == 1 || response?.data?.userprofiledata?.Username == email || response?.data?.userprofiledata?.UserAttributes?.auto_id) {
                            let auto_id = response.data.userprofiledata?.UserAttributes?.auto_id;
                            console.log("autoID", auto_id)
                            localStorage.setItem('auto_id', auto_id);
                            localStorage.setItem('email', email);
                            localStorage.setItem('given_name', given_name)
                            localStorage.setItem('family_name', family_name)
                            cookie.save("auto_id", auto_id, { path: "/" });
                            cookie.save("username", email, { path: "/" });
                            setgetStartedLoader(false)
                            setStep(3)
                        } else {
                            setErrorMessage("Something Went Wrong!");
                        }
                    });
                }
            })
        }catch(error){
            console.log("processFBGoogleFlow Catches Error", error)
        }
        
    }

    function sanitizeName(name) {
        return name.replace(/[^a-zA-Z0-9 ]/g, '');
      }
    

    const handleGoogleClick = async (user) => {
        // Your web app's Firebase configuration (already provided);
        setgetStartedLoader(true)
        try {
            
            console.log('User Info:', user);
            let { displayName, email, emailVerified, name} = user;
            if(email){
                localStorage.setItem('email_verified', 'true');
                email = email?.toLowerCase();
                setEmailAddress(email)
                }
           
            let providerData = user?.providerData && user?.providerData[0];
            if(!email) email = providerData?.email;
            if(!displayName) displayName = name;
            const uid = providerData?.uid
            if(uid) {
                localStorage.setItem('google_id', uid);
                setGoogleID(uid)
            }
            const given_name = displayName?.split(' ')[0] && sanitizeName(displayName.split(' ')[0]) ;
            const family_name = displayName?.split(' ')[1] ? sanitizeName(displayName.split(' ')[1]) : given_name;
            localStorage.setItem('family_name', family_name);
            localStorage.setItem('given_name', given_name);
            localStorage.setItem('email', email)
            if (email && displayName) {
                processFBGoogleFlow("google", email, displayName, family_name, given_name);
            } else if(uid && displayName && !email){
                if(providerData.providerId) localStorage.setItem('provider', providerData.providerId)
                localStorage.setItem('google_uid', uid);
                localStorage.setItem('email_verified', 'false');
                localStorage.setItem('login_via_google', "true")
                setStep(1)
                setgetStartedLoader(false)
            } else {
                setgetStartedLoader(false)
                setError_mess("After facebook login, Data didn't returned")
                // window.location = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=231822734692164&redirect_uri=${url.family_one_url}&response_type=token&scope=email`);
            }

            // Handle user info or tokens here, e.g., save to your state or context
        } catch (error) {
            console.log("handleGoogleClick Catch error", error)
            setgetStartedLoader(false)
            setError_mess("Something Went Wrong")
        }

    }

    const addChild = () => {
        console.log("addChild invoked from family")
        setStep(6)
    }

    const handleClickedContinue = (event) => {
        console.log("handleClickedContinue Invoked", event);
        let self = this;
        if (event === "address_completed") {
            setStep(5);
        } else if (event === 'add_child_completed') {
            setloader(true)
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
            let program_id = localStorage.getItem('entered_id') || program.program_id;
            console.log(program_id, "program", program)
            let sub_id = localStorage.getItem('auto_id');
            // functions.checkProgramCriteria(self, program_id, sub_id, function (response_json) {
            let phone_required = program?.entry_criteria?.includes('phone_number');
            
            console.log("phone_required", phone_required)
            if(!phone_required || phone_number || localStorage.getItem('phone_number')){
                let fb_signup = localStorage.getItem("login_via_fb")
                let ggl_signup = localStorage.getItem("login_via_google")
                let email_verified = localStorage.getItem('email_verified');
                let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria} = program;
                entry_criteria = JSON.parse(entry_criteria)
                let point_type = 'entry';
                let is_direct = 1;
                let consent = 'yes'
                const email = localStorage.getItem('email');
                // this function is call to invoke program entry process when user add their child and click on continue
                if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' && !entry_criteria?.includes('baby_registry') ){
                    functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function(res){

                    })
                    // triggerCAPIANDPixel(program)
                }else if (!is_lock_program || is_lock_program == 0){
                    localStorage.setItem('auto_invoke_program', program_id)

                    console.log("auto_invoke_program id set into local", program_id)
                }
                if ((email_verified == null || email_verified != 'false') && ((fb_signup && (fb_signup == 'true' || fb_signup == true)) || (ggl_signup && (ggl_signup == 'true' || ggl_signup == true)))) {
                    console.log("ggl_signup", ggl_signup)
                    functions.getUserData(self, email, 'login');
                    setTimeout(()=>{
                        handleContinue(`User click on ${fb_signup ? 'Facebook' : "Google"} button on buildfamily page`)
                    }, 700)
                } else {
                    // let finaljson = {
                    //     'email': email_address || localStorage.getItem('email'),
                    //     'session_id': cookie.load("session_id") ? cookie.load("session_id") : "",
                    //     'session_entered_id': localStorage.getItem('session_entered_id') ?  localStorage.getItem('session_entered_id') : cookie.load("session_entered_id") || "",
                    //     'action': 'SendEmailVerificationCode'
                    // }
                    setTimeout(()=>{
                        handleVerificationCode('', '', 'verify_later', (res)=>{})
                    }, 800)                    // functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
                    //     const res_data = response.data

                    //     if (res_data.existing === 1) {
                    //         localStorage.setItem('email', finaljson.email)
                    //         setCodeSent(true);
                    //         setloader(false)
                    //         setStep(2)
                    //     }
                    // })
                }
            }else{
                setloader(false);
                setStep(6);                
            }
            // })
            
        } else if(event === 'mobile_verification_completed'){
            let fb_signup = localStorage.getItem("login_via_fb")
            let ggl_signup = localStorage.getItem("login_via_google")
            let email_verified = localStorage.getItem('email_verified');
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
            let sub_id = localStorage.getItem('auto_id');
            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program, entry_criteria} = program;
            entry_criteria = JSON.parse(entry_criteria)
            let point_type = 'entry';
            let is_direct = 1;
            let consent = 'yes'
            if(((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' && !entry_criteria?.includes('baby_registry')) || partner_id == '14') {
                functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function(res){
                    
                });
                // triggerCAPIANDPixel(program)
                
                
            }else if (!is_lock_program || is_lock_program == 0){
                localStorage.setItem('auto_invoke_program', program_id)
                console.log("auto_invoke_program id set into local", program_id)
            }
            if ((email_verified== null || email_verified != 'false') && ((fb_signup && (fb_signup == 'true' || fb_signup == true)) || (ggl_signup && (ggl_signup == 'true' || ggl_signup == true)))) {
                console.log("ggl_signup", ggl_signup)
                const email = localStorage.getItem('email');
                
                functions.getUserData(self, email, 'login');
                setTimeout(()=>{
                    handleContinue(`User click on ${fb_signup ? 'Facebook' : "Google"} button on buildfamily page`)
                }, 700)
            } else {
                // let finaljson = {
                //     'email': email_address || localStorage.getItem('email'),
                //     'session_id': cookie.load("session_id") ? cookie.load("session_id") : "",
                //     'session_entered_id': localStorage.getItem('session_entered_id') ?  localStorage.getItem('session_entered_id') : cookie.load("session_entered_id") || "",
                //     'action': 'SendEmailVerificationCode'
                // }
                setTimeout(()=>{
                    handleVerificationCode('', '', 'verify_later', (res)=>{})
                }, 800)                // functions.CheckUserExistenceAndSendOTP(finaljson, function (response) {
                //     const res_data = response.data
                //     if (res_data.existing === 1) {
                //         localStorage.setItem('email', finaljson.email)
                //         setCodeSent(true);
                //         setotpVerified(false);
                //         setloader(false);
                //         setStep(2)
                //     }
                // })
            }
        }
    }

    // Refs for each step section
    const stepRefs = useRef([]);

    // Add refs dynamically as steps are rendered
    useEffect(() => {
        stepRefs.current = stepRefs.current.slice(0, steps + 1);  // Ensure refs array is up to date
    }, [steps]);

    useEffect(() => {
        // Scroll to the bottom of the current section
        if (stepRefs.current[steps]) {
            // Get the bottom of the current section by using scrollHeight
            stepRefs.current[steps].scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [steps]);

    const handleFacebookClick = (response) => {
        try{
            setgetStartedLoader(true)
            let displayName = response?.name || null, email = response?.email || null, facebook_id = response?.id ?? null;
            setfacebookID(facebook_id)            
            localStorage.setItem('facebook_id', facebook_id);
            if(email){
                localStorage.setItem('email_verified', 'true');
            email = email?.toLowerCase();
            setEmailAddress(email)
            }
            const given_name = displayName?.split(' ')[0] && sanitizeName(displayName.split(' ')[0]);
            const family_name = displayName?.split(' ')[1] ? sanitizeName(displayName.split(' ')[1]) : given_name
            localStorage.setItem('family_name', family_name);
            localStorage.setItem('given_name', given_name)
            localStorage.setItem('provider', 'facebook')
            if (email && displayName) {
                localStorage.setItem('email', email);
                processFBGoogleFlow("facebook", email, displayName, family_name, given_name);

            } else if(displayName && !email){
                setgetStartedLoader(false)
                localStorage.setItem('email_verified', 'false');
                localStorage.setItem('login_via_fb', "true")
                setStep(1)
            } else {
                setgetStartedLoader(false)
                setError_mess("After facebook login, Data didn't returned")
            }
        }catch(err){
            setgetStartedLoader(false)
            setError_mess("Something Went Wrong")
        }
        

    }

    return (
        <div>
            <div id="facebook_button"></div>
            <div id="googleButton"></div>

            <div className="signupConectCircle padding-top_6_per signupConectCircleNxt">
                {true &&
                    <div className="signupConect">
                    {/* {console.log("childState", childState)} */}
                        {steps === 0 &&
                            <div ref={(el) => stepRefs.current[0] = el}>
                                <GetStarted
                                    handleEmailAddress={handleEmailAddress}
                                    handleGoogleClick={handleGoogleClick}
                                    handleClick={handleClick}
                                    handleFacebookClick={handleFacebookClick}
                                    error_mess = {error_mess}
                                    getStartedLoader={getStartedLoader}
                                    setgetStartedLoader = {setgetStartedLoader}
                                    InvokeSendOTP_Session={InvokeSendOTP_Session}
                                    onUpdate={handleChildStateUpdate}
                                    setEmailAddress={setEmailAddress}

                                />
                            </div>
                        }

                        {steps === 1 &&
                            <div ref={(el) => stepRefs.current[1] = el}>
                                <EnterEmail
                                    setStep={setStep}
                                    totalStep ={totalStep}
                                    errorMessage={errorMessage}
                                    email_address={email_address}
                                    setEmailAddress={setEmailAddress}
                                    InvokeSendOTP={InvokeSendOTP}
                                    loader={loader}
                                />
                            </div>
                        }

                        {steps === 2 && codeSent &&
                            <div ref={(el) => stepRefs.current[2] = el}>
                                <VerifyCode
                                    totalStep ={totalStep}
                                    steps={steps}
                                    email_address={email_address}
                                    handleSetStep={handleSetStep}
                                    handleVerificationCode={handleVerificationCode}
                                    handleResendOTP={handleResendOTP}
                                    error_mess={error_mess}
                                    setError_mess={setError_mess}
                                />
                            </div>
                        }

                        {steps === 3 &&
                            <div ref={(el) => stepRefs.current[3] = el}>
                                <CreateAcount1
                                    totalStep ={totalStep}
                                    steps={steps}
                                    email_address={email_address}
                                    setStep={setStep}
                                    updateUser={updateUser}
                                    onUpdate={handleChildStateUpdate}
                                    childState={childState}
                                />
                            </div>
                        }

                        {steps === 4 &&
                            <div ref={(el) => stepRefs.current[4] = el}>
                                <CreateAcount2
                                    totalStep ={totalStep}
                                    ClickedContinue={handleClickedContinue}
                                    email_address={email_address}
                                    setStep={setStep}
                                    onUpdate={handleChildStateUpdate}
                                    childState={childState}
                                />
                            </div>
                        }

                        {steps === 5 &&
                            <div ref={(el) => stepRefs.current[5] = el}>
                                <CreateAcount3
                                    totalStep ={totalStep}
                                    setStep={setStep}
                                    handleClickedContinue={handleClickedContinue}
                                    onUpdate={handleChildStateUpdate}
                                    childState={childState}
                                    loader={loader}

                                />
                            </div>
                        }

                        {steps === 6 &&
                            <div ref={(el) => stepRefs.current[6] = el}>
                                <EnterMobile totalStep={totalStep} handleClickedContinue={handleClickedContinue} setStep={setStep} handleAutoInvokeProgram={handleAutoInvokeProgram} otp_verified={otpVerified}/>
                            </div>
                        }
                    </div>
                }

            </div>
        </div>

    )
}


export default LoginProcess;
