import React, { Fragment, useState } from "react";

import LottieLoader from '../../LottieLoader/index';
import functions from '../../src/assets/functions/common_functions';
import cookie from 'react-cookies';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { addMonths } from "date-fns";
import Button from '@material-ui/core/Button';

import Router from 'next/router';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { trackEvent } from '../../lib/facebookPixel';
import url from '../../src/assets/url';


const today = new Date();
const maxDate = addMonths(today, 9); // 9 months from today
const { v1: uuidv1 } = require('uuid');
const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const Month = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
};



export default class Index extends React.Component {
    constructor(props) {
        super(props);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : typeof window !== "undefined" ? localStorage.getItem("auto_id"): ''
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
        }
        else {
            Router.push('/');
        }
        this.state = {
            open_edit: false,
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            open: false,
            modal_confirm: false,
            button_selected: 'Unknown',
            dynamic_content: "",
            child_state: 'Expectant',
            minimum_date: "",
            maximum_date: "",
            birthdate_entered: localStorage.getItem("form_type") == "edit_child" ? true : false,
            time: new Date(),
            previous_state: localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "",
            show_date: false,
            isOpen: false,
            child_validate: false,
            select_relation: false,
            addChildParent: false,
            multiple_birthdate: "",
            add_as_relative: localStorage.getItem("add_as_relative") == "true" ? true : false,
            relation_with_child: localStorage.getItem("add_as_relative") == "true" ? "parent" : "parent",
            index: localStorage.getItem("edit_index") ? localStorage.getItem("edit_index") : 0,
            child_date: new Date(),
            child_data: localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [],
            add_child: localStorage.getItem("form_type") == "edit_child" ? false : true,
            creator_auto_id: localStorage.getItem("auto_id"),
            show_add_model: false,
            expectent_child: [],
            existing_child: [],
            editExpectionChildIndex:-1,
            editExistingChildIndex:-1,
            editModalOpen1:false,
            editModalChildren:false,
            editDeleteChildData: null,
            open_dailog : false,
            twins_index: "",
            
            ...props.childState
        };

        this.toggle = this.toggle.bind(this);

        this.handleBack = this.handleBack.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        // Compare current state with previous state to detect changes
        if (prevState !== this.state) {
            this.props.onUpdate(this.state); // Send updated state to parent
        }
    }

    invokeintially = () => {

        let childs_data = this.state.child_data;
        let expectent_child = childs_data?.length > 0 && childs_data.filter(child => child.type == "Expectant")
        let existing_child = childs_data?.length > 0 && childs_data.filter(child => child.type == "Existing")

        this.setState({ existing_child: existing_child, expectent_child: expectent_child })

        if (childs_data && childs_data.length) {
            if (typeof childs_data == 'string') {
                childs_data = JSON.parse(childs_data)
            }
            childs_data.map((child_data) => {
                if (child_data?.child_birthdate?.length <= 9) {
                    let childs = child_data?.child_birthdate.split('-');
                    if (childs[2] && childs[2].length == 1) childs[2] = '0' + childs[2]
                    if (childs[1] && childs[1].length == 1) childs[1] = '0' + childs[1]
                    child_data.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
                    child_data.birthdate_timestamp = new Date(child_data?.child_birthdate).getTime()
                }
                else {
                    child_data.birthdate_timestamp = new Date(child_data?.child_birthdate).getTime()
                }
            })
            localStorage.setItem("child_data", JSON.stringify(childs_data))
            this.setState({ child_data: childs_data })
        }
    }

    componentDidMount = () => {
        this.invokeintially()
        // let image = 'add_child_page_landing.png'
        // functions.logUserActivity("User land on Add child page", image)
        // // this.handleCircleGrowEffect();
        // let childs_data = this.state.child_data;
        // let expectent_child = childs_data.filter(child=> child.type == "Expectant")
        // let existing_child = childs_data.filter(child=> child.type == "Existing")

        // this.setState({existing_child: existing_child, expectent_child: expectent_child})
        // if (childs_data && childs_data.length) {
        //     if (typeof childs_data == 'string') {
        //         childs_data = JSON.parse(childs_data)
        //     }
        //     childs_data.map((child_data) => {
        //         if (child_data.child_birthdate.length <= 9) {
        //             let childs = child_data.child_birthdate.split('-');
        //             if (childs[2] && childs[2].length == 1) childs[2] = '0' + childs[2]
        //             if (childs[1] && childs[1].length == 1) childs[1] = '0' + childs[1]
        //             child_data.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
        //             child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
        //         }
        //         else {
        //             child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
        //         }
        //     })
        //     localStorage.setItem("child_data", JSON.stringify(childs_data))
        //     this.setState({ child_data: childs_data })
        // }
    }

    componentWillMount = () => {
        let { usedFor } = this.props;
        let current_state = localStorage.getItem('current_state');
        if (current_state !== 'addchild' && usedFor !== 'new_login_process') {
            functions.getLandingPage(this);
        }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        if (this.state.add_child) {
            this.toggle();
        }
        else {
            let min = new Date()
            let max = new Date();
            min.setFullYear(min.getFullYear() - 19);
            max.setMonth(max.getMonth() + 9);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            let child_date = this.state?.child_data[this.state?.index]?.child_birthdate
            let time = new Date(child_date)
            this.setState({ minimum_date: minimum_date, maximum_date: maximum_date, time: time, show_date: true })
        }
    }

    formatMinMaxDate = (date) => {
        let date_month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let date_year = date.getFullYear();
        let date_date = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let final_date = date_year + "-" + date_month + "-" + date_date;
        return final_date;
    }


    handleBack = () => {
        let {child_data, newChildData, }= this.state;
        let filteredPendingChild = child_data?.length && child_data.filter(item=> !item.status || item.status != 'pending');
        child_data = filteredPendingChild;
        console.log("child_data", child_data)
        this.setState({'child_data': child_data, show_add_model: null, newChildData:null}, ()=>{
            const { setStep } = this.props;
            if (setStep) setStep(4);
        });
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    handleClickAdd = (action) => {
        this.setState({ loader: false, show_error_message: null, show_add_model: action })

        let { child_data, index } = this.state;
        if(!child_data) child_data = [];
        let filter_pending_child = child_data?.length > 0 && child_data.filter(data=> data.status != 'pending')
        console.log("filter_pending_child", filter_pending_child)
        // child_data.length && child_data.forEach((child, i)=>{
        //     if(!child.child_name && !child.child_birthdate && (!child.child_gender || child.child_gender == 'Unknown') ){
        //         child_data.splice(i, 1)
        //     }
        // })
        index = child_data.length;
        console.log("index", index)

        // if(filter_pending_child?.length> 0){
        child_data = filter_pending_child || []
        index = filter_pending_child.length || 0;
        this.setState({child_data: filter_pending_child, index: index});
        localStorage.setItem("child_data", JSON.stringify(filter_pending_child));
        console.log("filter_pending_child index", index, child_data)
        if(!child_data) child_data = [];

        // }     
        

        let min = new Date()
        let max = new Date();

        min.setFullYear(min.getFullYear() - 19);
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        let child_gender = "Unknown"
        this.setState({ minimum_date: minimum_date, maximum_date: maximum_date })

        if (action) {
            let auto_id = localStorage.getItem('auto_id')
            let data = {
                type: action == 'expecting_child' ? 'Expectant' : action == 'existing_child' ? 'Existing' : '',
                relation_with_child: 'parent',
                creator_auto_id: auto_id,
                child_id: uuidv1(),
                child_gender: action === 'existing_child' ? 'Boy' : 'Unknown',
                status: "pending"
            }
            child_data[index] = data;
            console.log("child_data added", index, child_data)

            this.setState({ index: index, child_data: child_data, newChildData: data })
        } else {
            let {newChildData}= this.state;
            if(newChildData){
                let newChildIndex = child_data?.length && child_data.findIndex(item => item.child_id === newChildData.child_id);
                if(newChildData >= 0){
                    child_data.splice(newChildIndex, 1);
                    this.setState({ child_data: child_data, show_error_message: null, newChildData: null });
                    localStorage.setItem("child_data", JSON.stringify(child_data))
                }
                
            }
        }


    }

    handleAddChild = (value, toggle) => {
        // let action = "User clicked on " + value + " on popup of Add child page"
        // let image = 'user_clicked_' + value + '_button_add_child.png'
        // functions.logUserActivity(action, image)
        let child_date;
        let min = new Date()
        let max = new Date();

        min.setFullYear(min.getFullYear() - 19);
        max.setMonth(max.getMonth() + 9);
        let minimum_date = this.formatMinMaxDate(min);
        let maximum_date = this.formatMinMaxDate(max);
        let child_gender = "Unknown"
        this.setState({ minimum_date: minimum_date, maximum_date: maximum_date})
        if (value == 'Expectant') {
            let child_min = new Date()
            let child_minimum_date = this.formatMinMaxDate(child_min);
            child_date = child_minimum_date

        }
        else if (value == 'Existing') {
            let child_max = new Date()
            let child_maximum_date = this.formatMinMaxDate(child_max);
            child_date = child_maximum_date
            child_gender = "Boy"
        }

        let child_data = localStorage.getItem("child_data") ? JSON.parse(localStorage.getItem("child_data")) : [];
        let child_name_number = child_data && child_data.length + 1;
        let index = child_data && child_data.length;

        let child_name = "Child " + child_name_number
        let type = value
        let child_birthdate = this.formateAddChildDate(child_date);
        let birthdate_timestamp = new Date(child_date).getTime();
        let time = new Date(child_date)
        
        let { relation_with_child, add_as_relative, creator_auto_id } = this.state
        let data = { child_name: child_name, child_gender: child_gender, child_birthdate: child_birthdate, birthdate_timestamp: birthdate_timestamp, type: type, child_id: uuidv1(), relation_with_child: relation_with_child, creator_auto_id: creator_auto_id }
        child_data.push(data)
        this.setState({ time: time, child_data: child_data, add_child: true, index: index, button_selected: "Unknown", show_error_message: '' })
        if (toggle == "true") {
            this.toggle()
            if (add_as_relative == true) {
                this.toggle_select_relation()
            }
        }
    }

    hndleChangeChild = (event, name) => {
        let { child_data, index } = this.state
        child_data[index][name] = event.target.value
        this.setState({ child_data: child_data, activeButton: child_data[index][name]  })
    }

    handleChildDate = (child_date) => {
        let month = child_date.getMonth() + 1
        let date_month = month < 10 ? "0" + month : month;
        let date_year = child_date.getFullYear();
        let date_date = child_date.getDate() < 10 ? "0" + child_date.getDate() : child_date.getDate();
        child_date = date_year + "-" + date_month + "-" + date_date;
        let timestamp = new Date(child_date).getTime()
        let minimum_date = new Date(this.state.minimum_date)
        let maximum_date = new Date(this.state.maximum_date)
        if (timestamp >= minimum_date.getTime() && timestamp <= maximum_date.getTime() || child_date == '') {
            let child_data = this.state.child_data;
            child_data[this.state.index].child_birthdate = child_date;
            let time = new Date(child_date)
            child_data[this.state.index].birthdate_timestamp = new Date(child_date).getTime();
            this.setState({
                child_data: child_data,
                time: time,
                show_date: true,
                show_error_message: '',
                birthdate_entered: true,
                show_error_message: null
            });
        }
    }

    dateFormat = (value) => {
        if (value) {

            let date_array = value.split("-");
            if (date_array && date_array.length > 1) {
                let year = date_array[0];
                let date = date_array[2];
                let month = date_array[1];
                let month_name = Month[month];
                let child_date = date + " " + month_name + " " + year;
                
                return child_date;
            }
            else {
                date_array = value.split("/");
                if (date_array && date_array.length > 1) {
                    let year = date_array[0];
                    let date = date_array[2];
                    let month = date_array[1];
                    let month_name = Month[month];
                    let child_date = date + " " + month_name + " " + year;
                    return child_date;
                }
            }
        } else {
            return value;
        }
    }

    handleSaveChild = (path = 'continue', index) => {
        let self = this;

        const { child_data } = this.state;
        console.log("child_data", this.state.child_data, index)
        if (this.state?.child_data[this.state.index]?.child_birthdate && this.state?.child_data[this.state.index]?.child_birthdate != '' && (this.state?.child_data[this.state.index]?.child_birthdate)?.length > 3 && this.state?.child_data[this.state.index]?.birthdate_timestamp != 0 && this.state?.child_data[this.state?.index]?.child_name != '' && this.state?.birthdate_entered == true) {
            // Create a copy of child_data to avoid mutating the state directly
            const updatedChildData = [...child_data];
            console.log("updatedChildData", updatedChildData)
            updatedChildData.forEach(child => {
                if (!child.child_name || child.child_name.trim() === '') {
                child.child_name = 'Child '+ updatedChildData.length;
                }
            });
            let pending_child_id
            // Set the status to "success" for the entry at the given index
            if (updatedChildData[index]) {
                updatedChildData[index].status = "success";
                pending_child_id = updatedChildData[index].child_id
            }
        
            // Filter out any entries with status "pending" and count how many are removed
            const filteredChildData = updatedChildData.filter(child => child.status !== "pending");
            const removedCount = updatedChildData.length - filteredChildData.length;
        
            // Decrease the index by the number of removed records, ensuring it doesn't go below zero
            const newIndex = Math.max(index - removedCount, 0);
        
            // Update the state with the filtered child_data and new index, and log the result
            this.setState({ child_data: filteredChildData, index: newIndex, newChildData: null }, ()=>{
                //Code to check child age
                let child_data = this.state.child_data;
                let child_verified = true;
                let same_date = 0;
                
                child_data = Array.isArray(this.state.child_data) ? this.state.child_data : JSON.parse(this.state.child_data)
                    if (child_data && child_data.length > 0) {
                        
                        child_data.forEach((element, index) => {
                            if (element.relation_with_child == "parent") {
                                if (element.child_birthdate) {
                                    if (element.child_birthdate != child_data[this.state.index].child_birthdate) {
                                        if (element.child_birthdate && element.child_birthdate.length <= 9) {
                                            let childs = element.child_birthdate.split('-');
                                            if (childs[2] && childs[2].length == 1) childs[2] = '0' + childs[2]
                                            if (childs[1] && childs[1].length == 1) childs[1] = '0' + childs[1]
                                            element.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
                                            element.birthdate_timestamp = new Date(element.child_birthdate).getTime()
                                        }
                                        else {
                                            child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
                                        }
                                        let diffTime = Math.abs(new Date(child_data[this.state.index].child_birthdate) - new Date(element.child_birthdate));
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        if (diffDays < 2 || diffDays > 273) {
                                            if (diffDays < 2) {
                                                same_date = same_date + 1;
                                            }
                                        }
                                        else {
                                            child_verified = false;
                                        }
                                    } else {
                                        same_date = same_date + 1;
                                    }
                                }
                                else {
                                }
                            }           
                        });
                        if (child_verified == true) {
                            if (same_date == 2 || same_date == 3) {
                                self.setState({ show_error_dailog: "You are adding twince.", twins_id: pending_child_id, open_dailog: true })
                            }
                            else if (same_date < 8) {
                                self.handleUpdateChild(child_verified)
                            } else{
                                if (this.state.child_data[this.state.index].relation_with_child !== "parent") {
                                    self.handleUpdateChild(true)
                                } else {
                                    let body = url.dynamic_content.error_message.child_have_same_birthdate.en
                                    // functions.logUserError('user_action', body);
                                    self.setState({ show_error_message: url.dynamic_content.error_message.child_have_same_birthdate.en })
                                }
                            }
                        } else {
                            if (this.state.child_data[this.state.index].relation_with_child !== "parent") {
                                self.handleUpdateChild(true)
                            }
                            else {
                                let pendingChildData = [...this.state.child_data]
                                let pending_index = pendingChildData.findIndex(data=>data.child_id == pending_child_id)
                                console.log("pending_index", pending_index);
                                pendingChildData[pending_index].status = 'pending'
                                let body = url.dynamic_content.error_message.incorrect_child_date.en
                                functions.logUserError('user_action', body);
                                console.log("pendingChildData", pendingChildData);
                                this.setState({ child_data: pendingChildData, show_error_message: "Children must have 9 month diffrence between ages, Please select valid date."})
                            }
                        }
                    }
                })
                //End of Code to check child age
        } else {
            console.log("child_data", this.state.child_data)
            let body = url.dynamic_content.error_message.all_fields_required.en
            functions.logUserError('user_action', body);
            this.setState({ show_error_message: url.dynamic_content.error_message.all_fields_required.en })
        }
        // this.setState({ show_add_model: ''})
        
    }

    handleUpdateTwinsChild = (tw_id) => {
        const updatedChildData = [...this.state.child_data];
        
        // Find the index of the child with the matching tw_id
        const index = updatedChildData.findIndex(child => child.child_id === tw_id);
    
        if (index !== -1) {
            updatedChildData[index].status = "pending";
            this.setState({ child_data: updatedChildData });
            this.setState({open_dailog:false})
        } else {
            console.error("Child with the given ID not found");
        }
    }
    


    handleUpdateChild = (verifiy, i=null) => {
        let child_data = this.state.child_data
        let index = this.state.index;
        if(i) index = i;
        console.log("handleUpdateChild index", index)
        let type = child_data && child_data[index] && child_data[index].type
        let birthdate_timestamp = child_data && child_data[index] && child_data[index].birthdate_timestamp
        if (type == "Expectant") {
            let min = new Date()
            let max = new Date();
            // min.setHours(min.getHours() + 24)
            let temp = max.getDate()
            max.setMonth(max.getMonth() + 9);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            let min_time = new Date(minimum_date).getTime()
            let max_time = new Date(maximum_date).getTime()
            if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
                this.handleUpdateType(this.state.child_data, i)
            } else {
                // this.toggle_confirm()
            }
        } else if (type == "Existing") {
            let max = new Date();
            let min = new Date();
            let temp = max.getDate()
            min.setFullYear(min.getFullYear() - 19);
            let minimum_date = this.formatMinMaxDate(min);
            let maximum_date = this.formatMinMaxDate(max);
            let min_time = new Date(minimum_date).getTime()
            let max_time = new Date(maximum_date).getTime()
            if (birthdate_timestamp <= max_time && birthdate_timestamp >= min_time) {
                this.handleUpdateType(this.state.child_data, i)
            } else {
                // this.toggle_confirm()
            }
        }

    }
    handleUpdateType = (child_data, i) => {
        let self = this;
        this.setState({ child_validate: true })
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        let old_child_data = localStorage.getItem('child_data') ? JSON.parse(localStorage.getItem('child_data')) : "";
        let index = this.state.index;
        let update_rel_child = false;

        if ((old_child_data && old_child_data[index])) {

            if (child_data[index].child_name !== old_child_data[index].child_name ||
                child_data[index].child_gender !== old_child_data[index].child_gender ||
                child_data[index].child_birthdate !== old_child_data[index].child_birthdate ||
                child_data[index].type !== old_child_data[index].type) {
                update_rel_child = child_data[index]
            }

        }

        this.handleUpdate(child_data, update_rel_child, i);
        localStorage.setItem("child_data", JSON.stringify(child_data))
        this.setState({ add_child: false, show_error_message: '' })
    }

    handleUpdate = (child_data, update_rel_child = false, i=null) => {
        let userdata = [];
        let today_timestamp = new Date()
        let index = this.state.index
        if(i && i != null) index = i;
        let add_child_data = child_data[index]
        console.log("add_child_data", add_child_data)
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let modify_child_json = {
                "action": 'migrate_child_data',
                child_id: add_child_data.child_id,
                sub_id: sub_id,
                child_data: add_child_data,
                last_add_child_ppoup_timestamp: today_timestamp.getTime()
            }

            let selectedRelative = this.props.location?.state?.stateData?.selectedRelation

            functions.SyncInMysql(this, modify_child_json);
            let dynamo_userdata = { 'child_data': child_data };
            functions.updateUserDataChildName(this, userdata, dynamo_userdata, false, update_rel_child);
            this.setState({ show_add_model: false, loader: false, child_data: child_data, open_dailog: false });
            localStorage.setItem('child_data', JSON.stringify(child_data));
            this.invokeintially()
        }
    }

    formatDateString = (dateStr) => {
        const date = new Date(dateStr);
        // Format the date as "12 Feb 2025"
        const formattedDate = date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
        return formattedDate
    }

    handleFinal = () => {
        try{
            this.setState({ loader: true })
            const { handleClickedContinue } = this.props;
            try{
                trackEvent('Signup_completed_with_child_data_NextJS', { event_action: 'User added address and child then Clicked submit' });
            }catch(error){
                console.log("Pixel Error while trackEvent  ", error)
            }
            if (handleClickedContinue) {
                handleClickedContinue("add_child_completed")
            }
            setTimeout(()=>{
                this.setState({ loader: false })
            }, 2500)
        }catch(error){
            console.log("handleFinal Cathches Error", error);
            this.setState({ loader: false })
        }
        
    }

    handleDeleteChild=()=>{
        let {editChildIndex, editDeleteChildData, child_data } = this.state

        
        child_data.splice(editChildIndex, 1)
        
        let data = child_data.filter(item => item.child_id === editDeleteChildData.child_id);
        
        let dynamo_userdata = { 'child_data': child_data };
        functions.updateUserDataChildName(this, [], dynamo_userdata, false, false)
        let expectent_child = child_data?.length > 0 && child_data.filter(child => child.type == "Expectant")
        let existing_child = child_data?.length > 0 && child_data.filter(child => child.type == "Existing")
        localStorage.setItem("child_data", JSON.stringify(child_data))
        this.setState({ child_data: child_data, expectent_child: expectent_child,existing_child: existing_child, editModalOpen1:false,  editDeleteChildData: null, editChildIndex: null  })
    
    }

    // this function is used to perform Edit/Delete action for Child_data
    handleActionEditDelete=(child, i)=>{
      
        let {child_data, index}= this.state;
        let filteredPendingChild = child_data?.length && child_data.filter(item=> !item.status || item.status != 'pending');
        child_data = filteredPendingChild
        this.setState({child_data, show_add_model: null});
        // return
        // const filteredChildData = this.state.child_data?.filter(child => child.status !== "pending");
        // const removedCount = this.state.child_data.length - filteredChildData.length;
      
        // // Decrease the index by the number of removed records, ensuring it doesn't go below zero
        // const newIndex = Math.max(index - removedCount, 0);
      
        // // Update the state with the filtered child_data and new index, and log the result
        // this.setState({ child_data: filteredChildData, index: newIndex}) 
        
        if(child.type == "Expectant"){
            this.setState({editExpectionChildIndex: i})
        }else if(child.type == "Existing"){
            this.setState({editExistingChildIndex: i})
        }
        
        // let child_data = this.state.child_data
        
        let index1 = child_data?.length && child_data.findIndex(item => item.child_id === child.child_id);
        this.setState({editDeleteChildData : child, editChildIndex: index1, editModalOpen1:true})
    }

    isMonthDifferenceGreaterThanNine(d1, d2) {
        // Calculate the total month difference
        const yearDifference = d1.getFullYear() - d2.getFullYear();
        const monthDifference = d1.getMonth() - d2.getMonth();
      
        const totalMonthDifference = yearDifference * 12 + monthDifference;
      
        // Check if the month difference is greater than 9
        return Math.abs(totalMonthDifference) > 9;
      }

    processEditChild = (editedChild, index)=>{
        
        let {child_data, existing_child, expectent_child, show_error_message=null} = this.state;
       

        // Create a copy of child_data to avoid mutating the state directly
        const updatedChildData = [...child_data];
        updatedChildData.forEach(child => {
            if (!child.child_name || child.child_name.trim() === '') {
              child.child_name = 'Child '+updatedChildData.length;
            }
          });

      
        // Filter out any entries with status "pending" and count how many are removed
        const filteredChildData = updatedChildData

      
        // Update the state with the filtered child_data and new index, and log the result
        this.setState({ child_data: filteredChildData }, () => {
        let child_verified = true;
        let same_date = 0;
        let self = this;
        // for (const child of child_data) {
        //     if (child.child_id !== editedChild.child_id) {
        //         const valid_date = this.isMonthDifferenceGreaterThanNine(new Date(editedChild.child_birthdate), new Date(child.child_birthdate));
        //         if (!valid_date) {
        //             show_error_message = "Children must have 9 month difference between ages. Please select a valid date.";
        //             break;
        //         }
        //     }
        // }
        // if(show_error_message){
        //     this.setState({show_error_message});
        //     return
        // }
        let newChildIndex = child_data.findIndex(item => item.child_id === editedChild.child_id);
        
        let updatedChild 
        if(editedChild.type == 'Existing'){
            updatedChild = existing_child.filter(item=> item.child_id == editedChild.child_id)
        }else if(editedChild.type == 'Expectant'){
            updatedChild = expectent_child.filter(item=> item.child_id == editedChild.child_id)
        }
        
        child_data[newChildIndex] = updatedChild[0] 
        if (child_data && child_data.length > 0) {
            
            child_data.forEach((element, index) => {
                if (element.relation_with_child == "parent") {
                    if (element.child_birthdate) {
                        if (element.child_birthdate != child_data[newChildIndex].child_birthdate) {
                            if (element.child_birthdate && element.child_birthdate.length <= 9) {
                                let childs = element.child_birthdate.split('-');
                                if (childs[2] && childs[2].length == 1) childs[2] = '0' + childs[2]
                                if (childs[1] && childs[1].length == 1) childs[1] = '0' + childs[1]
                                element.child_birthdate = childs[0] + '-' + childs[1] + '-' + childs[2]
                                element.birthdate_timestamp = new Date(element.child_birthdate).getTime()
                            }
                            else {
                                child_data.birthdate_timestamp = new Date(child_data.child_birthdate).getTime()
                            }
                            let diffTime = Math.abs(new Date(child_data[newChildIndex].child_birthdate) - new Date(element.child_birthdate));
                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            if (diffDays < 2 || diffDays > 273) {
                                if (diffDays < 2) {
                                    same_date = same_date + 1;
                                }
                            }
                            else {
                                child_verified = false;
                            }
                        } else {
                            same_date = same_date + 1;
                        }
                    }
                    else {
                    }
                }           
            });
            if (child_verified == true) {
                if (same_date == 2 || same_date == 3) {
                    self.setState({ show_error_dailog: "You are adding twince.", twins_id: editedChild.child_id, open_dailog: true })
                }
                else if (same_date < 8) {
                    self.handleUpdateChild(child_verified, newChildIndex)
                } else{
                    if (this.state.child_data[newChildIndex].relation_with_child !== "parent") {
                        self.handleUpdateChild(true, newChildIndex)
                    } else {
                        let body = url.dynamic_content.error_message.child_have_same_birthdate.en
                        // functions.logUserError('user_action', body);
                        self.setState({ show_error_message: url.dynamic_content.error_message.child_have_same_birthdate.en })
                    }
                }
            } else {
                if (this.state.child_data[newChildIndex].relation_with_child !== "parent") {
                    self.handleUpdateChild(true, newChildIndex)
                }
                else {
                    let body = url.dynamic_content.error_message.incorrect_child_date.en
                    functions.logUserError('user_action', body);
                    this.setState({ show_error_message: "Children must have 9 month diffrence between ages, Please select valid date."})
                }
            }
        }
        let dynamo_data = {'child_data': child_data}
        functions.updateUserDataChildName(this, [], dynamo_data, false, false)
        localStorage.setItem('child_data', JSON.stringify(child_data))
        expectent_child = child_data?.length > 0 && child_data.filter(child => child.type == "Expectant")
        existing_child = child_data?.length > 0 && child_data.filter(child => child.type == "Existing")

        this.setState({ existing_child: existing_child, expectent_child: expectent_child })
        this.setState({editExpectionChildIndex: -1, editModalChildren : false, child_data: child_data})
        
    });
    }
    
  

    render() {
        const { show_add_model, child_data,editModalOpen1,editModalChildren, open_dailog, index, existing_child, expectent_child,activeButton,editExpectionChildIndex, editExistingChildIndex } = this.state;
        return (
            <div>
                  <div className="open_mail_model">
                    <Modal isOpen={editModalOpen1} toggle={()=>{}} className={" custom_Addchildmodel"}>
                        <ModalBody className="custom_editchildBody">
                            </ModalBody>
                            <div className="editchild_content" style={{ postion: "relative" }}>
                            <img src="/images/closeChild copy.svg" alt="" title="" onClick={() => this.setState({editModalOpen1:false, editDeleteChildData: null, editChildIndex: null})} className="clearcontest_img popup_close_button" />
                                
                                {/* <img src={require('/images/closeChild.svg')} alt="" title="" onClick={() => this.setState({editModalOpen1:false, editDeleteChildData: null, editChildIndex: null})} className="clearcontest_img popup_close_button" /> */}
                                    <Button style={{ marginTop: "20px" }} size="medium" color="primary" onClick={() => { this.setState({editModalChildren:true, editModalOpen1:false})}}>
                                        {"Edit"}
                                    </Button>
                                    <Button size="medium" color="primary" className="deleteButton closeModel childdel_btn 5656" onClick={() => {
                                            this.handleDeleteChild() 
                                            
                                            }} >
                                        {"Delete"}
                                    </Button>
                                </div>

                            
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={open_dailog}>
                            <ModalBody className="custom_editchildBody">
                                <div className="editchild_content" style={{ postion: "relative" }}>
                                <img src="/images/closeChild copy.svg" alt="" title="" onClick={() => this.handleUpdateTwinsChild(this.state.twins_id)} className="clearcontest_img popup_close_button" />
                                    <div className="ntc_head"><h3>Twince !!!</h3>
                                        <div className="ntc_headInr"><p>You are adding a child having same date.</p></div>
                                        <Button style={{ marginTop: "20px" }} size="medium" color="primary" onClick={() => {this.handleUpdateChild(true); this.setState({open_dailog:false})}}>
                                            {"Add Twince"}
                                        </Button>
                                        <Button size="medium" color="primary" className="deleteButton closeModel childdel_btn 5656" onClick={() => this.handleUpdateTwinsChild(this.state.twins_id)}  >
                                            {"Cancel"}
                                        </Button>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                <div className='addCardInfo'>
                    <div className='addCardDetailUpr2'>
                        <div className='addCardDetail2'>
                            <div className='verifyHeadOne'>
                                <div><img onClick={() => this.handleBack()} src="/images/ArowVector.svg" alt="" title="" /></div>
                                <label>Add your children</label>
                                <div><a>4/{this.props.totalStep}</a></div>
                            </div>
                            {expectent_child && expectent_child.length > 0 ?
                                <div className='expChildExpndList'>
                                    <div className='expChildExpLstInr'>
                                        <label>Expecting Child</label>
                                        <a onClick={() => this.handleClickAdd("expecting_child")} >Add <span>+</span></a>
                                    </div>
                                    {expectent_child.map((child, index) => {
                                        if(index == editExpectionChildIndex && editModalChildren){
                                            return(
                                                // <div className='expChildExpndUpr'>
                                                <div className='expChildExpnd' style={{marginTop:10}}>
                                                    <div className='expChildExpndInr'>
                                                        <div>
                                                            <input type='text' placeholder={"Child "+ this.state.child_data?.length ?? ''}  value={child?.child_name}   onChange={(e) => { 
                                                                const updatedExpectentChild = [...expectent_child]; 
                                                                // Update the specific child's child_name at the given index
                                                                updatedExpectentChild[index] = {
                                                                    ...updatedExpectentChild[index],
                                                                    child_name: e.target.value
                                                                };
                                                                // Set the updated array to the state
                                                                this.setState({ expectent_child: updatedExpectentChild });
                                                                //  this.setState({expectent_child:expectent_child?.[index]?.child_name=e.target.value})    
                                                                }
                                                            } />
                                                        </div>
                                                        <div className='expChildBtns expChildBtnsAdd'>
                                                            <input type='button' value='Unknown' onClick={  ()=>{
                                                                const updatedExpectentChild = [...expectent_child]; 

                                                                updatedExpectentChild[index] = {
                                                                ...updatedExpectentChild[index],
                                                                child_gender: 'Unknown'
                                                                };
                                                                
                                                                this.setState({ expectent_child: updatedExpectentChild });

                                                            }} className={!['Boy','Girl']?.includes(child?.child_gender)  ? 'active' : ''} />
                                                            <input type='button' value='Boy' onClick={()=>{
                                                                const updatedExpectentChild = [...expectent_child]; 
                                                                updatedExpectentChild[index] = {
                                                                ...updatedExpectentChild[index],
                                                                child_gender: 'Boy'
                                                                };
                                                                
                                                                this.setState({ expectent_child: updatedExpectentChild });
                                                                }} className={child?.child_gender == 'Boy' ? 'active' : ''} 
                                                            />
                                                            <input type='button' value='Girl' onClick={ ()=>{
                                                                
                                                                const updatedExpectentChild = [...expectent_child]; 

                                                                updatedExpectentChild[index] = {
                                                                ...updatedExpectentChild[index],
                                                                child_gender: 'Girl'
                                                                };
                                                                this.setState({ expectent_child: updatedExpectentChild });
                                                                
                                                            }} className={child?.child_gender == 'Girl' ? 'active' : ''} />
                                                        </div>
                                                    <div>
                                                    {/* <input type='text' placeholder='When is the baby due date to arrive?' /> */}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Fragment className="addChildDate">
                                                            <div className="addChildDateIcon">
                                                                <DatePicker
                                                                    openTo="year"
                                                                    format="dd/MM/yyyy"
                                                                    id="select_date"
                                                                    views={["year", "month", "date"]}
                                                                    placeholder="What is your little one's due date?"
                                                                    minDate={today}
                                                                    maxDate={maxDate}
                                                                    value={child?.child_birthdate?child?.child_birthdate  : null}
                                                                    onChange={(e) => {
                                                                        const updatedExpectentChild = [...expectent_child];
                                                                        let child_date=e
                                                                                let month = child_date.getMonth() + 1
                                                                                let date_month = month < 10 ? "0" + month : month;
                                                                                let date_year = child_date.getFullYear();
                                                                                let date_date = child_date.getDate() < 10 ? "0" + child_date.getDate() : child_date.getDate();
                                                                                child_date = date_year + "-" + date_month + "-" + date_date;
                                                                                updatedExpectentChild[index] = {
                                                                                ...updatedExpectentChild[index],
                                                                                child_birthdate: child_date
                                                                                };
                                                                                this.setState({ expectent_child: updatedExpectentChild, show_error_message: null });                                                                
                                                                    }}
                                                                    margin="normal"
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className='expChildSbmt'>
                                                    <input type='button' value='Cancel' onClick={() => {this.setState({editExpectionChildIndex: -1, editModalChildren:false, show_error_message: null})}} />
                                                    <input type='button' value='Save' className='expChildSbmtActv' onClick={() => this.processEditChild(child, index)} />
                                                </div>
                                                {this.state.show_error_message && this.state.show_error_message != "" &&
                                                    <p className="acErrorMsg">{this.state.show_error_message}</p>
                                                }
                                            </div>
                                        </div>
                                        // </div>
                                            )
                                        }
                                        return(
                                        <div className='adUserInfo' key={index}>
                                            <div className='adUserInfoLft'>
                                                <span>{child && child.child_name && (child.child_name[0] || '') + (child.child_name[1] || '')}</span>
                                                <div><label>{child.child_name}</label><p>{child.type}, {this.formatDateString(child.child_birthdate)}</p></div>
                                            </div>
                                            <div className='adUserInfoRght' onClick={()=>this.handleActionEditDelete(child, index)}><img src="/images/dots.svg" alt="" title="" /></div>
                                        </div>)
                                    }
                                )}

                                </div>
                                :
                                (
                                    show_add_model !== "expecting_child" && (
                                        <div className='adChildRen'>
                                            <div className='adChildExpt'>
                                                <input disabled type='text' placeholder='Expecting Child' />
                                                <a onClick={() => this.handleClickAdd("expecting_child")}>
                                                    Add <span>+</span>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                )}

                            {show_add_model != false && show_add_model == "expecting_child" &&
                                <div className='expChildExpndUpr'>
                                    <div className='expChildExpnd'>
                                        <label>Expecting Child</label>
                                        <div className='expChildExpndInr'>
                                            <div><input type='text' placeholder={"Child "+ this.state.child_data?.length ?? ''}    onChange={(e) => { this.hndleChangeChild(e, "child_name", 'expectant') }} /></div>
                                            <div className='expChildBtns expChildBtnsAdd'>
                                                <input type='button' value='Unknown' onClick={(e) => { this.hndleChangeChild(e, "child_gender") }} className={child_data?.[index]?.child_gender === 'Unknown' ? 'active' : ''} />
                                                <input type='button' value='Boy' onClick={(e) => { this.hndleChangeChild(e, "child_gender") }} className={child_data?.[index]?.child_gender === 'Boy' ? 'active' : ''} />
                                                <input type='button' value='Girl' onClick={(e) => { this.hndleChangeChild(e, "child_gender") }} className={child_data?.[index]?.child_gender === 'Girl' ? 'active' : ''} />
                                            </div>
                                            <div>
                                                {/* <input type='text' placeholder='When is the baby due date to arrive?' /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Fragment className="addChildDate">
                                                        <div className="addChildDateIcon">
                                                            <DatePicker
                                                                openTo="year"
                                                                format="dd/MM/yyyy"
                                                                id="select_date"
                                                                views={["year", "month", "date"]}
                                                                placeholder="What is your little one's due date?"
                                                                minDate={today}
                                                                maxDate={maxDate}
                                                                value={child_data && child_data[index]?.child_birthdate ? this.dateFormat(this.state.child_data[this.state.index].child_birthdate) : null}
                                                                onChange={(e) => { this.handleChildDate(e) }}
                                                                margin="normal"
                                                            />
                                                        </div>
                                                    </Fragment>
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className='expChildSbmt'>
                                                <input type='button' value='Cancel' onClick={() => this.handleClickAdd(false)} />
                                                <input type='button' value='Save' className='expChildSbmtActv' onClick={() => this.handleSaveChild('continue', this.state.index)} />
                                            </div>
                                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                                <p className="acErrorMsg">
                                                    {this.state.show_error_message}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {existing_child && existing_child.length > 0 ?
                                <div className='existChildExpndList'>
                                    <div className='existChildExpLstInr'>
                                        <label>Existing Child</label>
                                        <a onClick={() => this.handleClickAdd("existing_child")} >Add <span>+</span></a>
                                    </div>
                                    {existing_child.map((child,index) => {
                                        if(index == editExistingChildIndex && editModalChildren){
                                            return (
                                                // <div className='expChildExpndUpr'>
                                                <div className='expChildExpnd expChildExpndYelow' style={{marginTop:10}}>
                                                    <div className='expChildExpndInr'>
                                                        <div>
                                                            <input type='text' placeholder={"Child "+ this.state.child_data?.length ?? ''}  value={child?.child_name}   onChange={(e) => { 
                                                                const updatedExistingChild = [...existing_child]; 
                                                                // Update the specific child's child_name at the given index
                                                                updatedExistingChild[index] = {
                                                                    ...updatedExistingChild[index],
                                                                    child_name: e.target.value
                                                                };
                                                                // Set the updated array to the state
                                                                this.setState({ existing_child: updatedExistingChild });
                                                                //  this.setState({expectent_child:expectent_child?.[index]?.child_name=e.target.value})    
                                                                }
                                                            } />
                                                        </div>
                                                        <div className='expChildBtns existChildBtns'>
                                                            <input type='button' value='Boy' onClick={()=>{
                                                                const updatedExistingChild = [...existing_child]; 
                                                                updatedExistingChild[index] = {
                                                                ...updatedExistingChild[index],
                                                                child_gender: 'Boy'
                                                                };
                                                                this.setState({ existing_child: updatedExistingChild });
                                                                }} className={child?.child_gender == 'Boy' ? 'active' : ''} 
                                                            />
                                                            <input type='button' value='Girl' onClick={ ()=>{
                                                                
                                                                const updatedExistingChild = [...existing_child]; 

                                                                updatedExistingChild[index] = {
                                                                ...updatedExistingChild[index],
                                                                child_gender: 'Girl'
                                                                };
                                                                this.setState({ existing_child: updatedExistingChild });
                                                                
                                                            }} className={child?.child_gender == 'Girl' ? 'active' : ''} />
                                                        </div>
                                                    <div>
                                                    {/* <input type='text' placeholder='When is the baby due date to arrive?' /> */}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Fragment className="addChildDate">
                                                            <div className="addChildDateIcon">
                                                                <DatePicker
                                                                    openTo="year"
                                                                    format="dd/MM/yyyy"
                                                                    id="select_date"
                                                                    views={["year", "month", "date"]}
                                                                    placeholder="What is your little one's due date?"
                                                                    maxDate={new Date()} // Restricts selection to today or earlier
                                                                    disableFuture // Another way to disable future dates
                                                                    value={child?.child_birthdate?child?.child_birthdate  : null}
                                                                    onChange={(e) => {
                                                                        const updatedExistingChild = [...existing_child];
                                                                        let child_date = e
                                                                        let month = child_date.getMonth() + 1
                                                                        let date_month = month < 10 ? "0" + month : month;
                                                                        let date_year = child_date.getFullYear();
                                                                        let date_date = child_date.getDate() < 10 ? "0" + child_date.getDate() : child_date.getDate();
                                                                        child_date = date_year + "-" + date_month + "-" + date_date;
                                                                        updatedExistingChild[index] = {
                                                                        ...updatedExistingChild[index],
                                                                        child_birthdate: child_date
                                                                        };
                                                                        this.setState({ existing_child: updatedExistingChild, show_error_message: null });                                                                
                                                                    }}
                                                                    margin="normal"
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className='expChildSbmt'>
                                                    <input type='button' value='Cancel' onClick={() =>{ this.setState({editExpectionChildIndex: -1, editModalChildren:false, show_error_message: null})} }  />
                                                    <input type='button' value='Save' className='expChildSbmtActv' onClick={() => this.processEditChild(child, index)} />
                                                </div>
                                                {this.state.show_error_message && this.state.show_error_message != "" &&
                                                    <p className="acErrorMsg">{this.state.show_error_message}</p>
                                                }
                                            </div>
                                        </div>
                                        // </div>
                                            )
                                        }
                                        return(
                                            <div className='adUserInfo' key={index}>
                                                <div className='adexistUserInfoLft'>
                                                    <span>{child && child.child_name && (child.child_name[0] || '') + (child.child_name[1] || '')}</span>
                                                    <div><label>{child.child_name}</label><p>{child.type}, {this.formatDateString(child.child_birthdate)}</p></div>
                                                </div>
                                                <div className='adUserInfoRght' onClick={()=>this.handleActionEditDelete(child, index)}><img src="/images/dots.svg" alt="" title="" /></div>
                                            </div>
                                        )})}
                                </div>
                                :
                                (
                                    show_add_model !== "existing_child" && (
                                        <div className='adChildRen'>
                                        <div className='adChildExist'>
                                            <input type='text' disabled={true} placeholder='Existing Child' />
                                            <a onClick={() => this.handleClickAdd("existing_child")}>Add <span>+</span></a>
                                        </div>
                                    </div>

                                    )
                                )
                            }
                            

                            {show_add_model != false && show_add_model == "existing_child" &&
                                <div className='expChildExpndUpr expChildExpndYelow'>
                                    <div className='expChildExpnd'>
                                        <label>Existing Child</label>
                                        <div className='expChildExpndInr'>
                                            <div><input type='text' placeholder={"Child "+ this.state.child_data?.length ?? ''} onChange={(e) => this.hndleChangeChild(e, "child_name")} /></div>
                                            <div className='expChildBtns existChildBtns'>
                                                <input type='button' value='Boy' onClick={(e) => { this.hndleChangeChild(e, "child_gender") }} className={child_data?.[index]?.child_gender === 'Boy' ? 'active' : ''} />
                                                <input type='button' value='Girl' onClick={(e) => { this.hndleChangeChild(e, "child_gender") }} className={child_data?.[index]?.child_gender === 'Girl' ? 'active' : ''} />
                                            </div>
                                            {/* <div><input type='text' placeholder='When is the baby due date to arrive?' /></div> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <Fragment className="addChildDate">
                                                    <div className="addChildDateIcon">
                                                        <DatePicker
                                                            openTo="year"
                                                            format="dd/MM/yyyy"
                                                            id="select_date"
                                                            views={["year", "month", "date"]}
                                                            placeholder="What is your little one's due date?"
                                                            maxDate={new Date()} // Restricts selection to today or earlier
                                                            disableFuture // Another way to disable future dates
                                                            //  maxDate={new Date(this.state.maximum_date)}
                                                            value={child_data && child_data[index]?.child_birthdate ? this.dateFormat(this.state.child_data[this.state.index].child_birthdate) : null}
                                                            onChange={(e) => { this.handleChildDate(e) }}
                                                            margin="normal"
                                                        />
                                                    </div>
                                                </Fragment>
                                            </MuiPickersUtilsProvider>
                                            <div className='expChildSbmt'>
                                                <input type='button' value='Cancel' onClick={() => this.handleClickAdd(false)} />
                                                <input type='button' value='Save' className='expChildSbmtActv' onClick={() => this.handleSaveChild('continue', this.state.index)} />
                                            </div>
                                            <h3>{this.state.show_error_message}</h3>

                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.loader || this.props.loader) ?
                                <div className="loaderCenter"><center><LottieLoader /></center></div>
                                :
                               (expectent_child.length > 0 || existing_child.length > 0) ?
                               <div className='checkOtp cursor_pointer' onClick={() => this.handleFinal()}><a>Continue</a></div>
                                    :<div className='checkOtp checkOtpDisable'><a>Continue</a></div> 
                            }
                            <div className='addCardDetailTD addCardDetailTD2'>
                                <p>We collect your first name, last name and email address as the most basic account
                                    identifiers and default means for you to access your account, participate in offers
                                    and for us to contact you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}