import React, { useState, useEffect } from "react";
import MaskedInput from 'react-text-mask';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import functions from "../../src/assets/functions/common_functions";
import LottieLoader from '../../LottieLoader/index';
import cookie from 'react-cookies';
import TermsConditions from '../NewLoginProcess/TermsConditions'
export default class Entercontest extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: "+1",
            phone_number: localStorage.getItem("phone_number") ? localStorage.getItem("phone_number") : "",
            show_error_message: '',
            loader: false,
            error_message: null,
            messages: [],
            socket: null,
            otp_sent: false,
            timeLeft : 0,
            show_resend: false,
            otp_verified: false,
            show_t_and_c: false
        }
    }


    componentDidMount() {
        // this.startTimer();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.timeLeft <= 0 && prevState.timeLeft > 0) {
          this.setState({ showResend: true });
          this.clearTimer();
        }
        if (prevProps.otp_verified !== this.props.otp_verified) {
            this.setState({verification_loader: false, verification_error_message: null});

          }
      }
      componentWillUnmount() {
        this.clearTimer();
      }

      componentDidUpdate(prevProps, prevState) {
        // Check if phone_number has changed
        if (prevState.otp_sent !== this.state.otp_sent) {
          if (this.state.socket) {
            this.state.socket.close(); // Close the previous WebSocket connection before opening a new one
          }
          this.openWebSocket(); // Open a new WebSocket connection with the updated phone number
        }
      }

    formatPhoneNumber(phone, countryCode) {
        // Remove all non-digit characters
        const cleaned = phone.replace(/\D/g, '');
        // Prepend the country code
        return `+${countryCode}${cleaned}`;
    }



      handleClick = () => {
        let phoneNumber = this.formatPhoneNumber(this.state.phone_number, "1");

        // const phoneNumber = "+1234567890";  // Your phone number for verification
        const defaultMessage = "YES";       // The default message to send
        // Check if the device is mobile
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (isMobile) {
            // Mobile device: Open the default SMS app
            const smsHref = `sms:${phoneNumber}?&body=${encodeURIComponent(defaultMessage)}`;
            window.location.href = smsHref;
        } else {
            // Desktop device: Show tooltip or alert
            alert(`Please send a message with "${defaultMessage}" to ${phoneNumber} to get verified.`);
        }
    };
      openWebSocket = () => {
        const { phone_number } = this.state; // Get phone number from state
        const socketUrl = 'wss://8ypsjdkjnd.execute-api.us-east-1.amazonaws.com/production';
        const socket = new WebSocket(`${socketUrl}?phone_number=${encodeURIComponent(phone_number)}`);
    
        socket.onopen = () => {
          console.log('WebSocket connection established');
        };
    
        socket.onmessage = (event) => {
          const incomingMessage = JSON.parse(event.data);
          this.setState((prevState) => ({
            messages: [...prevState.messages, incomingMessage]
          }));
          console.log('Received message:', incomingMessage);
    
          // Show specific alerts based on incoming messages
          if (incomingMessage.message === 'Connected') {
            alert('WebSocket connected successfully!');
          }
          if (incomingMessage.message === 'Your phone number has been verified.') {
            console.log("nhgf")
            this.setState({verification_loader: true})
            // alert(incomingMessage.message);
            setTimeout(() => {
                this.handlePhoneVerificationCode_SMS();
            }, 2000);
          }
        };
     // // Handle WebSocket closure and immediate reconnection
            // socket.onclose = (event) => {
            //     console.log('WebSocket closed. Reconnecting immediately...');
            //     openWebSocket();  // Reconnect immediately without waiting
            // };
    
            // // Handle WebSocket errors
            // socket.onerror = (error) => {
            //     console.log('WebSocket Error: ', error);
            //     socket.close();  // Close the socket and trigger reconnection
            // };
    
            // Store the socket in the component's state
        // Store the WebSocket instance in state
        this.setState({ socket });
      };
      startTimer = () => {
        this.clearTimer(); // Ensure no duplicate timers
        this.timerId = setInterval(() => {
          this.setState((prevState) => ({
            timeLeft: prevState.timeLeft - 1,
          }));
        }, 1000);
      };
      clearTimer = () => {
        if (this.timerId) {
          clearInterval(this.timerId);
          this.timerId = null;
        }
      };
      // Function to clear the phone number
  handleClearPhoneNumber = () => {
    this.setState({ phone_number: '', show_error_message: '', loader : false });
  };



    // Handle the continue button press (Enter key)
    handleContinue = (event) => {
        // Implement your logic for continue
        try{
            let self = this
            this.setState({ loader: true })
            if(event == 'resend') this.setState({ verification_loader: true, entered_otp: "" });
            let phone_number = functions.formatPhoneNumber(this.state.phone_number);
            if(!phone_number){
                this.setState({error_message: 'Provided Phone Number is Invalid',loader: false})
                setTimeout(()=>{
                    this.setState({error_message: false})
                }, 3000);
                return
            }
            let country_code = this.state.country_code || "+1" 
            let sub_id = localStorage.getItem('auto_id')
            let area_code = phone_number.substring(1, 4).toString();
            let mode = "msg";
            let program_id = localStorage.getItem('entered_id')
            
            let action = "send_code";
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, program_id, function (response_json) {
                let response = JSON.parse(response_json)
                console.log("sendVerifyCode response_json", response);
                if(response?.data?.result == 'failed' || response?.catch_error == 1 || response?.data?.failed == 1){
                    self.setState({ loader: false, error_message: response?.data?.message || 'Something went wrong' })
                    setTimeout(()=>{
                        self.setState({ loader: false, verification_loader : false,  error_message: null })
                    }, 2000)
                }else{
                    // localStorage.setItem('phone_number', phone_number)
                    self.setState({ error_message: "Verification code Sent successfully", timeLeft: 80 })
                    self.startTimer();
                    setTimeout(()=>{
                        self.setState({ loader: false, verification_loader: false, otp_sent: true, error_message: null})
                    }, 2000)
                }
            })
        }catch(error){
            console.log("Handle continue cathes a error", error)
        }
        
    };
          // Format the time as mm:ss
    formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    formatPhoneNumber1(phoneNumberString) {
        this.setState({ show_error_message: "" })
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    handleCall = () => {
        let success_action = "User clicked on call button enterphone page"
        let image = 'call_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        // self.setState({ call_counter: 32 })
        self.setState({ show_error_message: "" })
        // localStorage.setItem("call_counter", 32)
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber1(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let user_action = "User got error on innerpages/enterphone file handleCall function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            this.setState({ show_error_message: "" })
            let area_code = phone_number.substring(1, 4).toString();
            let mode = "call";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, '', function (response_json) {
                let response = JSON.parse(response_json);
                if (response.error == 1) {
                    self.setState({ loader: false })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    let success_action = "User got success response on sendVerifyCode enterphone page"
                    let image = 'success_sendverifycode_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                    // self.setState({ call_counter: 32 })
                    // localStorage.setItem("call_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User' phone number is a existing record enterphone page"
                    let image = 'phone_number_already_exist_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    self.handlePhoneVerificationCode_SMS()

                }
            });
        }
    }
    handlePhoneVerificationCode_SMS = ()=>{
        try{
            let self = this;
            let {entered_otp, phone_number} = this.state;
            self.setState({verification_loader: true})
            if(!phone_number) phone_number = localStorage.getItem('phone_number')
            phone_number = functions.formatPhoneNumber(phone_number);
            let area_code = phone_number.substring(1, 4).toString();
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.country_code || "1" ;
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
            if(phone_number){
                
                        self.setState({ verification_error_message: 'Verification Successfully Completed', otp_verified: true });
                        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                        const fb_entered_pid = localStorage.getItem('fb_entered_pid');
                        localStorage.setItem('phone_number', phone_number)
                        self.props.handleClickedContinue('mobile_verification_completed')
                        self.props.handleAutoInvokeProgram(fb_entered_pid, program);
                        self.setState({verification_error_message: null});
                        // if(fb_entered_pid){
                        //     let program_list = localStorage.getItem('program_list') ? JSON.parse(localStorage.getItem('program_list')) : []
                        //     program = program_list?.length && program_list.filter(data=> data.program_id == fb_entered_pid);
                        //     setTimeout(()=>{
                        //         localStorage.removeItem('fb_entered_pid')
                        //     }, 3000)
                        // }
                        // let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program} = program
                        // let point_type = 'entry';
                        // let is_direct = 1;
                        // let consent = 'yes'
                        // if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' ){
                        //     functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function (response){
                        //         console.log("Consent ENtry response", response)
                        //     })
                        // }else if (!is_lock_program || is_lock_program == 0){
                        //     console.log("auto_invoke_program id set into local", program_id)
                        //     localStorage.setItem('auto_invoke_program', program_id)
                        // }
                        
                        // setTimeout(()=>{
                        //     self.setState({verification_loader: false, verification_error_message: null});

                        // }, 2000)
                             
            
            } else{
                self.setState({ verification_loader: false, verification_error_message: 'Invalid Phone Number' });
                setTimeout(()=>{
                    self.setState({verification_error_message: null})
                }, 3000)
            }

        }catch(error){
            this.setState({ verification_loader: false})
            console.log("handlePhoneVerificationCode cathes a error", error)
        }
    }
    handlePhoneVerificationCode = ()=>{
        try{
            let self = this;
            let {entered_otp, phone_number} = this.state;
            if (!entered_otp) {
                self.setState({ error_message: "Enter Verification Code first", timeLeft: 80 });
                setTimeout(()=>{
                    self.setState({ error_message: ""});
                }, 2200)
                return;
            }
            self.setState({verification_loader: true})
            if(!phone_number) phone_number = localStorage.getItem('phone_number')
            phone_number = functions.formatPhoneNumber(phone_number);
            let area_code = phone_number.substring(1, 4).toString();
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.country_code || "1" ;
            let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
            if(phone_number){
                functions.checkVerifyCode({'program': program}, "verify_code", phone_number, sub_id, country_code, area_code, entered_otp, function (response_json) {
                    let response = JSON.parse(response_json);
                    if (response.error == 1 || (response.data && (response.data.result == "Not Match" ))) {
                        self.setState({ verification_loader: false, verification_error_message: response.data.result=='Not Match' ? 'Entered Verification Code is invalid' : response?.data?.message || 'Something went wrong' })
                        setTimeout(()=>{
                            self.setState({verification_error_message: null})
                        }, 3000)
                    }else if(response && response.data && response.data.result == "Match" ){
                        self.setState({ verification_error_message: 'Verification Successfully Completed', otp_verified: true });
                        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                        const fb_entered_pid = localStorage.getItem('fb_entered_pid');
                        localStorage.setItem('phone_number', phone_number)
                        self.props.handleClickedContinue('mobile_verification_completed')
                        // self.props.handleAutoInvokeProgram(fb_entered_pid, program);
                        // if(fb_entered_pid){
                        //     let program_list = localStorage.getItem('program_list') ? JSON.parse(localStorage.getItem('program_list')) : []
                        //     program = program_list?.length && program_list.filter(data=> data.program_id == fb_entered_pid);
                        //     setTimeout(()=>{
                        //         localStorage.removeItem('fb_entered_pid')
                        //     }, 3000)
                        // }
                        // let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program} = program
                        // let point_type = 'entry';
                        // let is_direct = 1;
                        // let consent = 'yes'
                        // if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' ){
                        //     functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function (response){
                        //         console.log("Consent ENtry response", response)
                        //     })
                        // }else if (!is_lock_program || is_lock_program == 0){
                        //     console.log("auto_invoke_program id set into local", program_id)
                        //     localStorage.setItem('auto_invoke_program', program_id)
                        // }
                        
                        // setTimeout(()=>{
                        //     self.setState({verification_loader: false, verification_error_message: null});

                        // }, 2000)
                    }else{
                        self.setState({ verification_loader: false, verification_error_message: 'Something went wrong' })
                        setTimeout(()=>{
                            self.setState({verification_error_message: null})
                        }, 3000)
                    }            
                })
            } else{
                self.setState({ verification_loader: false, verification_error_message: 'Invalid Phone Number' });
                setTimeout(()=>{
                    self.setState({verification_error_message: null})
                }, 3000)
            }

        }catch(error){
            this.setState({ verification_loader: false})
            console.log("handlePhoneVerificationCode cathes a error", error)
        }
    }

    handleCloseTerms =(ev)=>{
        if(ev == 'open'){
          this.setState({show_t_and_c: true})
        }else
            this.setState({show_t_and_c: false})
    }
    handleSkipVerification=()=>{
        let {phone_number} = this.state;
        let self = this;
        self.setState({verification_loader: true});
        let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : {}
        localStorage.setItem('phone_number', phone_number)
        localStorage.setItem('phone_verified', 'false');
        localStorage.setItem('phoneVerificationSkipped', 'true');
        localStorage.setItem('phoneVerificationSkippedProgram', program.program_id); 
        let point_type = 'entry';
        let is_direct = 1;
        let consent = 'yes'
        let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
        sub_id = sub_id ? sub_id : ""
        let {program_id, partner_id, registration_source, program_type, term_id, is_lock_program} = program
        if((!is_lock_program || is_lock_program == 0) && program.program_type !== 'survey' && partner_id !== '2' ){
            functions.contestEntry(self, 'yes', sub_id, tracking_id, program_id, partner_id, registration_source || '', point_type, is_direct, '', '', program_type, term_id||'', consent, '', '', function (response){
                console.log("Consent ENtry response", response)
            })
        }
                            
        self.props.handleClickedContinue('mobile_verification_completed')
    }
    render() {
        let { timeLeft, showResend, phone_number, verification_loader, show_t_and_c, entered_otp  } = this.state;
        if(!phone_number) phone_number = localStorage.getItem('phone_number')
        return (
            <div class="smsCardInfo">
                <div class="smsCardDetailUpr">
                    {!this.state.otp_sent  ? <div class="smsCardDetail">
                        <div className='verifyHead verifyHeadOne cursor_pointer mobVerifySection'>
                            <img onClick={()=>this.props.setStep(5)} src="/images/ArowVector.svg" alt="" title="" />
                            <label>Verification</label>
                            <div><a>5/{this.props.totalStep || 6}</a></div>
                        </div>
                        <div className="entrMobDgt"><label>Enter a mobile number</label></div>
                        <div className="mobVerfyCstmUpr">
                            <div className="mobVerfyCstm">
                                <FormControl className="phone_form_class">
                                    <Select
                                        className="mobVerfySelect"
                                        value={this.state.code}
                                        onChange={(e) => { this.setState({ country_code: e.target.value, show_error_message: "" }) }}>
                                        <MenuItem value={"+1"}>+1</MenuItem>
                                        {/* <MenuItem value={"+91"}>+91</MenuItem> */}
                                    </Select>
                                </FormControl>
                                <div className="mobVerfyMask">
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        type='tel'
                                        inputMode="numeric"
                                        //placeholder="55-5342-8400"
                                        value={this.state.phone_number}
                                        onChange={(e) => { this.setState({ phone_number: e.target.value, show_error_message: '' }) }}
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                this.handleContinue()
                                            }
                                        }}
                                        autoFocus={true}
                                    />
                                    {this.state.phone_number && (
                                    <img
                                        src="/images/closeChild.svg"
                                        alt="Close"
                                        title="Clear phone number"
                                        onClick={this.handleClearPhoneNumber} 
                                    />
                                    )}
                                </div>
                            </div>
                            {this.state.error_message && <span>{this.state.error_message}</span>}
                        </div>
                        <div className='mobCardDetail'>
                            <p>You’ll receive a text message with a verification code</p>
                        </div>
                       {!this.state.loader ? <div className='mobWithUpr'>
                            <a onClick={()=>this.handleContinue()}><span>Continue</span></a>
                        </div> : 
                        <div className="loaderCenter"><center><LottieLoader /></center></div>
                        }
                    </div>
                    :
                    <div class="smsCardDetail">
                        <div className='verifyHead cursor_pointer verifyHeadOne justify_content_end'>
                            <img className="cursor_pointer" onClick={()=>this.setState({otp_sent: null})} src="/images/ArowVector.svg" alt="" title="" />
                            <label>{phone_number}</label>
                            <div><a>6/6</a></div>
                        </div>
                        <div className="entrMobDgt"><label>Enter OTP sent to you</label></div>
                        <div className="mobVerfyCstmUpr">
                            <div className="verifyOtp">
                                <label>Verification code</label>
                                <input type='tel'
                                        inputMode="numeric"
                                        autoComplete="one-time-code" 
                                        max={6} maxLength={6} // Restrict input length to 6 characters
                                        onChange={(e) => {
                                            const value = e.target.value.slice(0, 6); // Ensure maxLength restriction
                                            this.setState({ entered_otp: value });
                                        }}
                                    onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                this.handlePhoneVerificationCode()
                                            }
                                    }} 
                                />
                            </div>
                            {this.state.error_message && <span>{this.state.error_message}</span>}
                        </div>
                        {verification_loader ? <div className="loaderCenter"><center><LottieLoader /></center></div> :
                        <>
                            <div className='otpMsgSend'>
                                <p>Not getting any message or code</p>
                                {timeLeft > 0 ?<label>Re-send in {this.formatTime(timeLeft)}</label> 
                                    : <label className="cursor_pointer resendBtns" onClick={()=>this.handleContinue('resend')}>Re-send Code</label>} 
                            </div>
                                        {/* <div className="resend_active" onClick={() => { this.handleClick()}}><p>Send SMS</p></div> */}
                                        {/* <div onClick={() => { this.handleCall() }}><p style={{ textAlign: "right" }}>Call</p></div> */}

                                        </>

                        }                   
                        {this.state.verification_error_message ?<center><label className="resendBtns">{this.state.verification_error_message}</label></center>
                         :<div className="otpButtons">
                            {/* <button className="otpButonConct"><img src="/images/callicon.svg" alt="" title="" />Connect with Us on Call</button> */}
                            <button disabled={entered_otp && entered_otp.length >= 6 ? false: true} title= {entered_otp && entered_otp.length >= 6 ? '': 'Disabled! Enter Verification Code'} onClick={()=>this.handlePhoneVerificationCode()} className="otpButonSend">Submit</button>
                            {timeLeft <= 0 && 
                                <label className="cursor_pointer skipbtn" onClick={()=>this.handleSkipVerification('skip')}>Skip Verification</label>
                            }
                        </div>}
                        <div className="otpTermCondition">
                            <p>By continuing, I authorize family. one to contact me at the Phone Number I have specified even if I have registered my
                                Phone Number on a Do Not Call List. In the event that I! participate in a family.one hosted Program (giveaway, freebie or other program)
                                with family.one or a specified third party where my Phone Number is required, I authorize family.one to include my Phone Number in
                                registration for the specified Program. I understand that each Program will include their own Privacy Policy, Terms and Conditions
                                available for my review prior to entry explaining how my Phone Number will be used, and that I may refuse to provide my Phone Number
                                for a Program by not entering</p>
                            {/* <p>By entering you are agreeing to the <b className="cursor_pointer term_color_blue"><a onClick={()=>this.handleCloseTerms('open')}>Terms of Service</a></b> below.
                                Value may vary depending on date, location and vehicle chosen. Some vehicle class restrictions apply.
                                No purchase necessary. See Terms and Conditions for more details.</p> */}
                        </div>
                    </div>
                    }
                </div>
                {show_t_and_c &&
                  <TermsConditions Back={this.handleCloseTerms} />
              }
            </div>
        )
    }
}




