import React, { useState } from "react";
import ArowVector from '../../public/images/ArowVector.svg'
// import selectArrow from '../../../assets/images/new_images/selectArrow.svg'
// import country from "../../../assets/countries";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import '../../public/assets/css/login_process.css';
import Router from 'next/router';
import Button from '@material-ui/core/Button';

import address from '../../src/assets/get_address';
import cookie from 'react-cookies';
import functions from '../../src/assets/functions/common_functions'
import url from '../../src/assets/url';
import { trackEvent } from '../../lib/facebookPixel';

const country_options= ["Canada", "USA", "Other"];

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToTopRef = React.createRef();
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id && auto_id != "") {
            
        }
        else {
            Router.push('/');
        }
        this.state = {
            
            isUIReady: false,
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            loader: false,
            last_address: false,
            show_error_message2: "",
            address_autocomplete: [],
            modal: false,
            main_loader: false,
            province: '',
            states: [],
            addressline1: '',
            dynamic_content: "",
            city: '',
            postalcode: '',
            manual_address: localStorage.getItem("manual_address") && localStorage.getItem("manual_address") == "true" ? true : false,
            openedSub: false,
            openedRural: false,
            modal_account: false,
            modalAI: false,
            modalAI_Content: "",
            modalAI_Response: "",
            show_form: false,
            show_address_select: true,
            toggle_element: "House",
            show_province: false,
            search_key: "",
            country_options: ["Canada", "USA", "Other"],
            canada_address_options: ["House", "Sub", "Rural"],
            sub_options: ["Apt", "Unit", "Suite", "Penthouse", "Townhouse", "Condo"],
            sub_options_extra: ["Buzzer code", "Entrance", "Building"],
            rural_options: ["RR", "Site", "Comp"],
            RR: localStorage.getItem("RR") ? localStorage.getItem("RR") : "",
            Site: localStorage.getItem("Site") ? localStorage.getItem("Site") : "",
            Comp: localStorage.getItem("Comp") ? localStorage.getItem("Comp") : "",
            selected_country: "",
            RR_select: "false",
            Site_select: "false",
            Comp_select: "false",
            toggle_street_address: false,
            address_change: false,
            sub_selected: "",
            rural_selected: "",
            countries: [],
            show_additional: false,
            street_address_focus: true,
            general_delivery: "false",
            LCD: "false",
            "Buzzer code_select": localStorage.getItem("Buzzer code") ? localStorage.getItem("Buzzer code") : "false",
            Entrance_select: localStorage.getItem("Entrance_select") ? localStorage.getItem("Entrance_select") : "false",
            Building_select: localStorage.getItem("Building_select") ? localStorage.getItem("Building_select") : "false",
            "PO Box": localStorage.getItem("PO Box") ? localStorage.getItem("PO Box") : "",
            STN: localStorage.getItem("STN") ? localStorage.getItem("STN") : "",
            countryModal: false,
            addressTypeModal: false,
             addressReportData: {
                sub_id: localStorage.getItem("auto_id"),
                initial_address_type: "normal",
                final_address_type: "",
                start_time: new Date(),
                end_time: "",
                type: "old",
                functionaction: "add",
                action: "migrate_address_data",
                method: 'add',
            },
            country: "Canada",
            activeSection: 'house',
            aptUnitValue: localStorage.getItem('aptUnitValue') || '',
            buzzerEntarnceBuilding: '',
            ...props.childState
        };
        this.handleSubmitAddress = this.handleSubmitAddress.bind(this);
        this.handleBack = this.handleBack.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        // Compare current state with previous state to detect changes
        if (prevState !== this.state) {
            this.props.onUpdate(this.state); // Send updated state to parent
        }
    }
    componentDidMount=()=>{
        let dynamic_content = localStorage.getItem("dynamic_content");
        let address = localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')) : null;
        if(address){
            let aptUnitValue = address.hasOwnProperty("Apt") ? "Apt" 
                                : address.hasOwnProperty("Unit") ? "Unit" 
                                    : address.hasOwnProperty("Suit") ? "Suit" 
                                        : address.hasOwnProperty("Penthouse") ? "Penthouse" 
                                            : address.hasOwnProperty("Townhouse") ? "Townhouse" 
                                                : address.hasOwnProperty("Condo") ? "Condo" : '';

            this.setState({aptUnitValue: aptUnitValue});
            if(aptUnitValue == 'Apt'){
                this.setState({'Apt': address[aptUnitValue]})
            } else if(aptUnitValue == 'Unit'){
                this.setState({'Unit':  address[aptUnitValue]})
            }else if(aptUnitValue == 'Suit'){
                this.setState({'Suit': address[aptUnitValue]})
            }else if(aptUnitValue == 'Penthouse'){
                this.setState({'Penthouse':  address[aptUnitValue]})
            }else if(aptUnitValue == 'Townhouse'){
                this.setState({'Townhouse':  address[aptUnitValue]})
            }else if(aptUnitValue == 'Condo'){
                this.setState({'Condo':  address[aptUnitValue]})
            }
        }
        
    }

    handleButtonClick = (section) => {
      this.setState({activeSection: section, toggle_element : section ==='house' ? 'House' : section === 'apt'? "Sub" : "Rural"})
      this.autocomplete_toggle("enter_normal")
    };

    handleContinue=()=>{
        this.setState({ show_error_message2: '' }, () => {
        let temp_address = {};
        let user_action = "User got error on signup/address file handleContinue function";
        // let action = "User clicked on Continue while  address while newlogin process";
        // let image = 'continue_button_manual_address_address_page.png';
        // functions.logUserActivity(action, image);
        let toggle_element = this.state.toggle_element;

        let addressline1 = this.state.addressline1 ? this.state.addressline1.trim() : "";
        let city = this.state.city ? this.state.city.trim() : "";
        let province = this.state.province ? this.state.province.trim() : "";
        let postalcode = this.state.postalcode ? this.state.postalcode.trim() : "";
        let postalcode_length = postalcode && postalcode != null ? postalcode.length : 0;
        let show_additional = this.state.show_additional;
        let toggle_street_address = this.state.toggle_street_address;
        let STN = this.state.STN ? this.state.STN.trim() : "";
        let PO_Box = this.state["PO Box"] ? this.state["PO Box"].trim() : "";
        
        temp_address.addressline1 = addressline1;
        temp_address.city = city;
        temp_address.province = province;
        temp_address.postalcode = postalcode;
        temp_address.postalcode_length = postalcode_length;

        if (this.state.country == "Canada") {
            temp_address.show_additional = show_additional;
            temp_address.toggle_street_address = true;
            temp_address.STN = STN;
            temp_address.PO_Box = PO_Box;
            if (toggle_element == "") {
                let error_body = url.dynamic_content.error_message.select_category.en;
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message2: url.dynamic_content.error_message.select_category.en });
            }
            if (toggle_element == "Rural") {
                let RR = this.state.RRText ? this.state.RRText.trim() : "";
                let Site = this.state.SiteText ? this.state.SiteText.trim() : "";
                let Comp = this.state.CompText ? this.state.CompText.trim() : "";
                let LCD = this.state.LCD ? this.state.LCD : "false";
                let general_delivery = this.state.general_delivery ? this.state.general_delivery : "false";

                temp_address.LCD = LCD;
                temp_address.general_delivery = general_delivery;
                temp_address.rural_selected = this.state.toggle_element;

                if (RR ) {
                    temp_address["RR"] = this.state.RRText ? this.state.RRText.trim() : "";
                }
                if (Site) {
                    temp_address["Site"] = this.state.SiteText ? this.state.SiteText.trim() : "";
                }
                if (Comp) {
                    temp_address["Comp"] = this.state.CompText ? this.state.CompText.trim() : "";
                }
                this.makeAddressJson(temp_address);
            }
            if (toggle_element == "Sub") {
                let {Entrance, Building, Buzzer, aptUnitValue} = this.state ;
                temp_address['sub_selected'] = this.state.toggle_element || "";
                temp_address[aptUnitValue] = this.state[aptUnitValue];
                if (Buzzer) {
                    temp_address["Buzzer code"] = this.state["BuzzerText"] ? this.state["BuzzerText"].trim() : "";
                }
                if (Entrance ) {
                    temp_address["Entrance"] = this.state["EntranceText"] ? this.state["EntranceText"].trim() : "";
                }
                if (Building ) {
                    temp_address["Building"] = this.state["BuildingText"] ? this.state["BuildingText"].trim() : "";
                }

                this.makeAddressJson(temp_address);
            }
            if (toggle_element == "House") {
                this.makeAddressJson(temp_address);
            }
        }else {
            
            this.makeAddressJson(temp_address);
        }
        
        
    })
    } 

    makeAddressJson = (temp_address) => {
        let user_action = "User got error on signup/address file makeAddressJson function";
        
        
        if (this.state.country == "Canada") {
            if (temp_address.toggle_street_address == true) {
                if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                    let error_body = url.dynamic_content.error_message.all_fields_required.en
                    // functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message2: url.dynamic_content.error_message.all_fields_required.en })
                }
                else {
                    if (temp_address.postalcode_length != 7) {
                        let error_body = url.dynamic_content.error_message.incorrect_postal_code.en
                        // functions.logUserError(user_action, error_body);
                        this.setState({ show_error_message2: url.dynamic_content.error_message.incorrect_postal_code.en })
                    }
                    else {
                        this.setState({ show_error_message2: "All good" })
                        
                        this.handleSubmitAddress(temp_address)
                    }
                }
            }
            else {
                if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                    let error_body = url.dynamic_content.error_message.all_fields_required.en
                    functions.logUserError(user_action, error_body);
                    this.setState({ show_error_message2: url.dynamic_content.error_message.all_fields_required.en })
                }
                else {
                    if (temp_address.postalcode_length != 7) {
                        let error_body = url.dynamic_content.error_message.incorrect_postal_code.en
                        functions.logUserError(user_action, error_body);
                        this.setState({ show_error_message2: url.dynamic_content.error_message.incorrect_postal_code.en })
                    }
                    else {
                        this.setState({ show_error_message2: "All good" })
                        
                        this.handleSubmitAddress(temp_address)
                    }
                }
            }
        }
        else if (this.state.country == "USA") {
            if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message2: url.dynamic_content.error_message.all_fields_required.en })
            }
            else {
                this.setState({ show_error_message2: "All good" })
                
                this.handleSubmitAddress(temp_address)
            }
        }
        else if (this.state.country != "" && this.state.country != "USA" && this.state.country != "Canada") {
            if (temp_address.addressline1 == "" || temp_address.city == "" || temp_address.province == "" || temp_address.postalcode == "") {
                let error_body = url.dynamic_content.error_message.all_fields_required.en
                functions.logUserError(user_action, error_body);
                this.setState({ show_error_message2: url.dynamic_content.error_message.all_fields_required.en })
            }
            else {
                this.setState({ show_error_message2: "All good" })
                
                this.handleSubmitAddress(temp_address)
            }
        }
        else {
            this.setState({ show_error_message2: "All good" })
            
            this.handleSubmitAddress(temp_address)
        }
    }
    
    handleSubmitAddress = (temp_address) => {
        delete temp_address.postalcode_length
        let manual_address = true
        if (this.state.toggle_element == "House" && temp_address.PO_Box == "" && temp_address.STN == "" && this.state.manual_address == false) {
            manual_address = false
            
        }
        let address = temp_address
        address.country = this.state.country
        address.toggle_element = this.state.toggle_element
        address.sub_selected = this.state.sub_selected
        
        let userdata = [{ Name: 'address', Value: JSON.stringify(address) }, { Name: 'custom:manual_address', Value: (manual_address).toString() }];
        let dynamo_userdata = { 'address': JSON.stringify(address), 'country': this.state.country, 'postalcode': temp_address.postalcode, 'province': temp_address.province, 'manual_address': manual_address, 'address_updated_by_user': "true", address_update_by_user_date: this.handleformateDate(new Date().toLocaleDateString()), address_update_by_user_time: new Date().toLocaleTimeString() };
        localStorage.setItem("address", JSON.stringify(address))
        
        
        functions.updateUserData(this, userdata, dynamo_userdata);
        try{
            trackEvent('Signup_completed_with_Address_only_NextJS', { event_action: 'User added address then Clicked submit' });
        }catch(error){
            console.log("Pixel Error while trackEvent  ", error)
        }
        const { ClickedContinue } = this.props;

        // Call the parent’s ClickedContinue function passed as a prop
        if (ClickedContinue) {
            ClickedContinue('address_completed');
        }
    }


    handleSelectCountry = (value) => {
        
        this.setState({country: value, countryModal: false})
    }  

    handleformateDate = (date) => {
        var d = new Date(date)
        if (d == "Invalid Date") {
            let temp_date = date.split(" ")
            if (temp_date.length > 1) {
                var month = temp_date[0].split("/")[1],
                    day = temp_date[0].split("/")[0],
                    year = temp_date[0].split("/")[2],
                    hours = temp_date[1].split(":")[0],
                    minutes = temp_date[1].split(":")[1],
                    seconds = temp_date[1].split(":")[2]
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                let final_date = [year, month, day].join('/');
                return final_date
            } else {
                return "0000-00-00 00:00:00"
            }
        } else {
            var month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hours = d.getHours(),
                minutes = d.getMinutes(),
                seconds = d.getSeconds();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            let final_date = [year, month, day].join('/');
            return final_date
        }
    }

    autocomplete_toggle = (value) => {
        if (value != "close") {
            let action = "User clicked on " + value + " on address page"
            let image = value + '_button_address_page.png'
            functions.logUserActivity(action, image)
        }
        if (value == "cancel") {
            this.setState(({
                search_key: ''
            }));
        }
        if (value == "open") {
            this.setState(({
                search_key: this.state.addressline1
            }));
        }
        if (value == "enter_manually") {
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    initial_address_type: "manual"
                }
            }));
            localStorage.setItem("manual_address", "true")
            this.setState(({
                search_key: this.state.addressline1,
                address_autocomplete: [],
                manual_address: true,
                addressline1: "",
                city: "",
                province: "",
                postalcode: "",
                show_error_message2: "",
                address_change: false,
                show_address_select: true,
                modalAI_Response:  "",
                country : "Canada"
            }));
        }
        if (value == "enter_normal") {
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    initial_address_type: "normal"
                }
            }));
            localStorage.setItem("manual_address", "false")
            this.setState(({
                search_key: "",
                address_autocomplete: [],
                manual_address: false,
                addressline1: "",
                city: "",
                province: "",
                postalcode: "",
                show_error_message2: "",
                address_change: false,
                show_address_select: true,
                modalAI_Response:  "",
                country : "Canada"
            }));
        }
    }

    handleAutoComplete = (value) => {
        let { show_address_select } = this.state
        let self = this;
        this.setState({ search_key: value, show_error_message2: '', loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA"
        address.getAddress(this, value, country, function (response_json) {
            if (show_address_select == true) {
                if (response_json && response_json.length > 0 && response_json[0].Error == "1001") {
                    response_json[0].Description = "Enter Key To Search";
                    response_json[0].Text = "";
                    self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
                    if (self.state.manual_address == true) {
                        self.setState({ show_address_select: true, address_change: true })

                    }
                }
                else {

                    self.setState({ loader: false, last_address: false, address_autocomplete: response_json })
                    if (self.state.manual_address == true) {
                        self.setState({ show_address_select: true, address_change: true })

                    }
                }
            }
            
        })
    } 

    handleFocusTextField = () => {
        if (this.state.toggle_element == "Rural") {
            this.Site.focus()
        }
        else if (this.state.toggle_element == "House") {
            this.city.focus()
        }
        else {
            if (this[this.state.toggle_element] && typeof (this[this.state.toggle_element]) === 'object')

            this[this.state.toggle_element].focus()
        }
    }
    handleSetAddressFindByID = (value) => {
        let self = this;
        self.setState({ loader: true })
        let address_part = value.Description.trim().split(',')
        
        self.setState({ loader: false })
        localStorage.setItem("manual_address", "false")
        self.setState({
            manual_address: false,
            search_key: value.Text,
            addressline1: value.Text,
            city: address_part[0],
            province: address_part[1],
            postalcode: address_part[2]
        })
        self.autocomplete_toggle('close')
    }
    handleSetAddress = (value) => {
        
        this.setState({ show_address_select: false })
        if (value.Next == "Retrieve" || value.IsRetrievable == true) {
            this.autocomplete_toggle('close')
            this.setState({ address_autocomplete: [] })
            this.handleSetAddressFindByID(value)
        } else if (value.Next == "Find") {
            if (value.Description.split(',').length == 3) {
                this.setState({ address_autocomplete: [] })
                this.handleSetAddressFindByID(value)
            } else {
                
                this.getFindAddress(value)
            }
        }
        else if (value.IsRetrievable == false) {
            this.getFindAddressById(value)
        }
    }
    handleAddressFormat = (event, state_name) => {

        if (state_name === 'addressline1') {
            if (!event.target.value.match(/^[a-zA-Z0-9-'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
            }
            else {
                this.setState({
                    addressline1: event.target.value
                });
            }
        }
        else if (state_name === 'city') {
            if (!event.target.value.match(/^[a-zA-Z-'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ. ]*$/)) {
            }
            else {
                this.setState({
                    city: event.target.value
                });
            }
        }
        else if (state_name === 'province') {
            if (!event.target.value.match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
            }
            else {
                this.setState({
                    province: event.target.value
                });
            }
        }
        //for postal code
        else if (state_name === 'postalcode' && event?.target?.value?.length < 8) {
            if (this.state.postalcode) {
                if (this.state.postalcode.length < event?.target?.value?.length) {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event?.target?.value?.length === 3 &&

                        this.state.postalcode.length === 2
                    ) {
                        if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: event.target.value.toUpperCase() + " ",
                                show_error: false
                            });
                        }

                    }
                    else if (
                        event?.target?.value?.length === 4 &&

                        this.state.postalcode.length === 3
                    ) {
                        if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                        }
                        else {
                            this.setState({
                                postalcode: this.state.postalcode.toUpperCase() + " " + event.target.value.toUpperCase().slice(-1),
                                show_error: false
                            });
                        }

                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                }
                else {
                    if (event.keyCode === 0) {
                        return false;
                    }
                    else if (
                        event?.target?.value?.length === 3 &&

                        this.state.postalcode.length === 2
                    ) {
                        this.setState({
                            postalcode: event.target.value.toUpperCase() + " ",
                            show_error: false
                        });
                    }

                    else if ((event.target.value).length < 4) {
                        if ((event.target.value).length % 2 === 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }

                    else if ((event.target.value).length > 4) {
                        if ((event.target.value).length % 2 !== 0) {
                            if (!event.target.value.slice(-1).match(/^[0-9]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                        else {
                            if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                            }
                            else {
                                this.setState({
                                    postalcode: event.target.value.toUpperCase(),
                                    show_error: false
                                });
                            }
                        }
                    }
                    else if ((event.target.value).length === 4) {
                        this.setState({
                            postalcode: (event.target.value.toUpperCase()).trim(),
                            show_error: false
                        });
                    }
                }
            } else {
                if (!event.target.value.slice(-1).match(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/)) {
                }
                else {
                    this.setState({
                        postalcode: event.target.value.toUpperCase(),
                        show_error: false
                    });
                }
            }
        }
    }
    getFindAddress = (value) => {
        let self = this;
        this.setState({ show_error_message2: '' })
        self.setState({ loader: true })
        let country = this.state.country == "Canada" ? "CAN" : "USA";
        address.findAddress(this, value.Text, value.Id, country, function (response_json) {
            self.setState({ loader: false })
            if (response_json[0].Error == "1001") {
                response_json[0].Description = "Enter Key To Search";
                response_json[0].Text = "";
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
            else {
                self.setState({ last_address: false, address_autocomplete: response_json })
            }
        })
    }
    // Function to handle manual address either Canada or other countries
    handleManualAddress = async () => {
        try {
            // Update the state with a callback
            this.setState(prevState => ({
                addressReportData: {
                    ...prevState.addressReportData,
                    final_address_type: prevState.addressReportData.initial_address_type,
                    end_time: new Date()
                }
            }), async () => {
                try {
                    // This callback is executed after the state is updated
                    await functions.addressReportData(this.state.addressReportData);
                } catch (error) {
                    console.error('Error in handleSubmit', error);  // Optional: handle error if needed
                } finally {
                    // Place the code that needs to run after handleSubmit here
                    let temp_address = {};
                    let user_action = "User got error on signup/address file handleManualAddress function";
                    let action = "User clicked on Continue while entering manual address on address page";
                    let image = 'continue_button_manual_address_address_page.png';
                    functions.logUserActivity(action, image);
                    

                    let toggle_element = this.state.toggle_element;
                    let addressline1 = this.state.addressline1 ? this.state.addressline1.trim() : "";
                    let city = this.state.city ? this.state.city.trim() : "";
                    let province = this.state.province ? this.state.province.trim() : "";
                    let postalcode = this.state.postalcode ? this.state.postalcode.trim() : "";
                    let postalcode_length = postalcode && postalcode != null ? postalcode.length : 0;
                    let show_additional = this.state.show_additional;
                    let toggle_street_address = this.state.toggle_street_address;
                    let STN = this.state.STN ? this.state.STN.trim() : "";
                    let PO_Box = this.state["PO Box"] ? this.state["PO Box"].trim() : "";
                    
                    temp_address.addressline1 = addressline1;
                    temp_address.city = city;
                    temp_address.province = province;
                    temp_address.postalcode = postalcode;
                    temp_address.postalcode_length = postalcode_length;

                    if (this.state.country == "Canada") {
                        temp_address.show_additional = show_additional;
                        temp_address.toggle_street_address = true;
                        temp_address.STN = STN;
                        temp_address.PO_Box = PO_Box;
                        if (toggle_element == "") {
                            let error_body = url.dynamic_content.error_message.select_category.en;
                            functions.logUserError(user_action, error_body);
                            this.setState({ show_error_message2: url.dynamic_content.error_message.select_category.en });
                        }
                        if (toggle_element == "Rural") {
                            let RR = this.state.RR ? this.state.RR.trim() : "";
                            let Site = this.state.Site ? this.state.Site.trim() : "";
                            let Comp = this.state.Comp ? this.state.Comp.trim() : "";
                            let LCD = this.state.LCD ? this.state.LCD : "false";
                            let general_delivery = this.state.general_delivery ? this.state.general_delivery : "false";

                            temp_address.LCD = LCD;
                            temp_address.general_delivery = general_delivery;
                            temp_address.rural_selected = this.state.rural_selected;

                            let RR_select = this.state.RR_select ? this.state.RR_select : "false";
                            let Site_select = this.state.Site_select ? this.state.Site_select : "false";
                            let Comp_select = this.state.Comp_select ? this.state.Comp_select : "false";

                            if (RR_select == "true") {
                                temp_address["RR"] = this.state["RR"] ? this.state["RR"].trim() : "";
                            }
                            if (Site_select == "true") {
                                temp_address["Site"] = this.state["Site"] ? this.state["Site"].trim() : "";
                            }
                            if (Comp_select == "true") {
                                temp_address["Comp"] = this.state["Comp"] ? this.state["Comp"].trim() : "";
                            }

                            this.makeAddressJson(temp_address);
                        }
                        if (toggle_element == "Sub") {
                            let sub_selected = this.state.sub_selected;
                            let Entrance_select = this.state.Entrance_select ? this.state.Entrance_select : "false";
                            let Building_select = this.state.Building_select ? this.state.Building_select : "false";
                            let Buzzer_code_select = this.state["Buzzer code_select"] ? this.state["Buzzer code_select"] : "false";
                            temp_address[sub_selected] = this.state[sub_selected] ? this.state[sub_selected].trim() : "";
                            if (Buzzer_code_select == "true") {
                                temp_address["Buzzer code"] = this.state["Buzzer code"] ? this.state["Buzzer code"].trim() : "";
                            }
                            if (Entrance_select == "true") {
                                temp_address["Entrance"] = this.state["Entrance"] ? this.state["Entrance"].trim() : "";
                            }
                            if (Building_select == "true") {
                                temp_address["Building"] = this.state["Building"] ? this.state["Building"].trim() : "";
                            }

                            
                            this.makeAddressJson(temp_address);
                        }
                        if (toggle_element == "House") {
                            this.makeAddressJson(temp_address);
                        }
                    } else {
                        
                        this.makeAddressJson(temp_address);
                    }
                }
            });
        } catch (error) {
            console.error('Error in handleSubmit', error);  // Optional: handle error if needed
        }
    };
    handleBack = ()=>{
        const {setStep} = this.props;
        if(setStep) setStep(3);
    }

    handleApt = (event, action)=>{
        console.log("handleApt", event.target, action )
        if(!event.target.value) this.setState({"Buzzer": false, Entrance: false, Building: false, BuzzerText: '', EntranceText: '', BuildingText: ''})
        if(action){
            this.setState({"aptUnitValue": event.target.value})
        }else{

        }
    }

    handleAPTUNIT=(e, state)=>{
        if(state == 'Apt'){
            this.setState({'Apt': e.target.value})
        } else if(state == 'Unit'){
            this.setState({'Unit': e.target.value})
        }else if(state == 'Suit'){
            this.setState({'Suit': e.target.value})
        }else if(state == 'Penthouse'){
            this.setState({'Penthouse': e.target.value})
        }else if(state == 'Townhouse'){
            this.setState({'Townhouse': e.target.value})
        }else if(state == 'Condo'){
            this.setState({'Condo': e.target.value})
        }
    }


    render() {
        const { dynamic_content, activeSection, country, countryModal, manual_address, aptUnitValue, Building, Buzzer, Entrance, RR, Site,Comp, general_delivery } = this.state
        const closePoints = <button className="closeAddressModel" onClick={() => { this.closeToggle(this, 'modal_account') }}>&times;</button>;
        const closeSelection = <button className="closeAddressModel" onClick={() => { this.toggleModal('countryModal') }}>&times;</button>;
        const closeSubSelection = <button className="closeAddressModel" onClick={() => { this.toggleModal('subOptionModal') }}>&times;</button>;
        

        return (
            <div className="addCardInfo">
                <div className="addCardDetailUpr2">
                    <div className="addCardDetail2">
                        <div className="verifyHeadOne">
                            <div><img onClick={()=>this.handleBack()} src="/images/ArowVector.svg" alt="familyone" title="familyone" /></div>
                            <label>Mailing Address</label>
                            <div><a>3/{this.props.totalStep}</a></div> {/* Changed <a> to <span> since it's not a link */}
                        </div>
                        <div>
                            {/* <div className="acCntryAddres">
                                <input type="text" placeholder="Country" />
                                <img src={selectArrow} alt="Select Arrow" title="Select Arrow" />
                            </div> */}
                            <div className="cstmSelCountry" onClick={() => this.setState({countryModal: false})}>
                                <p >{!country ? "Select" : country}</p>
                                    <img src="/images/updownIcon.png" alt="" title="" 
                                            className="cstmSelectArow" />
                            </div>
                        </div>

                        {country!='USA'&&   <div className='acTabSection'>
                    <div className='selectTabType'>
                            <a
                            onClick={() => this.handleButtonClick('house')}
                            className={activeSection === 'house' ? 'active' : ''}>
                            House
                            </a>
                            <a
                            onClick={() => this.handleButtonClick('apt')}
                            className={activeSection === 'apt' ? 'active' : ''}>
                            Apt/Unit
                            </a>
                            <a
                            onClick={() => this.handleButtonClick('rural')}
                            className={activeSection === 'rural' ? 'active' : ''}>
                            Rural
                            </a>
                        </div>
                        <div className='actvTabContent'>
                            {activeSection === 'house' &&
                            <div className='actvContentInfo'>
                                {/* <div className='aprtContentInfo'><input type='text' placeholder='Apartment' /> <img src={selectArrow} alt="" title="" /></div>
                                <div><input type='text' placeholder='City' /></div> */}
                            </div>
                            }
                            {activeSection === 'apt' &&
                            <div className='actvContentInfo'>
                                <div>
                                    <select value={this.state.aptUnitValue} onChange={(e) => this.handleApt(e, 'placeholder')}>
                                    <option value="">Select</option>
                                        <option value="Apt">Apt</option>
                                        <option value="Unit">Unit</option>
                                        <option value="Suit">Suit</option>
                                        <option value="Penthouse">Penthouse</option>
                                        <option value="Townhouse">Townhouse</option>
                                        <option value="Condo">Condo</option>
                                    </select>
                                </div>
                                {aptUnitValue &&
                                    <div className="buzSub">
                                        <div className="buzSubBtn">
                                            <button className={Buzzer ? 'buzSubActv' : ''} onClick={()=>this.setState({Buzzer: !Buzzer})}><span value='Buzzer Code'>Buzzer Code</span></button></div>
                                        <div className="buzSubBtn">
                                            <button className={Entrance ? 'buzSubActv' : ''} onClick={()=>this.setState({Entrance: !Entrance})}> <span value='Entrance'>Entrance</span></button></div>
                                        <div className="buzSubBtn">
                                            <button className={Building ? 'buzSubActv' : ''} onClick={()=>this.setState({Building: !Building})}><span value='Building'>Building</span></button>
                                        </div>
                                    </div>
                                }
                                {aptUnitValue &&
                                <div><input type='text' placeholder={aptUnitValue} onChange={(e)=> this.handleAPTUNIT(e, aptUnitValue)} value={this.state[aptUnitValue]} /></div>
                                }
                                {Buzzer && <div><input type='text' placeholder={'Buzzer'} onChange={(e)=>{this.setState({"BuzzerText": e.target.value})}} value={this.state['BuzzerText'] || ''} /></div>}
                                {Entrance && <div><input type='text' placeholder={'Entrance'} onChange={(e)=>{this.setState({"EntranceText": e.target.value})}} value={this.state['EntranceText'] || ''} /></div>}
                                {Building && <div><input type='text' placeholder={'Building'} onChange={(e)=>{this.setState({"BuildingText": e.target.value})}} value={this.state['BuildingText'] || ''} /></div>}

                                

                            </div>
                            }
                            {activeSection === 'rural' &&
                            <div className='actvContentInfo'>
                                <div className="buzSub">
                                    <div className="buzSubBtn">
                                        <button className={RR ? 'buzSubActv' : ''} onClick={()=>this.setState({RR: !RR})}><span value='RR'>RR</span></button></div>
                                    <div className="buzSubBtn">
                                        <button className={Site ? 'buzSubActv' : ''} onClick={()=>this.setState({Site: !Site})}> <span value='Site'>Site</span></button></div>
                                    <div className="buzSubBtn">
                                        <button className={Comp ? 'buzSubActv' : ''} onClick={()=>this.setState({Comp: !Comp})}><span value='Comp'>Comp</span></button>
                                    </div>
                                </div>
                                {RR && <div><input type='text' placeholder={'RR'} onChange={(e)=>{this.setState({"RRText": e.target.value})}} value={this.state['RRText'] || ''} /></div>}
                                {Site && <div><input type='text' placeholder={'Site'} onChange={(e)=>{this.setState({"SiteText": e.target.value})}} value={this.state['SiteText'] || ''} /></div>}
                                {Comp && <div><input type='text' placeholder={'Comp'} onChange={(e)=>{this.setState({"CompText": e.target.value})}} value={this.state['CompText'] || ''} /></div>}
                                <div className="selRurlBtn" style={{width: '100%'}}>
                                    <button className={general_delivery=='true' ? 'cursor_pointer buzSubActv buzSubActvGD' : 'cursor_pointer'} onClick={()=>{this.setState({general_delivery: general_delivery == 'true'? "false": 'true'})}}>
                                        <span>General Delivery</span>
                                    </button>
                                </div>
                                {/* <div><input type='text' placeholder='Rural' /></div> */}
                            </div>
                            }
                        </div>
                        </div>}

                        {activeSection && <div className="acStreetAddres">
                            <label>Street address</label>
                            <div>
                                <input 
                                    type="text" 
                                    placeholder="Street Address" 
                                    value={this.state.addressline1}
                                    onChange={(event) => {
                                        if (this.state.manual_address == true) {
                                            if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                this.setState({ addressline1: event.target.value, show_error_message2: '', modalAI_Response: "" })
                                            }
                                        } else {
                                            this.setState({ show_address_select: true, address_change: true })
                                            if (event.target.value.match(/^[a-zA-Z-0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'/\\\\,. ]*$/)) {
                                                this.setState({ addressline1: event.target.value, show_error_message2: '' })
                                            }
                                            this.handleAutoComplete(event.target.value)
                                        }
                                    }}
                                    onKeyPress={event => {
                                        if (event.key === "Enter" && this.state.manual_address == true) {
                                            this.handleFocusTextField();
                                        }
                                    }}

                                />
                            </div>
                            {this.state.address_change && this.state.show_address_select &&
                                <div className="address_parent_section">
                                    {this.state.address_autocomplete && this.state.address_autocomplete.length > 0 && this.state.address_autocomplete.map((element, index) => (
                                        <div key={index} className="address_child_section">
                                            {this.state.last_address == true &&
                                                <p onClick={() => { this.handleSetAddress(element) }}>{element.Suggestion}</p>
                                            }
                                            {this.state.last_address == false &&
                                                <p onClick={() => { this.handleSetAddress(element) }}>{element.Text + " " + element.Description}</p>
                                            }
                                        </div>

                                    ))}
                                    {this.state.address_autocomplete && this.state.address_autocomplete.length == 0 &&
                                        <div className="address_child_section">
                                            <p>No matching address found</p>
                                        </div>
                                    }
                                </div>
                            }
                            <div>
                                <input 
                                    type="text" 
                                    autoComplete='off'
                                    hiddenlabel={"City"}
                                    placeholder={"City"}
                                    name={"City"}
                                    id="outlined-read-only-input"
                                    InputProps={{
                                        readOnly: !this.state.manual_address,
                                    }}
                                    inputRef={(input) => { this.city = input; }}
                                    //margin="normal"
                                    value={this.state.city}
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            this.province.focus()
                                        }
                                    }}
                                    onChange={event => {
                                        this.setState({ show_error_message2: '' });
                                        if (this.state.manual_address) {
                                            this.handleAddressFormat(event, "city")
                                        }
                                    }}
                                 />
                            </div>
                            <div>
                            {!this.state.manual_address &&
                                                <input
                                                    autoComplete='off'
                                                    placeholder="Province"
                                                    type="text"
                                                    inputRef={(input) => { this.province = input; }}
                                                    //margin="normal"
                                                    id="outlined-read-only-input"
                                                    InputProps={{
                                                        readOnly: !this.state.manual_address,
                                                    }}
                                                    value={this.state.province}
                                                />
                                            }
                                            {this.state.manual_address &&
                                                <div>
                                                    <input
                                                        autoComplete='off'
                                                        onClick={() => {
                                                            let action = "User open province drop down on address page"
                                                            let image = 'provience_dropdown_address_page.png'
                                                            functions.logUserActivity(action, image);
                                                            this.setState({ modal_account: true, show_province: true })
                                                        }}
                                                        placeholder="Province"
                                                        onChange={(e) => this.setState({ province: e.target.value })}
                                                        value={this.state.province}
                                                        inputRef={(input) => { this.province = input; }}
                                                        type="text"
                                                        onKeyPress={event => {
                                                            if (event.key === "Enter") {
                                                                this.postalcode.focus();
                                                            }
                                                            else {
                                                                let action = "User open province drop down on address page"
                                                                let image = 'provience_dropdown_address_page.png'
                                                                functions.logUserActivity(action, image);
                                                                this.setState({ modal_account: true, show_province: true })
                                                            }
                                                        }}
                                                        // disabled={this.state.manual_address ? false : true}
                                                        autoFocus={false}
                                                    //margin="normal"
                                                    />

                                                    
                                                </div>
                                            }
                                </div>
                            <div>
                                <input 
                                type="text" 
                                placeholder="Postal Code" 
                                name={"Postal code"}
                                id="outlined-read-only-input"
                                InputProps={{
                                    readOnly: !this.state.manual_address,
                                }}
                                inputRef={(input) => { this.postalcode = input; }}
                                value={this.state.postalcode}
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        this.handleManualAddress();
                                    }
                                }}
                                onChange={event => {
                                    this.setState({ show_error_message2: '' });
                                    if (this.state.manual_address) {
                                        this.handleAddressFormat(event, "postalcode")
                                    }
                                }}
                            />
                        </div>
                        {this.state.show_error_message2 && this.state.show_error_message2 != "" &&
                            <p className="error_message">
                                {this.state.show_error_message2}
                            </p>
                        }
                        </div>}
                        {!this.state.manual_address &&

<div className="enter_manully">
    <Button color="secondary" onClick={() => { this.autocomplete_toggle('enter_manually') }}>{dynamic_content?.static_text?.address ? dynamic_content?.static_text?.address?.enter_manually.en : "Enter Manually"}</Button>

</div>
}
                        <div className="checkOtp cursor_pointer">
                            <a onClick={()=>this.handleContinue()}>Continue</a> {/* Changed <a> to <button> */}
                        </div>
                        <div className="addCardDetailTD addCardDetailTD2">
                            <p>We collect your first name, last name, and email address as the most basic account
                                identifiers and default means for you to access your account, participate in offers,
                                and for us to contact you.
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <Modal isOpen={countryModal} className="custom_Addchildmodel" toggle={() => this.setState({countryModal: false})}>
                        <ModalBody className="skilltestBody skilltestAddons">
                            <div className="country_data">

                                <div className="country_ac">
                                    <div className="country_acLft">
                                        <label>Choose Country</label>
                                    </div>
                                    <div className="country_acRght">
                                        <ModalHeader close={()=> this.setState({countryModal: false})}></ModalHeader>
                                    </div>
                                </div>

                                <div className="country_dataList">
                                    {/* <div className="country_active"><a>Canada</a> <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" /></div> */}

                                    {country_options && country_options.length > 0 && country_options.map((element, index) => (
                                        <div key={index} className={country == element ? "country_active" : ""} onClick={() => {this.handleSelectCountry(element)}}>
                                            <a className="other_country">
                                                {element}
                                            </a>
                                            {country == element &&
                                                // <img src={url.family_one_image_url + 'Vector21.png'} alt="" title="" className="cntrySelect" />
                                                <img src="/images/selectedCountry.svg" alt="" title="" className="cntrySelect" />
                                            }
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

