import React, {useState} from "react";

import TermsConditions from './TermsConditions'
import LottieLoader from '../../LottieLoader/index';



const EnterEmail =({InvokeSendOTP, setEmailAddress, email_address, errorMessage, loader, setStep, totalStep})=>{
  const [show_t_and_c, setTermCondition] =useState(false)
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
        InvokeSendOTP();
    }
  };
  const handleCloseTerms =(ev)=>{
    if(ev == 'open'){
      setTermCondition(true)
    }else
      setTermCondition(false)
  }
    return(
        <div className='addCardInfo'>
              <div className='addCardDetailUpr'>
                <div className='addCardDetail'>
                <div className='verifyHeadOne'>
                  <div><img className="cursor_pointer" onClick={() => setStep(0)} src="/images/ArowVector.svg" alt="familyone" title="familyone" /></div>
                  <label></label>
                  <div><a>1/{totalStep}</a></div>
                </div>
                <div className='addCardLogo'>
                    <img src="/images/heartVector.png" alt="" title="" className='heartVector' />
                    <a><img src="/images/familyOneNewLogo.svg" alt="" title="" /></a>
                    <img src="/images/StartUnion.svg" alt="" title="" className='StarUnion' />
                  </div>
                  <div className='emailInput'><input onChange={(e)=>setEmailAddress(e.target.value.toLowerCase())} type='text' placeholder="Email Address" value={email_address}  onKeyDown={handleKeyDown}/></div>
                  {errorMessage && <div className="error_msg">{errorMessage}</div>}
                  <div className='checkWithUpr'>
                    {loader ?
                        <div className="loaderCenter"><center><LottieLoader /></center></div>
                        :
                        <div onClick={()=>InvokeSendOTP()} className='checkWithFB cursor_pointer checkWithG'><a><span>Continue</span></a></div>
                    }
                  </div>
                </div>
                {/* <div className='addCardDetailTD'>
                <p>By accessing family.one, you agree to the
                    <b className="cursor_pointer term_color_blue"><a onClick={()=>handleCloseTerms('open')}>Terms of Service</a></b> and <b className="cursor_pointer"><a href="https://www.iubenda.com/privacy-policy/7780464" target="_blank">Privacy Policy</a></b>, and consent to receiving relevant updates and offers
                    that match your interests. You can manage your preferences in your profile settings.
                  </p>
                </div> */}
              </div>
              {show_t_and_c &&
                  <TermsConditions Back={handleCloseTerms} />
              }
            </div>
    )
}

export default EnterEmail;
