import React from "react";

export default class EnterOtp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: "+1",
            phone_number: localStorage.getItem("phone_number") ? localStorage.getItem("phone_number") : "",
            show_error_message: '',
        }
    }
    // Function to clear the phone number
    handleClearPhoneNumber = () => {
        this.setState({ phone_number: '', show_error_message: '' });
    };

    // Handle the continue button press (Enter key)
    handleContinue = () => {
        // Implement your logic for continue
        console.log('Phone number submitted:', this.state.phone_number);
    };
    render() {
        return (
            <div class="smsCardInfo">
                <div class="smsCardDetailUpr">
                    <div class="smsCardDetail">
                        <div className='verifyHead cursor_pointer'>
                            <img src="/images/ArowVector.svg" alt="" title="" />
                            <label>(226) 455-1645</label>
                        </div>
                        <div className="entrMobDgt"><label>Enter OTP sent to you</label></div>
                        <div className="mobVerfyCstmUpr">
                            <div className="verifyOtp">
                                <label>Verification code</label>
                                <input type="text" />
                            </div>
                        </div>
                        <div className='otpMsgSend'>
                            <p>Not getting any message or code</p>
                            <label className="resendBtns">Re-send Code</label>
                        </div>
                        <div className="otpButtons">
                            <button className="otpButonConct"><img src="/images/callicon.svg" alt="" title="" />Connect with Us on Call</button>
                            <button className="otpButonSend"><img src="/images/msgsms.svg" alt="" title="" />Send us SMS</button>
                        </div>
                        <div className="otpTermCondition">
                            <p>By continuing, I authorize family. one to contact me at the Phone Number I have specified even if I have registered my
                                Phone Number on a Do Not Call List. In the event that I! participate in a family.one hosted Program (giveaway, freebie or other program)
                                with family.one or a specified third party where my Phone Number is required, I authorize family.one to include my Phone Number in
                                registration for the specified Program. I understand that each Program will include their own Privacy Policy, Terms and Conditions
                                available for my review prior to entry explaining how my Phone Number will be used, and that I may refuse to provide my Phone Number
                                for a Program by not entering</p>
                            <p>By entering you are agreeing to the <a href="#">Terms and Conditions</a> below.
                                Value may vary depending on date, location and vehicle chosen. Some vehicle class restrictions apply.
                                No purchase necessary. See Terms and Conditions for more details.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}




