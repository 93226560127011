import React, { useEffect, useState, useRef } from "react";
import LottieLoader from '../../LottieLoader/index';
import cookie from 'react-cookies';
import Router from 'next/router';
import functions from '../../src/assets/functions/common_functions';
import TermsConditions from './TermsConditions'
const VerifyCode = ({ handleSetStep, steps, email_address, handleVerificationCode,error_mess,setError_mess, handleResendOTP}) => {
    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");
    const [timeLeft, setTimeLeft] = useState(80);
    const[showResend, setShowResend] = useState(null)
    const [show_t_and_c, setTermCondition] =useState(false)

    const [loader, setLoader] = useState(true);
    const handleCloseTerms =(ev)=>{
        if(ev == 'open'){
          setTermCondition(true)
        }else
          setTermCondition(false)
      }
    // Create refs for input fields
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoader(false);
        }, 1500);
        return () => clearTimeout(timer);    
    }, []);

    useEffect(() => {
    

        let self = this;
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : "null"

        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : "null";
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.getUserProfile(this, finaljson, function (response_json) {
                let response = JSON.parse(response_json);
              
                if (response.catch_error) {
                    let user_action = "User got error on profile/index file componentWillMount function";
                    let body = response.message
                    functions.logUserError(user_action, body);
                    // self.setState({ loader: false, show_error_message: response.message })
                }
                else if (!response.catch_error) {

                    if (response.data.error === 1) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    } else if (response.data.statusCode === 400) {
                        let user_action = "User got error on profile/index file componentWillMount function";
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        // self.setState({ loader: false, show_error_message: response.data.message })
                    }

                    else if (response.status === 200) {
                       
                            let data = response.data;
                            let cognito_data = data.cognito ? data.cognito.UserAttributes : []
                            let dynamo_data = data.dynamo.Items ? data.dynamo.Items[0] : {}
                            let relation_data = data.relation_data ? data.relation_data : {}
                            let program_data = data.program_data ? data.program_data : []
                            let winner_data = data.winner_data ? data.winner_data : [];
                            let user_status = dynamo_data.email_verified == 'true' || dynamo_data.email_verified == true ? 'Confirmed' : 'Not verified'
                            functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data);

                        
                    }
                }
            })
        } else {
            Router.push('/');
        }
    }, []);

    useEffect(() => {
      if (timeLeft > 0) {
        const timerId = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
  
        // Clear the interval when component unmounts or timeLeft changes
        return () => clearInterval(timerId);
      } else if(timeLeft <= 0){
        console.log("TIme left over")
        setShowResend(true)
      }
    }, [timeLeft]);


    // Focus management
    const handleChange = (index, setCode, value) => {
        setCode(value);
        error_mess&&setError_mess('')

        if (value.length === 1) {
            if (index === 0) input2Ref.current.focus();
            if (index === 1) input3Ref.current.focus();
        }
    };
    const handleKeyDown = (index, setCode, event) => {
        if (event.key === 'Backspace') {
            if (index > 0 && !event.target.value) {
                // Move focus to the previous input if the current input is empty
                if (index === 1) input1Ref.current.focus();
                if (index === 2) input2Ref.current.focus();
            }
            // Clear the current input
            setCode('');
        }
    };

         // Format the time as mm:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };
    const handleLoader=()=>{
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 3000);
    }
    const handlePaste=(e)=>{
        const pasteData = e.clipboardData.getData("Text").slice(0, 3); 
        if (pasteData.length === 3) {
            setCode1(pasteData[0])
            setCode2(pasteData[1])
            setCode3(pasteData[2])

      
        }
    }

    const handleVerifyClick=(action)=>{
        setLoader(true);

        {action === "verify_later" ?  handleVerificationCode(code1 + code2 + code3, email_address, action, function(res){
            setError_mess(res);
            setLoader(false);
            setTimeout(()=>{
                setError_mess(false)
            }, 4500)
        })
        :
        handleVerificationCode(code1 + code2 + code3, email_address, '', function(res){
            setError_mess(res);
            setLoader(false);
            setTimeout(()=>{
                setError_mess(false)
            }, 4500)
        });
    }
        // handleLoader();
    }
    const resendClicked=()=>{
        setCode1("")
        setCode2("")
        setCode3("")
        setLoader(true)
        setCode1("")
        setCode2("")
        setCode3("")
        handleResendOTP('', function(clicked){
            if(clicked == true){
                setShowResend(false)
                setTimeLeft(80);
                setLoader(false)            
            }else{
                setError_mess(clicked);
                setLoader(false)            
            }
        })
    }
    function formatEmail(email, maxLength = 25) {
        
        // Check if the email length exceeds the maximum length
        if (email.length > maxLength) {
          const firstPart = email.slice(0, maxLength); // Show the first 7 characters
          return `${firstPart}...`;
        }
        
        return email; // Return full email if within the limit
      }

    return (
        <div className='addCardInfo'>
            <div className='addCardDetailUpr2'>
                {showResend && <div className='addCardDetail'>
                    <div className='addChkDetail'>
                        <label>Check your Inbox</label>
                        <p>A Magic Link has been sent to: <span>{formatEmail(email_address)}</span> Didn't receive the email? You can re-send it below</p>
                        {error_mess && <div className="error_message error_messageTimr">{error_mess}</div>}
                        {loader ?
                            <div className="loaderCenter"><center><LottieLoader /></center></div>
                            :
                            <div className='checkWithTimer'>
                                <div className='checkWithResend checkWithResendClr '><a className={'cursor_pointer'} onClick={()=>resendClicked()}>Re-send</a></div>
                                <div className='checkWithResendBk checkWithResendBkClr '><a className={'cursor_pointer'} onClick={()=>handleSetStep(steps, "backword")}>Go Back</a></div>
                            </div>
                        }
                    </div>
                </div>}
                {!showResend && <div className='addCardDetail2'>
                    <div onClick={() => handleSetStep(steps, "backword")} className='verifyHead cursor_pointer'>
                        <img src="/images/ArowVector.svg"   alt="" title="" />
                        <label>Verification</label>
                    </div>
                    {/* <div className='editEmail'>
                        <label>Oops! Your email address hasn't been verified.
                            We sent a verification code to:</label>

                        <div className='editEmailInr'>
                            <span>{formatEmail(email_address)}</span>
                            <a>
                                <img onClick={() => handleSetStep(steps, "backword")} src="/images/EmailEdit.svg" alt="" title="" />
                            </a>
                        </div>
                    </div> */}
                     <div className='addChkDetail'>
                        <label>Check your Inbox</label>
                        <p>A Magic Link has been sent to: <span>{formatEmail(email_address)}</span> Didn't receive the email? You can re-send it below</p>
                    </div>
                    <div className='optFields'>
                        <input
                            type='tel'
                            inputMode="numeric" // Ensures numeric keyboard on iOS
                            maxLength="1"
                            value={code1}
                            onChange={(e) => handleChange(0, setCode1, e.target.value.replace(/[^0-9]/g, ''))}
                            ref={input1Ref}
                            onKeyDown={(e) => handleKeyDown(0,setCode1, e)}
                            onPaste={handlePaste}
                            autoFocus 

                        />
                        <input
                            type='tel'
                            inputMode="numeric" // Ensures numeric keyboard on iOS
                            maxLength="1"
                            value={code2}
                            onChange={(e) => handleChange(1, setCode2, e.target.value.replace(/[^0-9]/g, ''))}
                            ref={input2Ref}
                            onKeyDown={(e) => handleKeyDown(1,setCode2, e)}
                            onPaste={handlePaste}


                        />
                        <input
                            type='tel'
                            inputMode="numeric" // Ensures numeric keyboard on iOS
                            maxLength="1"
                            value={code3}
                            onChange={(e) => handleChange(2, setCode3, e.target.value.replace(/[^0-9]/g, ''))}
                            ref={input3Ref}
                            onKeyDown={(e) => handleKeyDown(2,setCode3, e)}
                            onPaste={handlePaste}


                        />
                    </div>
                    <div className='setTimer'><label>{formatTime(timeLeft)}</label></div>
                    {error_mess && <div className="error_message error_messageTimr">{error_mess}</div>}
                    <div className='checkOtp'>
                        {loader ?
                            <div className="loaderCenter"><center><LottieLoader /></center></div>
                            :
                            <>
                            <a className="cursor_pointer"
                                onClick={() => 
                                    handleVerifyClick()
                                }
                            >
                                Submit
                            </a>
                            {/* <a className="cursor_pointer2"  onClick={() => handleVerifyClick("verify_later")} >
                                Verify Later
                            </a> */}

                            </>
                            
                        }
                    </div>
                </div>}
                {/* <div className='addCardDetailTD'>
                    <p>By accessing family.one, you agree to the
                        <b className="cursor_pointer term_color_blue"><a onClick={()=>handleCloseTerms('open')}>Terms of Service</a></b> and <b className="cursor_pointer"><a href="https://www.iubenda.com/privacy-policy/7780464" target="_blank">Privacy Policy</a></b>, and consent to receiving relevant updates and offers
                        that match your interests. You can manage your preferences in your profile settings.
                    </p>
                </div> */}
            </div>
            {show_t_and_c &&
                <TermsConditions Back={handleCloseTerms} />
            }
        </div>
    );
};

export default VerifyCode;
