'use client';
import React, { useEffect, useState } from "react";
import LottieLoader from '../../LottieLoader/index';
import TermsConditions from './TermsConditions'
import {handleFBLogin} from '../../src/utils/FacebookSDK'
import axios from "axios";
import GoogleLogin from "react-google-login";
import { Modal, ModalBody, Label } from 'reactstrap';
import { trackEvent } from '../../lib/facebookPixel';
import functions from '../../src/assets/functions/common_functions';
import { event } from "jquery";
import cookie from 'react-cookies';

function fetchUserInfoFromFBToken(accessToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`https://graph.facebook.com/me?fields=name,email&access_token=${accessToken}`);
      const data = await response.json();
      console.log("data", data);
      if (data && !data.error) {
        console.log(`Name: ${data.name}`);
        console.log(`Email: ${data.email}`);
        resolve(data);
      } else {
        console.error('Error fetching user information:', data.error);
        resolve(null); // Resolve with null for error cases
      }
    } catch (error) {
      console.error("Error fetching user info from FB token:", error);
      reject(error);
    }
  });
}

const getstarted =({handleEmailAddress, handleClick, handleFacebookClick, handleGoogleClick, error_mess, getStartedLoader, showError,setgetStartedLoader, InvokeSendOTP_Session, onUpdate, setEmailAddress})=>{
  const [loader, setLoader] = useState(false);
  const [show_t_and_c, setTermCondition] =useState(false);
  const [show_Enter, setshow_Enter] = useState(false);
  const [isFacebookBrowser, setIsFacebookBrowser] = useState(false);
  const [pageLoader, setPageLaoder] = useState(false);
  const [gglFbSuccessMsg, setGglFbSuccessMsg] = useState(null);
  const [enterBtnText, setEnterBtnText] = useState('Enter');
  const [fbGglErrorMsg, setFbGglErrorMsg] = useState(false)
  const [showIframe, setShowIframe] = useState(false);
 
  const handleClose = () => {
    setShowIframe(false);
  };

  useEffect(() => {
    // Get childState from localStorage
    const storedChildState = JSON.parse(localStorage.getItem("childState")) || {};
  
    // Check if email_address is empty
    if (storedChildState.email_address === "") {
      setEmailAddress(""); // Set email_address to empty in the component state
    }
  }, []);
  useEffect(() => {
    const checkFacebookUser = async () => {
      // Detect Facebook browser
      if (typeof navigator !== "undefined") {
        const userAgent = navigator.userAgent || navigator.userAgentData?.ua || "";
        const fbRegex = /FBAN|FBAV/i; // Detect Facebook in user agent
        setIsFacebookBrowser(fbRegex.test(userAgent));
      }

      // Check for Facebook access token
      const fbAccessToken = localStorage.getItem("fb_access_token");
      if (fbAccessToken) {
        try {
          setshow_Enter(true);
          // setPageLaoder(true)
          // setGglFbSuccessMsg("Authenticating...");
          const userData = await fetchUserInfoFromFBToken(fbAccessToken); // Wait for user data
          console.log("user_data", userData);
          setTimeout(() => {
            localStorage.removeItem("fb_access_token");
          }, 2000);
          if (userData && userData.name) {
            cookie.save("facebook_id", userData.id, { path: "/" });
            // If email exists, mark as verified
            if (userData.email) {
                localStorage.setItem('email_verified', 'true');
            }
        
            // Invoke handleFacebookClick with userData after a delay
            setTimeout(() => {
                handleFacebookClick(userData);
            }, 300);
        }
        
           else {
            setgetStartedLoader(false);
            setFbGglErrorMsg("Authentication Failed")
            setTimeout(()=>{
              setFbGglErrorMsg(false)
              setGglFbSuccessMsg(null);
              setPageLaoder(false);
      
            }, 1000)
            console.log("Facebook access token has expired or is invalid.");
          }
        } catch (error) {
          setgetStartedLoader(false)
          setFbGglErrorMsg("Authentication Failed")
          setTimeout(()=>{
            setFbGglErrorMsg(false)
            localStorage.removeItem("fb_access_token");
            setGglFbSuccessMsg(null);
            setPageLaoder(false);
    
          }, 1000)          
          console.error("Error processing Facebook user data:", error);
        }
      }
    };

    const fetchTokensAndUserInfo = async () => {
      const authorizationCode = localStorage.getItem('google_access_code') || ''
      if (authorizationCode) {
        setshow_Enter(true);
        // setPageLaoder(true);
        // setGglFbSuccessMsg("Authenticating...");
        try {
          const origin = window.location.origin;
          const tokenUrl = 'https://oauth2.googleapis.com/token';
          const clientId= '630950640067-2joesrmd9s9gdvibnslieu9p5831gqlj.apps.googleusercontent.com';
          const clientSecret = 'P-Y6rHLKT5Wid474BCthyxx6';
          const redirectUri = origin; // Your Next.js app URL

          // Exchange authorization code for access and refresh tokens
          const response = await axios.post(tokenUrl, {
            code: authorizationCode,
            client_id: clientId,
            client_secret: clientSecret,
            redirect_uri: redirectUri,
            grant_type: 'authorization_code',
          });

          const { access_token: accessToken, refresh_token: refreshToken } = response.data;
          setTimeout(()=>{
            localStorage.removeItem('google_access_code')
          }, 1500)
          if (accessToken) {
            console.log('Access Token:', accessToken);
            console.log('Refresh Token:', refreshToken);
            
            // Fetch user info with the access token
            await fetchGoogleUserInfo(accessToken);
          } else {
            console.error('Error obtaining access token:', response.data);
            setgetStartedLoader(false);
            setFbGglErrorMsg("Authentication Failed")
            setTimeout(()=>{
              setGglFbSuccessMsg(null);
              setFbGglErrorMsg(false)
              setPageLaoder(false);
            }, 1000)
          }
        } catch (error) {
          console.error('Error exchanging authorization code for tokens:', error);
          setgetStartedLoader(false);
          setFbGglErrorMsg("Authentication Failed")
          setTimeout(()=>{
            setGglFbSuccessMsg(null);
            setFbGglErrorMsg(false)
            setPageLaoder(false);
            localStorage.removeItem('google_access_code')
          }, 1000);
        }
      }
    };

    checkFacebookUser(); // Call the async function
    fetchTokensAndUserInfo();

  }, []);  

  useEffect(()=>{
    let program = localStorage.getItem('program') ? JSON.parse(localStorage.getItem('program')) : null
    if (program && program.program_type == 'freebie' && program.program_id !== '1636'){
      setEnterBtnText("Redeem")
    }
    if(program && program.perk_title && program.perk_title != ''){
      setEnterBtnText("Get Perk")
    }
    async function loadFbSDK(){
      console.log("loadFbSDK Invoked")
      window.fbAsyncInit = function() {
        FB.init({
          appId: '231822734692164',  // Replace with your App ID
          cookie: true,              // Enable cookies to allow the server to access the session
          xfbml: true,               // Parse social plugins on this webpage
          version: 'v19.0'           // Use the latest version of the Facebook Graph API
        });
  
        // Automatically check login state when the page loads
        // checkLoginState();
      };
  
      // Load the SDK asynchronously
      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "https://connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };
    loadFbSDK();
  }, [])

  const fetchGoogleUserInfo = async (accessToken) => {
    try {
      localStorage.removeItem('google_access_code')
      const response = await fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${accessToken}`);
      const data = await response.json();
      if (data && !data.error) {
        localStorage.setItem('email_verified', 'true')
        setTimeout(()=>{
          handleGoogleClick(data);
        }, 300);
      } else {
        console.error('Error fetching user information:', data.error);
        setgetStartedLoader(false);
        setGglFbSuccessMsg("Authentication Failed");
        setTimeout(()=>{
          setGglFbSuccessMsg(null);
          setPageLaoder(false);

        }, 1000)
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setgetStartedLoader(false);
      setGglFbSuccessMsg("Authentication Failed");
      setTimeout(()=>{
        setGglFbSuccessMsg(null);
        setPageLaoder(false);
        localStorage.removeItem('google_access_code')
      }, 1000);
    }
  };

  function checkLoginState() {
    FB.getLoginStatus(function(response) {
      console.log("getLoginStatus", response)
      if (response.status === 'connected') {
        // If the user is already logged in, directly call statusChangeCallback
        statusChangeCallback(response);
      }
    });
  }

  function statusChangeCallback(response) {
    try{
      if (response.status === 'connected') {
        // Fetch user information
        fetchUserInfo(response.authResponse.accessToken);
      } else {
        console.log("'Please log in to access this page.'")
        setgetStartedLoader(false)
      }
    }catch(error){
      console.log("statusChangeCallback catches error", error)
      setgetStartedLoader(false)
    } 
  }

  function fetchUserInfo(accessToken) {
    try{
      // setPageLaoder(true)
      // setGglFbSuccessMsg("Authenticating...");
      FB.api('/me', {fields: 'name,email', access_token: accessToken}, function(response) {
        if (response && !response.error) {
          console.log("fetchUserInfo", response)
          console.log(`Name: ${response.name}`);
          console.log(`Email: ${response.email}`);
          cookie.save("facebook_id", response.id, { path: "/" });
          setTimeout(()=>{
            handleFacebookClick(response)
          }, 300)
        } else {
          setFbGglErrorMsg("Authentication Failed")
          setgetStartedLoader(false)
          // setGglFbSuccessMsg("Authentication Failed");
          setTimeout(()=>{
            setFbGglErrorMsg(false)
            setGglFbSuccessMsg(null);
            setPageLaoder(false);
    
          }, 1000)
          console.error('Error fetching user information:', response.error);
        }
      });
    }catch(error){
      console.log("fetchUserInfo catches error", error);
      setgetStartedLoader(false);
      setFbGglErrorMsg("Authentication Failed")
      setTimeout(()=>{
        setFbGglErrorMsg(false)
        setGglFbSuccessMsg(null);
        setPageLaoder(false);

      }, 1000)    
    }
  }

  const logErrorToServer = async (errorType, errorMessage) => {
    try {
      await fetch('/api/logError', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          errorType,
          errorMessage,
          timestamp: new Date().toISOString(),
        }),
      });
    } catch (serverError) {
      console.error("Error logging to server:", serverError);
    }
  };

  async function loginWithFacebook() {
    try{
      setgetStartedLoader(true);
      if(isFacebookBrowser || !FB) {
        const urlPath = window.location.pathname; 
        const origin = window.location.origin;
        console.log("origin", origin);
        let dynamic_state = urlPath?.substring(1);
        window.location.href = `https://www.facebook.com/v21.0/dialog/oauth?client_id=231822734692164&redirect_uri=${origin}&state=${dynamic_state}&response_type=token&scope=public_profile,email`;
        return;
      }
      // setTimeout(()=>{
      
      FB.login(function(response) {
        if (response.authResponse) {
          statusChangeCallback(response);
        }else{
          setgetStartedLoader(false)
        }
      }, {scope: 'public_profile,email'}); // Use default auth type to avoid asking for password if logged in
      // }, 150)
    } catch(error){
      console.log("loginWithFacebook cathes error", error)
      setgetStartedLoader(false)
    } 
  }

  const handleCloseTerms =(ev)=>{
    if(ev == 'open'){
      setTermCondition(true)
    }else
      setTermCondition(false)
  }

  // const handleGClick = async (redirect)=>{
  //   try{
  //     setgetStartedLoader(true)
  //     if(status == 'authenticated'){
  //       handleGoogleClick(session.user)
  //     }else{
  //       try {
  //         // Trigger the Google sign-in flow
  //         localStorage.setItem('GooglesignInClicked', 'true')
  //         await signIn('google'); // Redirect will happen as part of the process
  //       } catch (error) {
  //         setgetStartedLoader(false)
  //         console.error("Error during Google sign-in", error);
  //       }
  //     }
  //   }catch(error){
  //     setgetStartedLoader(false)
  //     console.error("Error While Click on Google button session:", error);
  //   }
  // }

  // this code
 
const redirectToGoogleLogin = () => {
  try{
    const urlPath = window.location.pathname; 
    const origin = window.location.origin;
    console.log("origin", origin)
    // let dynamic_state = urlPath?.substring(1);
    const dynamic_state = encodeURIComponent(urlPath.substring(1)); 
    const clientId = '630950640067-2joesrmd9s9gdvibnslieu9p5831gqlj.apps.googleusercontent.com';// Replace with your actual Client ID
    // const redirectUri = encodeURIComponent(origin);
    const redirectUri = `${origin}`; // Ensure the redirect URI is properly set
    const scope = encodeURIComponent('email profile openid');

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${clientId}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&response_type=code` +
      `&scope=${scope}` +
      `&state=${dynamic_state}&prompt=consent`;

    window.location.href = googleLoginUrl;
  }catch(error){
    console.log("redirectToGoogleLogin catches error", error)
  }
  
};

const handleShow = () => {
  const program_id = localStorage.getItem('session_entered_id');
  const updatedChildState = {
    ...JSON.parse(localStorage.getItem('childState')) || {},
  };

  const state = { program_id };

  new Promise((resolve) => {
    onUpdate(state); // Call onUpdate
    resolve();
  }).then(() => {
    if (updatedChildState.email_address) {
      InvokeSendOTP_Session();
    } else {
      setshow_Enter(true);
      try{
        trackEvent('Clicked_Enter/Redeem_NextJS', { event_action: 'User Clicked on Enter/Redeem Button' });
      }catch(error){
        console.log("Pixel Error while trackEvent  ", error)
      }
    }
  }).catch(error => {
    console.error('Error:', error);
  });
};



  
  return(
        <div className='addCardInfo'>
          {show_Enter ?
              <div className='addCardDetailUpr'>
                <div className='addCardDetail'>
                  <div className='addCardLogo'>
                    <img src="/images/heartVector.png" alt="familyone" title="familyone" className='heartVector' />
                    <a><img src="/images/familyOneNewLogo.svg" alt="familyone" title="familyone" /></a>
                    <img src="/images/StartUnion.svg" alt="familyone" title="familyone" className='StarUnion' />
                  </div>
                  <label> Get Started</label>
                  <p>To redeem this offer you need to Log in or Sign up</p>

                  <div className='checkWithUpr'>
                    <div onClick={()=>{loginWithFacebook() }} className='checkWithFB'><a><img src="/images/ic_baseline-facebook.svg" alt="familyone facebook" title="" /><span>With Facebook</span></a></div>
                    {!isFacebookBrowser && <div onClick={() => { redirectToGoogleLogin() }} className='checkWithFB checkWithG'><a><img src="/images/mage_google.svg" alt="familyone google" title="familyone google" /><span>With Google</span></a></div>}
                    {/* <div  onClick={() => handleGClick('google')} className='checkWithFB checkWithG'><a><img src="/images/mage_google.svg" alt="familyone google" title="familyone google" /><span>With Google</span></a></div> */}
                  </div>
                  <h3 onClick={()=>handleEmailAddress()}>Enter Your Email Address</h3>
                  {showError && <p>{showError}</p>}
                  {error_mess && <p>{error_mess}</p>}
                  {fbGglErrorMsg && <p>{fbGglErrorMsg}</p>}
                  {getStartedLoader && getStartedLoader == true && 
                    <div className="loaderCstm">
                       <center>
                        <LottieLoader />
                      </center>
                  </div>}
                </div>
                
                {/* <div className='addCardDetailTD'>
                  <p>By accessing family.one, you agree to the
                    <b className="cursor_pointer"><a onClick={()=>handleCloseTerms('open')}>Terms of Service</a></b> and <b className="cursor_pointer"><a href="https://www.iubenda.com/privacy-policy/7780464" target="_blank">Privacy Policy</a></b>, and consent to receiving relevant updates and offers
                    that match your interests. You can manage your preferences in your profile settings.
                  </p>
                </div> */}
              </div> :
               <div className='addCardDetailUpr'>
               <div className='addCardDetail'>
                 <div className='addCardLogo'>
                   <img src="/images/heartVector.png" alt="familyone" title="familyone" className='heartVector' />
                   <a><img src="/images/familyOneNewLogo.svg" alt="familyone" title="familyone" /></a>
                   <img src="/images/StartUnion.svg" alt="familyone" title="familyone" className='StarUnion' />
                 </div>
       
                 <div className='addCstmPara'>
                 <p>By accessing family.one, you agree to the
                   <b className="cursor_pointer term_color_blue"><a onClick={()=>handleCloseTerms('open')}>Terms of Service</a></b> and <b className="cursor_pointer term_color_blue"><a onClick={() => setShowIframe(true)}>Privacy Policy</a></b>, and consent to receiving relevant updates and offers
                   that match your interests. You can manage your preferences in your profile settings.
                 </p>
               </div>

                 {/* <div className='checkWithUpr'> */}
                   {/* <div onClick={()=>{loginWithFacebook() }} className='checkWithFB'><a><img src="/images/ic_baseline-facebook.svg" alt="familyone facebook" title="" /><span>With Facebook</span></a></div> */}
                   {/* <div onClick={() => { handleGoogleClick() }} className='checkWithFB checkWithG'><a><img src="/images/mage_google.svg" alt="familyone google" title="familyone google" /><span>With Google</span></a></div> */}
                   {/* <div  onClick={() => handleGClick('google')} className='checkWithFB checkWithG'><a><img src="/images/mage_google.svg" alt="familyone google" title="familyone google" /><span>With Google</span></a></div> */}
                 {/* </div> */}
                 <div onClick={() => handleShow()} className='checkWithFB checkWithG'><a><span>{enterBtnText}</span></a></div>

                   {/* <div onClick={()=>handleEmailAddress()} className='checkWithFB checkWithG'><a><span>Enter Your Email Address</span></a></div> */}

                 {/* <h3 onClick={()=>handleEmailAddress()}>Or Enter Your Email Address</h3> */}
                 {showError && <p>{showError}</p>}
                 {error_mess && <p>{error_mess}</p>}
                 {getStartedLoader && getStartedLoader == true && 
                   <div className="loaderCstm">
                    <center><LottieLoader /></center>
                   </div>
                  }
               </div>
              
             </div>
}
              {show_t_and_c &&
                  <TermsConditions Back={handleCloseTerms} program={JSON.parse(localStorage.getItem("program"))} />
              }
              
      {showIframe && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              overflow: 'hidden',
            }}
          >
            <button
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: '10px',
                right: '5%',
                backgroundColor: 'transparent', // No background color
    color: 'black', // Black cross
                border: 'none',
                outline: 'none', // Remove focus outline
    borderRadius: '0', // No rounded border
                width: '40px',
                height: '40px',
                fontSize: '50px',
                cursor: 'pointer',
                zIndex: 1001,
              }}
            >
              &times;
            </button>
            <iframe
              src="https://www.iubenda.com/privacy-policy/7780464"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              title="Privacy Policy"
            />
          </div>
        </div>
      )}
              {/* Successful model to show google and Facebook authorised. */}
              {/* <Modal isOpen={gglFbSuccessMsg} toggle={()=>setGglFbSuccessMsg(null)} className={" custom_Addchildmodel"}>
                  <ModalBody className="custom_editchildBody">
                      </ModalBody>
                      <div className="editchild_content" style={{ postion: "relative" }}>
                      <img src="/images/closeChild copy.svg" alt="" title="" onClick={() => setGglFbSuccessMsg(null)} className="clearcontest_img popup_close_button" />
                          <div className="ggl_scs_div">
                            {pageLoader ? <center><LottieLoader /></center>:<span className="success_class"></span>}
                            <Label className=" ggl_fb_success_msg">{gglFbSuccessMsg}</Label>
                          </div>
                          <img src={require('/images/closeChild.svg')} alt="" title="" onClick={() => this.setState({editModalOpen1:false, editDeleteChildData: null, editChildIndex: null})} className="clearcontest_img popup_close_button" />
                              
                        </div>  
                </Modal> */}
            </div>
    )
}

export default getstarted;
