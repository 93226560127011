import React from 'react';

import Button from '@material-ui/core/Button';
import url from '../../src/assets/url';
import functions from '../../src/assets/functions/common_functions';
import gsap from 'gsap';
import $ from "jquery";
import LottieLoader from '../../LottieLoader';

import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import Router from 'next/router';
import cookie from 'react-cookies';
import { isMobileSafari } from 'react-device-detect';
import LoginProcess from '../../components/LoginProcess';
import { withRouter } from 'next/router';
// import home from "../Screens/home";
// import HomeScreen from "../../Screens/home";

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};
class maintwo extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            program: props.progArray || [],
            dynamic_content: props.initialDynamicContent || "",
            fixedAtBottom: false,
            lastScrollTop: 0,
            loading: false,
            isExpanded: false,
            is_hydrated: false
        }
    }
    componentDidMount = () => {
        if (!this.props.progArray || this.props.progArray.length === 0) {
            Router.push('/');
            // Redirect to the homepage if progArray is empty
        }
        this.setState({ is_hydrated: true })

                // Combine both arrays
                let program_list = [...this.props.all_prog, ...this.props.perk_array];
            
                // Get the part after the last underscore
                if (this.props.entered_id) {
                    let clicked_program = program_list?.length > 0 && program_list.filter(pro => pro.program_id == this.props.entered_id || pro.term_id == this.props.entered_id)
                    localStorage.setItem("entered_id", this.props.entered_id);
                    localStorage.setItem("session_entered_id", this.props.entered_id);
                    localStorage.setItem("program", JSON.stringify(clicked_program[0]));
                }
                let self = this;
                let progArray = this.state.program
                if(progArray && progArray[0] && progArray[0].program_type == 'story'){
                    functions.getShareLink(this, progArray[0].program_id, cookie.load("auto_id") || '', function (response_json) {
                        let response = JSON.parse(response_json);
                        progArray[0].facebook_link = response.facebook_link;
                        self.setState({ program: progArray })
                    })
                }





                window.scrollTo(0, 0);

                // Create the timeline animation
                const timeline = gsap.timeline();
                timeline.fromTo('div#__next', { opacity: 0 }, { opacity: 1, duration: 2 });

                let dynamic_content = localStorage.getItem("dynamic_content")
                    ? JSON.parse(localStorage.getItem("dynamic_content"))
                    : "";

                if (dynamic_content) {
                    this.setState({ dynamic_content: dynamic_content });
                }
                this.OnPageLoad()

                // Add the scroll event listener if needed
                window.removeEventListener('scroll', this.handleScroll);
                window.addEventListener('scroll', this.handleScroll);
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }, 0);
            };
        
    


    //     static async getInitialProps(ctx) {
    //         // Fetch initial data here, such as program list from an API or database
    //         let program_list = []; // replace with actual fetch if needed
    //         let entered_id = ""; // replace with logic to get ID if needed
    // 
    //         // Simulate loading data from local storage (only works client-side, so this is just a placeholder)
    //         if (typeof window !== "undefined") {
    //             program_list = JSON.parse(localStorage.getItem("program_list")) || [];
    //             entered_id = JSON.parse(localStorage.getItem("entered_id")) || localStorage.getItem("session_entered_id") || "";
    //         }

    //         const program_enter = program_list.filter(pro => pro.program_id == entered_id);
    // 
    //         return { initialProgram: program_enter || null, initialDynamicContent: "" };
    //     }

    //     componentDidMount() {
    //         if (typeof window !== "undefined") {
    //             // Perform any client-side specific logic here
    //             const dynamic_content = localStorage.getItem("dynamic_content")
    //                 ? JSON.parse(localStorage.getItem("dynamic_content"))
    //                 : "";

    //             this.setState({ dynamic_content });

    //             window.addEventListener('scroll', this.handleScroll);
    //             setTimeout(() => {
    //                 window.scrollTo({
    //                     top: 0,
    //                     left: 0,
    //                     behavior: 'smooth'
    //                 });
    //             }, 0);
    //         }
    //     }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' // You can use 'smooth' if you prefer smooth scrolling
                });
            }, 0);
        }
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    OnPageLoad = async (data_Store) => {
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
        auto_id = auto_id ? auto_id : "";

        // if (auto_id !== "") {
        //     functions.getLandingPage(this);
        // } else {

        let action = "User landed on main index page";
        let image = 'main_index_page.png';
        // functions.logUserActivity(action, image);
        let fb_post = localStorage.getItem("fb_post") === "true" ? "true" : "false";
        localStorage.setItem("active_button", "0");
        let logged_in = localStorage.getItem("logged_in");

        if (logged_in === false || logged_in === 'false') {
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));
            localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content));
            cookie.remove("auto_id", { path: "/" });
        }

        this.setState({ main_loader: true });

        let pathname = window.location.hash;
        let self = this;


        if (pathname.length > 0 && fb_post === "false") {
            // Additional logic can be placed here if needed.
        } else {
            if (self.props.all_prog || self.props.perk_array ) {
                let cognito_data = {};
                let dynamo_data = {};
                let relation_data = {};
                let program_data = self.props.all_prog.sort((a, b) => a.order_number - b.order_number);
                let store_all = 'true';
                functions.storeCookie(cognito_data, dynamo_data, relation_data, program_data, store_all);
                localStorage.setItem("perk_response", JSON.stringify(self.props.perk_array));

                self.handleFixedHeader();
                // self.handleSessionDetail();
                self.setState({ main_loader: false, loader: false });
                self.toggleAnimation();
            }
        }
        // }
    }

    handleSessionDetail() {
        let self = this;


        $(document).on('click', '.program_slides', function () {
            let classes = $(this).attr('class');
            let temp_classes = classes.split(" ")
            let program_array = self.state.all_program;
            var index = program_array.findIndex(p => p.program_id == temp_classes[2])
            if (index != -1) {
                let program = program_array[index];
                let program_id = program.program_id;
                let program_type = program.program_type;
                // let action = "User clicked on arrow of " + program_id + " on slider on main index page"
                // let image = 'main_slider_' + program_id + '.png';
                // functions.logUserActivity(action, image);
                // let main_id = 'session_' + program_type + program_id;
                let main_id = program_type + program_id;
                self.handleShowDetail(program, main_id)
            }
        });

        $(document).on('click', '.extragain_tabs', function () {

            // $(".extragain_tabs").animate({ scrollTop: 600 });
            var navbar = document.getElementById("navbar");
            var tab_content_start = document.getElementById("tab_content_start");
            if (navbar && tab_content_start) {
                var sticky = navbar.offsetTop;
                var sticky2 = navbar.offsetHeight;
                let sticky_tabcontent = tab_content_start.offsetTop
                
                
                
                let scrolled = $(window).scrollTop() == 0 ? $(window).scrollTop() + 2 : $(window).scrollTop() + 2
                
                if ($(window).scrollTop() >= (sticky)) {
                    
                    window.scrollTo({
                        top: sticky_tabcontent,
                        behavior: 'smooth'
                    })
                } else {
                    
                    window.scrollTo({
                        top: $(window).scrollTop() == 0 ? sticky : sticky + scrolled,
                        behavior: 'smooth'
                    })
                    setTimeout(() => {
                        window.scrollTo({
                            top: sticky_tabcontent - 10,
                            behavior: 'smooth'
                        })
                    }, 20);
                }
            }
        })

        $(document).on('click', '.ar_middle', function () {
            if (self.swiper) {
                
                // let action = "User clicked on right arrow of slider of " + self.swiper.realIndex + " index on main index page"
                // let image = 'slider_right_arrow.png'
                // functions.logUserActivity(action, image);
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
        $(document).on('click', '.al_middle', function () {
            if (self.swiper) {
                
                // let action = "User clicked on left arrow of slider of " + self.swiper.realIndex + " index on main index page"
                // let image = 'slider_left_arrow.png'
                // functions.logUserActivity(action, image);
                self.setState({ activeIndex: self.swiper.realIndex })
            }
        });
    }

    toggleAnimation = () => {
        let self = this;
        var root = document.documentElement;
        var body = document.body;
        var pages = document.querySelectorAll(".program-page");
        var tiles = document.querySelectorAll(".program-tile");

        for (var i = 0; i < tiles.length; i++) {
            let tiles_id = tiles[i].getAttribute("id");
            let ids = '#' + tiles_id + '.program-page.hero-1';
            let pageselected = []
            pages.forEach(page => {
                if (page.getAttribute("id") == tiles_id) {
                    pageselected.push(page)
                }
            });
            if (tiles && tiles.length > 0 && pageselected && pageselected.length > 0) {
                addListeners(tiles[i], pageselected[0]);
            }
        }

        function addListeners(program_tile, page) {
            program_tile.addEventListener("click", function () {
                animateHero(program_tile, page);
            });
            page.querySelectorAll(".close_btn")[0].addEventListener("click", function () {
                animateHero(page, program_tile);
            });
        }

        function animateHero(fromHero, toHero) {
            
            // existing animation code...
            TweenLite.to(clone, 0.3, style);
            
            var clone = fromHero.cloneNode(true);
            var from = calculatePosition(fromHero);
            var to = calculatePosition(toHero);
            TweenLite.set([fromHero, toHero], { visibility: "hidden", className: "-=active" });
            TweenLite.set(clone, { position: "absolute", margin: 0, background: "#F9EAE2" });

            body.appendChild(clone);
            var style = {
                x: to.left - from.left,
                y: to.top - from.top,
                width: to.width,
                height: to.height,
                autoRound: false,
                ease: Power1.easeOut,
                onComplete: onComplete
            };

            TweenLite.set(clone, from);
            TweenLite.to(clone, 0.3, style)

            if (clone.classList.contains('program-tile', 'program_detail_logo', 'program_title01')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "16px" }, { bottom: "-31px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "20px" }, { 'font-size': "25px" }, 0)
                $('html, body').css('overflow', 'hidden');
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs, .fixFotrBtn').css('opacity', 0);

                // document.ontouchmove = function(e){ e.preventDefault(); }
            } else if (clone.classList.contains('program-page', 'program_detail_logo', 'freebies_iner')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "-31px" }, { bottom: "16px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "25px" }, { 'font-size': "20px" }, 0)
                $('html, body').css('overflow', 'initial');
                $('.program-page-container').find('.program-page').css('min-height', window.innerHeight);
                $('.common_main_space1, .sharegain_swiper, .program-tile-container, .extragain_tabs, .fixFotrBtn').css('opacity', 1);
            }

            function onComplete() {
                TweenLite.set(toHero, { visibility: "visible", className: "+=active" });
                body.removeChild(clone);
            }
        }

        function calculatePosition(element) {
            var rect = element.getBoundingClientRect();
            var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
            var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

            var clientTop = root.clientTop || body.clientTop || 0;
            var clientLeft = root.clientLeft || body.clientLeft || 0;

            return {
                top: Math.round(rect.top + scrollTop - clientTop),
                left: Math.round(rect.left + scrollLeft - clientLeft),
                height: rect.height,
                width: rect.width,
            };
        }
        /*End of Code of animation */
    }

    // componentWillMount = () => {
    //     let program_list = localStorage.getItem("program_list")?JSON.parse(localStorage.getItem("program_list")):[];
    //     let entered_id = localStorage.getItem("entered_id")?
    //                         JSON.parse(JSON.stringify(localStorage.getItem("entered_id"))):    
    //                             localStorage.getItem('session_entered_id') ? localStorage.getItem('session_entered_id') : "";

    //     let program_enter = program_list.find(pro=>pro.program_id==entered_id)
    //     this.setState({program:program_enter})
    //     if (program_list && Object.keys(program_list).length > 0) {
    //         let program = []
    //         program.push(program_enter)
    //         this.setState({ program: program })
    //     }
    //     window.scrollTo(0, 0)
    //     var timeline = new TimelineMax();
    //     timeline.fromTo('div#__next', 2, { opacity: "0" }, { opacity: "1" });

    //     let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    //     if (dynamic_content != "") {
    //         this.setState({ dynamic_content: dynamic_content })
    //     }
    //     window.removeEventListener('scroll', this.handleScroll);
    // }

    handleFixedHeader = () => {
        $(window).scroll(function () {
            var navbar = document.getElementById("navbar");
            if (navbar) {
                var sticky = navbar.offsetTop;
                if ($(window).scrollTop() >= (sticky - 39)) {
                    $('.fix_main').addClass('fixed-header');
                }
                else {
                    $('.fix_main').removeClass('fixed-header');
                }
            }
        });
    }

    formatDate = (value) => {
        let final_date = ""
        if (value && value != "" && typeof value != undefined && value != "undefined") {
            let date_array = value.split("-");
            let year = date_array[0];
            let date = date_array[2];
            let month = date_array[1];
            let month_name = Month[month];
            final_date = date + " " + month_name + " " + year;
        }
        return final_date;
    }
    handleShowEmailInput = (value) => {
        this.setState({ showEmailInput: value })
    }

    handleGetStarted = () => {
        let action = "User clicked on Get Started on session detail page"
        let image = 'user_clicked_get_started_session_detail.png'
        functions.logUserActivity(action, image);
        let is_sub_id = 'no';
        let sub_id = is_sub_id == 'yes' ? cookie.load("auto_id") : cookie.load("session_id");
        let program_id = this.state.program && this.state.program[0].program_id;
        let partner_id = this.state.program && this.state.program[0].partner_id;
        let registration_source = this.state.program && this.state.program[0].registration_source ? this.state.program[0].registration_source : "";
        let point_type = 'entry';
        let self = this;
        let is_direct = 1;
        let program = this.state.program && this.state.program[0] ? this.state.program[0] : {};
        if(!program_id && program.perk_title){
            program_id = program.term_id        }
        if (program?.is_lock_program == 1) {
        } else {
            if (program?.program_type == "contest" || program?.program_type == "freebie") {
                let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                // functions.contestEntry(this, is_sub_id, sub_id, tracking_id, program_id,partner_id,registration_source, point_type, is_direct, '', function (response_json) {
                //     let response = JSON.parse(response_json);
                //     if (response.status == 200) {

                //         self.setState({ loader: false });
                //     }
                // })

            }
            localStorage.setItem("session_entered_id", program_id)
        }
        // functions.pushToRoute(self, 'home')
    }

    handleBackSession = () => {
        this.setState({ loading: true });
        Router.push('/');

        let action = "User clicked on back button of " + this.state.program.program_id + " on session detail page"
        let image = 'user_clicked_back_button_of_' + this.state.program.program_id + '_session_detail.png'
        functions.logUserActivity(action, image);
        // functions.pushToRoute(this, '', 'session_enter')
        localStorage.setItem("playAnimation", "true");
        localStorage.setItem("current_state", "");
        localStorage.setItem("previous_state", "session_enter");
        // functions.pushToRoute(this.props, "session_enter", "main_index")
    }

    handleScroll = () => {
        const { lastScrollTop, fixedAtBottom } = this.state;
        const currentScrollTop = window.scrollY;
        const scrollToTop = currentScrollTop < lastScrollTop || currentScrollTop === 0;

        // Check if scrolled to the bottom
        if (!fixedAtBottom && scrollToTop) {
            // Add a small delay before updating the state to allow the button to animate smoothly
            setTimeout(() => {
                this.setState({ fixedAtBottom: true });
            }, 50);
        } else if (fixedAtBottom && !scrollToTop) {
            // Check if scrolled to top from bottom
            this.setState({ fixedAtBottom: false });
        }

        this.setState({ lastScrollTop: currentScrollTop });
    };

    // Toggle method to change the 'isExpanded' state
    toggleDescription = () => {
        this.setState((prevState) => ({
        isExpanded: !prevState.isExpanded,
        }));
    };

    renderDescription(description) {
        const maxDescriptionLength = 80; // Character limit for truncation
    
        // If description length is less than maxDescriptionLength, always show full description without truncation
        if (description.length <= maxDescriptionLength) {
            return (
                <>
                    <span>{ReactHtmlParser(description)}</span>
                </>
            );
        }
    
        // If description is longer than max length and not expanded, show truncated version
        if (!this.state.isExpanded) {
            return (
                <>
                    <div className='descShwText'>
                        {ReactHtmlParser(description.slice(0, maxDescriptionLength) + '...')}
                    </div>
                    <button className='descShwBtn' onClick={this.toggleDescription}>See More</button>
                </>
            );
        } else {
            // If expanded, show full description
            return (
                <>
                    <span>{ReactHtmlParser(description)}</span>
                    <button className='descShwBtn' onClick={this.toggleDescription}>See Less</button>
                </>
            );
        }
    }

    


    render() {
        const { dynamic_content, fixedAtBottom } = this.state
        let programArr = this.state.program;
        return (
            <div id={programArr?.length > 0 && programArr[0] != undefined && programArr[0]?.program_type && programArr[0]?.program_id ? programArr[0].program_type + programArr[0].program_id : ''} className={"program-page hero-1 hero_" + programArr?.length > 0 && programArr[0] != undefined && programArr[0].program_id ? programArr[0].program_id : ''}>
                <div className={`row ${programArr?.length && programArr[0] != undefined && programArr[0]?.program_type === "contest" ? "giveAwaySession" : ""} ${programArr?.length && programArr[0] != undefined && programArr[0]?.program_type === "freebie" ? "freebieSession" : ""}`}>
                    <div className="common_mainAddOn">
                        <div className="common_main detailspace_rmv">
                            {programArr && programArr.length > 0 && programArr.map((program, index) => (

                                <div key={index} className="sessionAddOns sessionAddOnsNxt">
                                    <div className="freeies_babyImg">

                                        {this.state.loading && (
                                            <div className='loaderCstm'>
                                                <LottieLoader className="loader_class" />
                                            </div>
                                        )}

                                        <div className="detailback_section white_circle_div">
                                            <div onClick={() => { this.handleBackSession() }} className="backLinkWidth close_btn 12">
                                                <img src="/images/bkArorIcon.svg" alt="Family-friendly deals" title="Family-friendly deals" />
                                            </div>
                                        </div>
                                        <div className="contests_dtlImges" onClick={() => {
                                            localStorage.setItem("entered_id", program?.program_id ?? '')
                                        }}>
                                            <div><img src={ url.cloudflare_image_path + (program?.program_image || program?.perk_image) + url.varient} alt="" title="" /></div>
                                        </div>

                                        <div className="programLogo">
                                            <div className="programLogoLft program_detail_logo">
                                                {program?.program_type != "review" && program?.program_type != "story" && !program?.perk_title &&
                                                    program?.logo && program.logo.map((logo1) => (
                                                        <a href='#' key={logo1}><img src={url.cloudflare_image_path + logo1 + url.varient} alt="Parenting community" title="Parenting community" /></a>
                                                    ))
                                                }
                                            </div>
                                            {!program?.perk_title && program?.program_type != "story" && <div className="programLogoRght">
                                                <div className="instant_details instantDetailsSpc 12121">
                                                    <a href='#' className={`instant_tags ${program?.program_type === "contest" ? "giveAwayWrap" : ""} ${program?.program_type === "freebie" ? "freebieSesonWrap" : ""} ${program?.program_type === "survey" ? "surveyMainWrap" : ""}`}>
                                                       
                                                        {
  program.program_type === "contest" && program.program_id !== "4380" ? (
    <img src="/images/giveAwayCardIcon.svg" className="prgrmSetLogo" alt="Parenting community platform" title="Parenting community platform" />
  ) : program.program_id === "4380" ? (
    <img src="/images/surveyIcon.svg" className="prgrmSetLogo" alt="Family product insights" title="Family product insights" />
  ) : null
}
                                                        {program?.program_type == "survey" &&
                                                            <img src="/images/surveyIcon.svg" className="prgrmSetLogo" alt="Family product insights" title="Family product insights" />
                                                        }
                                                        {program?.program_type == "freebie" &&
                                                            //<img src={url.family_one_image_url + 'orange-icon-FREEBIE_128.png'} alt="" title="" />
                                                            <img src="/images/FreebieIcon.svg" className="prgrmSetLogo" alt="Trusted family reviews" title="Trusted family reviews" />
                                                        }
                                                        {program?.program_type == "story" &&
                                                            <img src={url.family_one_image_url + 'orange-icon-STORY_128.png'} className="prgrmSetLogo" alt="Family lifestyle tips" title="Family lifestyle tips" />
                                                        }
                                                        {program?.program_type == "review" &&
                                                            <img src={url.family_one_image_url + 'orange-icon-REVIEW_128.png'} className="prgrmSetLogo" alt="Baby essentials guide" title="Baby essentials guide" />
                                                        }
                                                        {/* <span className='giveAwayTag'>{capitalize(program.program_id == '1636' ? 'Perks' : program?.program_type == "contest" ? "Giveaways" : program?.program_type)}</span> */}
                                                        <span className='giveAwayTag'>
  {capitalize(
    program?.program_type === "contest" && program.program_id === "4380"
      ? "Study"
      : program?.program_type === "contest"
      ? "Giveaways"
      : program.program_id === "1636"
      ? "Perks"
      : program?.program_type
  )}
</span>
                                                    </a>
                                                    {program?.program_category == 'Instant' &&
                                                        <a className="instant_tags"><img src={url.family_one_image_url + 'Vector3.png'} className="prgrmSetLogo" alt="Child development articles" title="Child development articles" />
                                                            <span>{dynamic_content ? dynamic_content.static_text.session_enter.instant.en : "Instant"}</span></a>
                                                    }
                                                    {program?.is_lock_program == 1 &&
                                                        <a className="bonusTagNw bonusTagNwNxt">
                                                            <img src="/images/BounsStar.svg" className="prgrmSetLogo1 prgrmSetLogoNxt" alt="Family-friendly deals and perks" title="Family-friendly deals and perks" />
                                                            <span>{dynamic_content ? dynamic_content.static_text.session_enter.exclusive.en : "Exclusive"}</span>
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        {program?.perk_title &&
                                        <div className='programLogo'>
                                          <div className='programLogoRght'> 
                                          <div className="perksTagBtn">
                                            <img src='/images/perkIcon.png' alt="Giveaway opportunities" title="Giveaway opportunities" /> 
                                            {program?.category}
                                          </div>
                                          </div> 
                                        </div>
                                       }


                                    </div>

                                    {program?.program_type == "story" ?
                                    <div className="dtl_details_sub">
                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle1)}</label>
                                            {ReactHtmlParser(program.story_paragraph1)}
                                        </div>
                                        {program.story_media && program.story_media.length > 0 && program.story_media.map((element, index) => (
                                            <div key={"program_array_story_" + index} className="story_media">
                                                {element.url && element.url != "" &&
                                                    <div key={"story_media" + index} className="vdo_show" style={{ marginLeft: "3px" }}>
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == false &&
                                                            <div style={{ position: "relative" }} >
                                                                <img style={{ borderRadius: "20px", height: "auto" }} width="324px" src={url.cloudflare_image_path + program.program_image + url.varient} alt="" title="" />
                                                                <img onClick={() => { this.handleImageClick() }} className="play_button" src={url.cloudflare_image_path + "Subtractplay.png" + url.varient} alt="" title="" />
                                                            </div>
                                                        }
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == true &&
                                                            <Iframe url={element.url + "&autoplay=1&mute=1"}
                                                                width="324px"
                                                                height={CalculateHeight(324, program.video_dimention ? program.video_dimention : '1:1')}
                                                                id={"iframe_story_media" + index}
                                                                className="iframe_story_media"
                                                                scrolling="no"
                                                                frameborder="0"
                                                                allow='autoplay; encrypted-media'
                                                                title='video'
                                                                allowTransparency="true"
                                                                data-video-aspect-ratio="5:9"
                                                                autoplay="true"
                                                                webkitallowfullscreen="true"
                                                                mozallowfullscreen="true"
                                                                allowfullscreen="true"
                                                            />
                                                        }
                                                        {!(program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook')) && element.type == "video" &&
                                                            <ReactPlayer
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { showinfo: 0 }
                                                                    }
                                                                }}
                                                                className="video_story" light={true} controls={true} url={element.url} playing />
                                                        }
                                                        {element.type != "video" &&
                                                            <img className="video_story" src={element.url} alt="" title="" />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle2)}</label>
                                            {ReactHtmlParser(program.story_paragraph2)}
                                        </div>
                                        {!this.state.session_entry &&
                                            <div className="dtl_suba_redem">

                                                <a className="story_share pointer_cursor" href={program.facebook_link} rel="noopener noreferrer" target="_blank">Share</a>

                                                {program.has_enter != 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter" onClick={() => { this.handleClick(program) }}>
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.enter.en : "Enter"}
                                                    </Button>
                                                }
                                                {program.has_enter == 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter">
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.entered.en : "Entered"}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                        {/* {!this.state.session_entry &&
                                            <div className="fb_connect">
                                                <div> <img src={url.family_one_image_url + 'Path.png'} alt="" title="" /> </div>
                                                <p>{dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.facebook_comments.en : "Facebook comments"}</p>
                                                <FacebookProvider appId="367702427421660">
                                                    <Comments href="https://www.facebook.com/WebnexusPankaj/posts/915966112113485" width="100" />
                                                </FacebookProvider>
                                            </div>
                                        } */}
                                        {this.state.session_entry &&
                                            <div className="paddingbottom">
                                                <div className="hometwo_upr">
                                                    <div className="hometwo_hendupr 7878">
                                                        <div className="hometwo_hend">
                                                            <div className='startRocket'><img src='/images/rocketIconNw.png' alt="" title="" /></div>
                                                            <h3>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}</h3>
                                                            <p>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.login_to_redeem.en : "To redeem this offer you need login or sign up"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="hometwo_redem">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleClick(program) }}>
                                                            {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                       

                                    </div>
:
                                    <div className={isMobileSafari ? "details_freebies height_ios" : "details_freebies"} >


                                       
                                        <div className="freebies_iner program_title01">
                                            <h1>{ReactHtmlParser(program?.program_title || program?.perk_title)}</h1>
                                            <p>{ReactHtmlParser(program?.perk_subtitle)}</p>

                                        </div>
                                        <div className="descp_freebies">
                                            <label>{dynamic_content ? dynamic_content.static_text.session_enter.description.en : "Description"}</label>
                                            {/* {ReactHtmlParser(program?.program_description)} */}
                                            <div>{this.renderDescription(program?.program_description || program?.description)}</div>

                                            {/* {ReactHtmlParser(program?.program_description || program?.description)} */}
                                        </div>
                                        {program?.hide_expiry_date == "true" ?
                                            <div></div> :
                                            !program?.perk_title &&
                                            <div className="ends_date session_end_date">
                                                <p> {"Ends " + this.formatDate(program?.expiry_date)} </p>
                                            </div>
                                        }
                                        {/* <div className="ends_date session_end_date">
                                            <p> {"Ends " + this.formatDate(new Date(program.expiry_date))} </p>
                                        </div> */}

                                        <div onClick={() => { this.handleGetStarted() }}>
                                            {/* <div className="homeSession_upr">
                                                <div className="session_hendupr">
                                                    <div className="session_hend">
                                                                                                              
                                                        <LoginProcess dynamic_content={dynamic_content} handleGetStarted = {this.handleGetStarted} handleShowEmailInput = {this.handleShowEmailInput}/>

                                                    </div>
                                                </div>
                                                
                                            </div> */}
                                            {this.state.is_hydrated === true && 
                                            <LoginProcess dynamic_content={dynamic_content} handleGetStarted={this.handleGetStarted} handleShowEmailInput={this.handleShowEmailInput} programId={this.props.progArray[0].program_id} />
                                            }
                                        </div>
                                    </div>
    }


                                    {/* End of  three in One section */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(maintwo);

export async function getStaticPaths() {
    try {
        const url = 'https://familyonecanadaaddress.s3.amazonaws.com/FamilyoneWebsite/familyoneWebsiteData.json';
        const response = await fetch(url);
        const data = await response.json();

        // Assuming data contains different lists like program_list, perk_list, and story_list
        const programList = data.program_list || [];
        const perkList = data.perk_list || [];
        const storyList = data.story_list || [];

        // Combine all lists into a single array
        const combinedData = [...programList, ...perkList, ...storyList];

        // Map through combined data to create paths
        const paths = combinedData.map((item) => {
            const formattedTitle = item.program_title.replace(/\s+/g, '-'); // Format title by replacing spaces with hyphens
            const programId = item.program_id.toString(); // Ensure programId is a string

            return {
                params: {
                    programName: formattedTitle, // The formatted program name
                    programId: programId, // The program ID
                },
            };
        });

        return {
            paths,
            fallback: 'blocking', // 'blocking' will wait for the new page to be generated on the first request
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { paths: [], fallback: 'blocking' }; // Return empty paths in case of an error
    }
}


export async function getStaticProps(context) {
    try {
        const url = `https://familyonecanadaaddress.s3.amazonaws.com/FamilyoneWebsite/familyoneWebsiteData.json?cacheBust=${Date.now()}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const { 'programName]_[entered_id': slug } = context.params;

        if (!slug) {
          throw new Error("Slug not found in params");
        }
    
        // Extract the last part of the slug after the last underscore
        const entered_id = slug.split('_').pop();

        // Parse the fetched JSON data
        const data_Store = await response.json();

        // Initialize the response objects
        let response_json = { catch_error: true, data: [] };
        let perk_response_json = { catch_error: true, data: [] };

        // Ensure program_list and story_list are present, and merge them
        if (Array.isArray(data_Store?.program_list) && Array.isArray(data_Store?.story_list)) {
            response_json = {
                catch_error: false,
                data: [...data_Store.program_list, ...data_Store.story_list],
            };
        }

        // Handle perk_list if it exists
        if (data_Store?.perk_list) {
            perk_response_json = {
                catch_error: false,
                data: data_Store.perk_list,
            };
        }

        // Initialize all_program variable if response_json is valid
        let all_program = [];
        if (response_json && !response_json.catch_error) {
            all_program = response_json.data ?? [];
          

            // Process the program array into different categories
            let program_array_enter = [];
            let program_array_is_eligible = [];
            let program_array_not_enter = [];
            all_program.forEach((ele) => {
                if (ele.has_enter) {
                    program_array_enter.push(ele);
                } else {
                    if (ele.is_eligible || ele.pending_fields.length) {
                        program_array_is_eligible.push(ele);
                    } else {
                        program_array_not_enter.push(ele);
                    }
                }
            });

            // Sorting the arrays by order_number
            program_array_enter.sort((a, b) => a.all_order_number - b.all_order_number);
            program_array_not_enter.sort((a, b) => a.all_order_number - b.all_order_number);
            program_array_is_eligible.sort((a, b) => a.all_order_number - b.all_order_number);

            // Merging the sorted arrays into final all_program
            all_program = [...program_array_is_eligible, ...program_array_not_enter, ...program_array_enter];
            // Separate featured programs
            let featured_program_array = all_program.filter(e => e.featured === 1);
            featured_program_array.sort((a, b) => (a.feature_order_number ?? 0) - (b.feature_order_number ?? 0));

            // Filter by different program types
            let contest_array = all_program.filter(e => e.program_type === "contest");
            let freebie_array = all_program.filter(e => e.program_type === "freebie");
            let survey_array = all_program.filter(e => e.program_type === "survey");
            let stories_array = all_program.filter(e => e.program_type === "story");
            let review_array = all_program.filter(e => e.program_type === "review");

            // Boolean flags to determine if there are any items in these categories
            let show_freebie = freebie_array.length > 0;
            let show_giveaway = contest_array.length > 0;
            let show_survey = survey_array.length > 0;
            let show_review = review_array.length > 0;
            let show_story = stories_array.length > 0;

            // Filter programs that should show in the "all" category
            let show_all_program = all_program.filter(pro => pro.show_in_all);

            // Process the perks response
            let perk_array = [];
            if (perk_response_json && !perk_response_json.catch_error) {
                perk_array = perk_response_json.data.sort((a, b) => a.order_number - b.order_number);

                // Add perk items to all_program
                // perk_response_json.data.forEach((element) => {
                //     all_program.push(element);
                // });
            }
const program_list = [...all_program, ...perk_array];
let prog = program_list.find(pro => pro.program_id == entered_id || pro.term_id == entered_id);
// Store the result as an array
let progArray = prog ? [prog] : [];
all_program = all_program.filter(program => program.hide_before_login !== 1);
featured_program_array = featured_program_array.filter(program => program.hide_before_login !== 1);
perk_array = perk_array.filter(program => program.hide_before_login !== 1);
progArray = progArray.filter(program => program.hide_before_login !== 1);
            // Return the necessary props
            return {
                props: {
                    show_freebie,
                    show_giveaway,
                    show_survey,
                    show_review,
                    show_story,
                    all_prog: all_program,
                    featured_program_array,
                    perk_array,  // Include the perk data
                    progArray,
                    entered_id
                },
            };
        }

        // Fallback if response_json has errors
        return {
            props: {
                show_freebie: false,
                show_giveaway: false,
                show_survey: false,
                show_review: false,
                show_story: false,
                all_prog: [],
                featured_program_array: [],
                perk_array: [],
                progArray: []
            },
        };
    } catch (error) {
        // Log error and return fallback props
        console.error('Error fetching data:', error);
        return {
            props: {
                show_freebie: false,
                show_giveaway: false,
                show_survey: false,
                show_review: false,
                show_story: false,
                all_prog: [],
                featured_program_array: [],
                perk_array: [],
                progArray: []

            },
        };
    }
}

